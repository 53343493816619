/* eslint-disable max-lines */
/* eslint-disable max-len */
const de = {
  translations: {
    datepicker: {
      placeholderText: 'TT.MM.JJJJ'
    },
    table: {
      loading: 'Laden...'
    },
    select: {
      placeholderText: 'Bitte wählen...',
      smokeProducts: {
        cigarettes: 'Zigaretten',
        cigar: 'Zigarre',
        pipe: 'Pfeife',
        chewingTobacco: 'Kautabak',
        snuff: 'Schnupftabak',
        eCigs: 'E-Zigaretten',
        other: 'Anderes',
      },
      heightUnits: {
        cm: 'cm',
        inch: 'Zoll'
      },
      weightUnits: {
        kg: 'kg',
        pound: 'Pfund'
      },
      parents: {
        one: 'ein Elternteil',
        both: 'beide Elternteile'
      }
    },
    radio: {
      gender: {
        male: 'Männlich',
        female: 'Weiblich',
      },
      yesNo: {
        no: 'Nein',
        yes: 'Ja',
        na: 'n.v.',
        dontKnow: 'Unbekannt'
      }
    },
    marks: {
      more: 'oder mehr',
      less: 'oder weniger',
      hour: 'Std.',
      week: 'Wo.',
      day: 'Tg.',
      moreHours: '$t(marks.hour) $t(marks.more)',
      terrible: 'schlecht',
      perfect: 'ausgezeichnet',
      seldom: 'selten',
      perWeek: 'pro Woche',
      never: 'nie',
      often: 'oft',
    },
    header: {
      instructions: 'Hinweise',
      help: 'Hilfe',
      logo: 'Logo'
    },
    pageHeader: {
      basicData: 'Grunddaten',
      smoking: 'Rauchen',
      alcohol: 'Alkohol',
      coffeAndTea: 'Kaffee und Tee',
      exercises: 'Sportliche Aktivitäten',
      existingConditions: 'Gesundheitszustand',
      medications: 'Arzneimittel',
      family: 'Familienanamnese',
      psychometric: 'Allgemeines Wohlbefinden',
      nutrition: 'Ernährung',
      search: 'Suche',
      lifeStyle: 'Lifestyle',
      morbidity: 'Vorhandene Erkrankungen',
      metabolicMetrics: 'Messungen und Laborresultate',
      riskFactors: 'Risikofaktoren',
      riskFactorSummary: 'Zusammenfassung der Risikofaktoren',
      precursorRisks: 'Morbiditätsrisiken',
      mortalityRisks: 'Sterblichkeitsrisiken',
      СHD: 'KHK Überlebens- und Eingriffsmöglichkeiten',
      plusRisks: 'Risiken',
      diseaseRisks: 'Krankheitsrisiken',
      sleep: 'Schlaf'
    },
    basicData: {
      name: 'Vorname',
      familyName: 'Name',
      gender: 'Geschlecht',
      birthDate: 'Geburtsdatum',
      height: 'Körpergröße',
      weight: 'Gewicht',
      bmi: 'BMI (kg/m2)',
      birthYear: 'Geburtsjahr',
      birthMonth: 'Geburtsmonat',
      addInfo: 'Zusätzliche Information'
    },
    pageOneQuestions: {
      smoking: {
        smkNow: 'Sind Sie derzeit Raucher?',
        smkEver: 'Haben Sie jemals früher geraucht?',
        smkStartYear: 'Wann haben Sie angefangen zu rauchen?',
        smkQuitYear: 'Wann haben Sie aufgehört zu rauchen?',
        smoke: 'Welches Produkt {{time}} verwenden Sie',
        smkNum: 'Wie viele {{name}} {{time}} rauchen Sie ungefähr pro Tag?',
        smokePresentTime: 'Was rauchen Sie?',
        smokePastTime: 'Was haben Sie geraucht?',
        smkNumPresentTime: 'Wie viele {{name}} rauchen Sie pro Tag?',
        smkNumPastTime: 'Wie viele {{name}} haben Sie pro Tag geraucht?'
      },
      alcohol: 'Wie viel Alkohol trinken Sie durchschnittlich pro Woche?',
      coffeAndTea: 'Wie viele Tassen Kaffee oder Tee trinken Sie durchschnittlich pro Tag?',
      exercises: 'Wie viele Stunden trainieren Sie durchschnittlich pro Woche?',
      diagnosedHypertension: 'Wurde bei Ihnen jemals Bluthochdruck diagnostiziert?',
      diagnosedDiabet: 'Wurde bei Ihnen jemals Diabetes diagnostiziert?',
      highBloodPressure: 'Werden Sie wegen Bluthochdruck behandelt?',
      highBloodSugar: 'Werden Sie wegen Diabetes behandelt?',
      family: 'Hatte jemand Ihrer Eltern eine Herz-Kreislauf-Erkrankung vor dem Alter von 60 Jahren?',
      sleepWeekdays: 'Wie viele Stunden schlafen Sie an Wochentagen?',
      sleepWeekends: 'Wie viele Stunden schlafen Sie an Wochenenden oder Feiertagen?',
      isRefreshed: 'Sind Sie am Morgen erholt?',
      psychometric: 'Wie würden Sie Ihre allgemeine Gesundheit einschätzen?',
      fruitsVegetables: 'Wie oft essen Sie Obst und Gemüse pro Woche?',
      fishMeat: 'Wie oft essen Sie rotes Fleisch, Geflügel oder Fisch pro Woche?'
    },
    phaseOneResult: {
      condition: {
        good: 'gut',
        veryGood: 'sehr gut',
        excellent: 'hervorragend',
      },
      graph: {
        health: 'Ihr',
        estimate: 'Ergebnis'
      },
      result: {
        success: 'Glückwunsch! Ihr Gesundheitszustand scheint {{health}} zu sein!<br /> Behalten Sie Ihre gesunden Gewohnheiten bei.', // Варианты  {{health}} берутся из phaseOneResult.condition
        failure: `Sie haben möglicherweise einige gesundheitliche Probleme, die Sie untersuchen lassen sollten.
          Wir empfehlen Ihnen, sich an den Arzt oder die Ärztin Ihres Vertrauens zu wenden oder eines unserer
         Labore für eine genauere Analyse zu besuchen. Wir würden gerne ein paar Bluttests durchführen, um eine vollständige Übersicht zu erlangen.
         Um einen Termin zu vereinbaren, bitte nutzen Sie diesen Link:`,
        id: 'Ihre Id'
      },
      report: 'Bericht erhalten'
    },
    search: {
      error: 'Patient nicht gefunden',
      find: 'Suchen'
    },
    lifeStyle: {
      smokeTime: 'Rauchen',
      packYears: 'Packungsjahre',
      since: 'seit',
      to: 'bis',
      aDay: 'pro Tag',
      alcoBeerPerWeek: {
        label: 'Bier',
        popOverText: 'Wie viel Bier trinken Sie durchschnittlich pro Woche?',
      },
      alcoWinePerWeek: {
        label: 'Wein',
        popOverText: 'Wie viel Wein trinken Sie durchschnittlich pro Woche?',
      },
      alcoWhiskeyPerWeek: {
        label: 'Spirituosen',
        popOverText: 'Wie viel Schnaps trinken Sie durchschnittlich pro Woche?',
      },
      coffePerDay: {
        label: 'Kaffee',
        popOverText: 'Wie viele Tassen Kaffee trinken Sie durchschnittlich pro Tag?',
      },
      teaPerDay: {
        label: 'Tee',
        popOverText: 'Wie viele Tassen Tee trinken Sie durchschnittlich pro Tag?',
      },
      exerciseWalkPerWeek: {
        label: 'Spaziergänge',
        popOverText: 'Wie viele Stunden pro Woche gehen Sie durchschnittlich spazieren?',
      },
      exerciseRunPerWeek: {
        label: 'Laufen',
        popOverText: 'Wie viele Stunden pro Woche laufen Sie im Durchschnitt?',
      },
      exerciseBicyclePerWeek: {
        label: 'Fahrradfahren',
        popOverText: 'Wie viele Stunden pro Woche fahren Sie durchschnittlich Fahrrad?',
      },
      exerciseSimulatorsPerWeek: {
        label: 'Crosstraining',
        popOverText: 'Wie viele Stunden pro Woche gehen Sie durchschnittlich auf den Crosstrainer?',
      },
      exerciseSwimmingPerWeek: {
        label: 'Schwimmen',
        popOverText: 'Wie viele Stunden schwimmen Sie durchschnittlich pro Woche?',
      },
      nutFruit: {
        label: 'Obst',
        popOverText: 'Wie oft pro Woche isst du Obst?',
      },
      nutVegetables: {
        label: 'Gemüse',
        popOverText: 'Wie oft pro Woche essen Sie Gemüse?',
      },
      nutChicken: {
        label: 'Geflügel',
        popOverText: 'Wie oft pro Woche essen Sie mehr als 150 Gramm Geflügel?',
      },
      nutRedMeat: {
        label: 'Rotes Fleisch',
        popOverText: 'Wie oft pro Woche essen Sie mehr als 150 Gramm rotes Fleisch?',
      },
      nutFish: {
        label: 'Fisch',
        popOverText: 'Wie oft pro Woche essen Sie mehr als 150 Gramm Fisch?',
      },
    },
    morbidity: {
      treated: 'in Behandlung',
      diagnosedDiabet: 'Diabetes',
      diagnosedHypertension: 'Bluthochdruck',
      highBloodSugar: '$t(morbidity.diagnosedDiabet) $t(morbidity.treated)', // Значения подствляются автоматически
      highBloodPressure: '$t(morbidity.diagnosedHypertension) $t(morbidity.treated)', // Значения подствляются автоматически
      kidneyDesease: 'Nierenerkrankung',
      metabolicSyndrome: 'Stoffwechselerkrankung',
      cardiovascular: 'Herz-Kreislauf-Erkrankung',
      stroke: 'Schlaganfall',
      cancer: 'Krebs',
    },
    family: {
      parents: 'Eltern'
    },
    metrics: {
      mmHg: 'mmHg',
      mmolL: 'mmol/L',
      UL: 'U/L',
      bloodPressureSystolic: 'Systolischer Blutdruck ($t(metrics.mmHg))',
      bloodPressureDiastolic: 'Diastolischer Blutdruck ($t(metrics.mmHg))',
      lipidTotalCholesterol: 'Gesamt-Cholesterin ($t(metrics.mmolL))',
      highDensityLiprotein: 'Lipoprotein hoher Dichte HDL ($t(metrics.mmolL))',
      lowDensityLiprotein: 'Lipoprotein niederer Dichte LDL ($t(metrics.mmolL))',
      ratioOfTotalCholesterolToHdl: 'Verhältnis von Gesamtcholesterin zu HDL',
      triglycerides: 'Triglyceride ($t(metrics.mmolL))',
      fastingBloodGlucose: 'Nüchternblutzucker ($t(metrics.mmolL))',
      glycatedHemoglobin: 'Glykiertes Hämoglobin (%)',
      cReactiveProtein: 'C-reaktives Protein (mg/L)',
      alanineAminotransferase: 'Alanin-Aminotransferase ($t(metrics.UL))',
      aspartateAminotransferase: 'Aspartat-Aminotransferase ($t(metrics.UL))',
      gammaGlutamylTransferase: 'Gamma-Glutamyl-Transferase ($t(metrics.UL))',
      glomerularFiltrationRate: 'Glomeruläre Filtrationsrate (mL/min/1.73 m^2)',
      albuminCreatinineUrineRatio: 'Albumin-Kreatinin-Urin-Verhältnis (mg/mmol)',
    },
    riskFactors: {
      normalRange: 'Statistisch erwarteter Bereich',
      normalValue: 'Erwarteter Wert',
      expectedRange: 'Erwarteter Bereich',
      actual: 'Tatsächlicher Wert',
      obesity: 'Fettleibigkeit',
      movement: 'Bewegung',
      sleep: 'Schlaf',
      glucose: 'Glucose',
      lipids: 'Lipide',
      bloodPressure: 'Blutdruck',
      ironMetabolism: 'Eisenstoffwechsel',
    },
    risks: {
      timeHorizon: 'zeithorizont {{time}} Jahre',
      riskRatio: 'Absolute Risiken',
      cardiovascular: 'Kardiovaskuläre Erkrankung',
      metabolicDysfunction: 'Stoffwechselerkrankung',
      liver: 'Lebererkrankung',
      dementia: 'Demenz',
      atherosclerosis: 'Atherosklerose',
      COPD: 'Chronisch obstruktive Lungenerkrankung',
      OSA: 'Obstruktives Schlafapnoesyndrom',
      ACM: 'Gesamt-Mortalität',
      CHF: 'Kongestive Herzinsuffizienz',
      aMI: 'Akuter Herzinfarkt',
      ESLD: 'Leberzirrhose',
      ESRD: 'Nierenerkrankung im Endstadium',
      diabetesMellitus2: 'Zuckerkrankheit 2',
      heartFailure: 'Herzfehler',
      totalCancer: 'Totaler Krebs'
    },
    export: {
      printExport: 'Drucken/Exportieren',
      report: 'Bericht',
      patient: '$t(export.printExport) $t(export.report) für den Patienten',
      physician: '$t(export.printExport) $t(export.report) für den Arzt',
    },
    drillDownGraph: {
      chart: {
        years: 'Jahre',
        survival: 'Überlebenswahrscheinlichkeit',
        intervention: 'Intervention',
        noIntervention: 'Keine $t(drillDownGraph.chart.intervention)',
      },
      filters: {
        data: 'Daten',
        intervention: 'Intervention',
        age: 'Alter',
        gender: 'Geschlecht',
        weight: '$t(basicData.weight) (kg)',
        nyhaClass: 'NYHA-Klassifikation',
        ejectionFraction: 'Ejektionsfraktion (%)',
        ischemicEtiology: 'Ischämische Ätiologie',
        sbp: 'Systolischer Blutdruck (mmHG)',
        sodium: 'Natrium (mEq/L)',
        totalCholesterol: 'Gesamtcholesterin (mmol/L)',
        hemoglobin: 'Hämoglobin (g/dL)',
        lymphocytes: 'Lymphozyten (%)',
        uricACid: 'Harnsäure (mg/dL)',
        diureticDose: 'Diuretische Dosis (mg/day)',
        aceInhibitor: 'Ace-Hemmer',
        bBlocker: 'β-Blocker',
        arb: 'ARB',
        ksparingDiuretic: 'K-sparendes Diuretikum',
        allopurinolUse: 'Verwendung von Allopurinol',
        statinUse: 'Statin-Einsatz',
        biventricularPacemaker: 'Biventrikulärer Herzschrittmacher',
        icd: 'ICD',
        biventricularIcd: 'Biventrikulärer ICD',
      }
    },
    plusRisks: {
      chart: {
        timeHorizon: 'Zeithorizont: 5 Jahre',
        averageValue: 'Durchschnittswert',
        baseLineValue: 'Basiswert',
        confidenceInterval: 'Konfidenzintervall',
        reset: 'Zurücksetzen'
      },
      filter: {
        quitSmoking: 'Hör auf zu rauchen'
      }
    },
    month: {
      january: 'Januar',
      february: 'Februar',
      march: 'Marsch',
      april: 'April',
      may: 'Mai',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Dezember',
    },
    report: {
      underweight: 'zu leicht',
      normal: 'normal',
      overweight: 'leicht erhöht',
      obese: 'stark erhöht',
      harmless: 'unbedenklich',
      harmlessCigarette: 'unbedenklich',
      slightlyHarmful: 'leicht schädlich',
      extremelyHarmful: 'sehr schädlich',
      requiresAction: 'riskant',
      insufficient: 'ungenügend',
      sufficient: 'genügend',
      good: 'gut',
      healthy: 'unbedenklich',
      notQuiteHealthy: 'leicht schädlich',
      unhealthy: 'sehr schädlich',
      stronglyIncreased: 'stark erhöht',
      slightlyIncreased: 'leicht erhöht',
      low: 'unbedenklich',
      increased: 'auffällig',
      lipidStronglyIncreased: 'sehr auffällig',
      lipidSlightlyIncreased: 'auffällig',
      lipidLow: 'unbedenklich',
    },
    overallRisk: {
      good: 'gut',
      fair: 'mässig beeinträchtig',
      poor: 'stark beeinträchtigt'
    },
    validator: {
      presence: 'Wert erforderlich',
      tooLong: 'ist zu lang (Maximum ist {count, number} {count, plural, one {character} other {characters}})',
      wrongLength: 'ist die falsche Länge (sollte {count, number} {count, plural, one {character} other {characters}} sein)',
      invalid: 'ist ungültig',
      notANumber: 'ist keine Zahl',
      notAnInteger: 'ist keine ganze Zahl',
      greaterThan: 'muss größer als {count, number} sein',
      lessThanOrEqualTo: 'muss kleiner sein als {count, number}',
      greaterThanOrEqualTo: 'muss größer oder gleich {count, number} sein',
      lessThan: 'muss kleiner sein als {count, number}'
    },
    combinedPhaseResult: {
      id: 'Ihre Id',
      info: 'Schreiben Sie es auf, wenn Sie Informationen mit Ihrem Gesundheitsdienstleister teilen möchten.',
      report: 'Bericht erhalten'
    },
    phase1Report: {
      pdfBtn: 'PDF',
      header: 'Phase 1 dok',
      needMedicineYes1: 'Sie haben möglicherweise einige gesundheitliche Probleme, die Sie untersuchen lassen sollten. Die nachstehenden Tipps zeigen Ihnen auf, wie Sie Ihren Alltag gesünder gestalten können. Vor allem aber empfehlen wir Ihnen, sich an den Arzt oder die Ärztin Ihres Vertrauens zu wenden oder eines unserer Labore für eine genauere Analyse zu besuchen. Wir würden gerne ein paar Bluttests durchführen, um eine vollständige Übersicht zu erlangen.',
      needMedicineYes2: 'Um einen Termin zu vereinbaren, nutzen Sie bitte diesen Link: [LINK TO REFERENCE SITE]',
      needMedicineNo1: 'Herzliche Gratulation! Bei Ihnen bestehen keine wesentlichen Gesundheitsrisiken. Führen Sie Ihren gesunden Lebensstil weiter und machen Sie den Gesundheitscheck in xx Jahren wieder. Eventuell helfen Ihnen die nachstehenden Tipps, Ihren Alltag noch gesünder zu gestalten. Auch mit Ihrem guten Resultat kann sich eine vertiefte Überprüfung lohnen und wir möchten Sie einladen, sich in einem unserer Labore für eine Blutentnahme zu melden.',
      needMedicineNo2: 'Um einen Termin zu vereinbaren, nutzen sie bitte diesen Link: [LINK TO REFERENCE SITE]',
      generalRecommendationTitle: 'Allgemeine Gesundheitsempfehlungen',
      generalRecommendationSubTitle: 'Unsere Gesundheitsempfehlungen basieren auf 4 wesentlichen Elementen.',
      point1Title: 'Nicht rauchen',
      point1Body: 'Rauchen ist der mit Abstand bedeutendste Risikofaktor, den Sie selbst beeinflussen können. Raucher verlieren je nach Untersuchung und Bevölkerungsgruppe zwischen 5 und 11 Lebensjahre. Rauchen ist auch der wesentliche Risikofaktor für das Entwickeln einer Chronisch Obstruktiven Lungenerkrankung (COPD) und die Grundlage für die Entstehung von Lungenkrebs. Entgegen einer früheren Einschätzung ist bereits das Rauchen einer einzigen Zigarette täglich verantwortlich für etwa die Hälfte des damit verbundenen Risikos. Damit kommt nur ein absoluter Rauchstopp infrage. Diverse Gesellschaften und Ratgeber unterstützen Sie bei einem Rauchstopp.',
      point2Title: 'Regelmässige Bewegung',
      point2Body: 'Unter Berücksichtigung der Möglichkeit, Ihre Gelenke zu belasten sollten Sie die Ausdauerfitness erhalten. Bereits 75 Minuten schweisstreibende Aktivitäten pro Woche, aufgeteilt in 4 bis 5 Trainings, bewirken eine deutliche Reduktion des kardio-vaskulären Risikos. Alternativ reichen auch 150 Minuten moderate körperliche Aktivität, beziehungsweise eine Kombination der beiden. An 2 Tagen in der Woche empfehlen wir ein gezieltes Krafttraining, das sämtliche Muskelgruppen berücksichtigt, vor allem aber die Rumpfmuskulatur. Wir empfehlen also ein ausgeglichenes Programm an 6 Tagen in der Woche in den vier Dimensionen: Kraft, Ausdauer, Koordination/Gleichgewicht und Beweglichkeit.',
      point3Title: 'Ausgewogene Ernährung',
      point3Body: 'Es gibt unzählige Empfehlungen, wie Sie sich gesund ernähren können. Als eine besonderes gut dokumentierte und wissenschaftlich untersuchte Form empfehlen wir eine Mediterrane Ernährung. Olivenöl, reichlich frisches Gemüse, Früchte und Fisch gehören zu den wichtigen Bestandteilen. Auch ein Glas (Rot-) Wein ist dabei erlaubt. Essen Sie wenn möglich in Gesellschaft und geniessen Sie die Pause im Alltag. Meiden Sie Zucker, Fertigprodukte und stark verarbeitete Nahrungsmittel, etwa aus Weissmehl. Trinken Sie genügend Wasser, vor allem bei warmem Wetter und erhöhter körperlicher Aktivität.',
      point4Title: 'Ausreichend Schlafen',
      point4Body: 'In den letzten Jahren ist die Bedeutung des Schlafs für die Gesundheit im Allgemeinen und vor allem für die Hirnleistung zunehmend in den Vordergrund gerückt. In der REM-Phase (Traumphase) werden Gedächtnis Inhalte gefestigt und im Tiefschlaf wird das Hirn von Abfällen entlastet, welche sich tagsüber angestaut haben. Zu den wichtigsten Punkten einer guten Schlafhygiene gehören: Ein dunkles, ruhiges und kühles Schlafzimmer, keine Coffein-haltigen Getränke nach 16 Uhr, keine anregenden Tätigkeiten nach 21 Uhr, insbesondere keine Arbeit an Bildschirmen mehr (Mails, SMS, etc.). Schalten Sie ab etwa 21 Uhr alle Bildschirme auf den Nachtmodus um. Gehen Sie auch nicht mit vollem Magen ins Bett und meiden Sie, im Schlafzimmer zu arbeiten oder TV zu schauen. Schliesslich soll das Bett bequem sein und das Kopfkissen eine entspannte Haltung von Kopf und Nacken ermöglichen.',
    },
    phase2Report: {
      header: 'Ihre Resultate',
      headerText1: 'Vielen Dank, dass Sie unseren digitalen Check-up durchgeführt haben, um mehr über Ihre gesundheitlichen Risiken zu erfahren. Die folgenden Analysen und Aussagen stützen sich auf Ihre gesundheitsbezogenen Angaben und Messungen sowie auf die erhobenen Laborresultaten. Die Auswertung basiert auf dem gesammelten Wissen von über 5000 Experten in der Medizin und von Studienresultaten bei über 400 Millionen Patientenjahren.',
      headerText2: 'In der Folge teilen wir Ihnen Ihre Resultate mit, interpretieren diese und geben Ihnen wertvolle Tipps, wie Sie Ihre Gesundheitssituation verbessern können.',
      riskFactors: 'Risikofaktoren',
      overweightTitle: 'Übergewicht und Adipositas',
      overweightText1: 'Übergewicht und Adipositas gehören zu den wichtigsten Risikofaktoren für eine Reihe von Erkrankungen, wie Herz-Gefässleiden, Leberfunktionsstörungen und Diabetes. Darüber hinaus ist erhöhtes Körpergewicht ein Risikofaktor für Gelenkserkrankungen und Rückenleiden. Auch Untergewicht ist zu vermeiden, denn häufig fehlen bei schweren Erkrankungen Reserven.',
      overweightText2: 'Mit einem Body Mass Index von {{bmi}} ist Ihr Gewicht in Relation zur Körpergrösse:',
      alcoholTitle: 'Schädlicher Alkohol Konsum',
      alcoholText1: 'Alkoholische Getränke führen ab einer bestimmten Menge und/oder Häufigkeit auch bei sonst gesunden Personen zu gesundheitlichen Problemen. Im Besonderen nimmt die Leber Schaden, doch auch der Schlaf und damit die mentale Gesundheit werden negativ durch Alkoholkonsum in den Abendstunden beeinflusst. Zudem ist unter Alkoholeinfluss das Unfallrisiko deutlich erhöht.',
      alcoholText2: 'Gemäss Ihren Angaben ist ihr Alkohol Konsum:',
      smokingTitle: 'Rauchen',
      smokingText1: 'Rauchen, vor allem das Inhalieren von Zigarettenrauch, ist der wichtigste, durch Sie selbst beeinflussbare Risikofaktor überhaupt. Zigarettenraucher verlieren bis zu 10 Jahren an Lebenserwartung durch Lungenerkrankungen, Herz-Kreislaufleiden und speziell auch verschiedene Krebsleiden. Bereits das Rauchen einer einzigen Zigarette pro Tag ist für etwa die Hälfte der Risikoerhöhung verantwortlich. Daher kommt nur ein vollständiger Rauchstopp infrage.',
      smokingText2: 'Ihr Verhalten ist:',
      movementTitle: 'Bewegungsmangel',
      movementText1: 'Die Annehmlichkeiten eines modernen Alltags haben zu einem enormen Bewegungsmangel geführt. Schon längeres Sitzen ist ein eigenständiger Risikofaktor für die Gesundheit. Sportliche Aktivität im Ausdauerbereich senkt nachweislich das Risiko für Herz-/Gefässleiden, Krebs und Abbau der geistigen Fähigkeiten. Wir empfehlen entweder 75 Minuten sportliche Tätigkeiten im hohen Intensitätsbereich oder 150 Minuten in einem mittleren Intensitätsbereich, bzw. eine Kombination davon verteilt auf mindestens 4, besser 6 Trainingstage pro Woche. Ein auf Kraft fokussiertes Training zwei Mal pro Woche ergänzt das Fitness Programm ideal.',
      movementText2: 'Gemäss Ihren Angaben ist Ihr Bewegungsprofil',
      nutritionTitle: 'Schlechte Ernährungsgewohnheiten',
      nutritionText1: 'Heutige Essgewohnheiten verleiten zur Einnahme von zu vielen Nahrungsmitteln mit hoher Energiedichte und ungesunden Begleitstoffen, wie Kochsalz, Zucker und Konservierungsmittel. Eine gesunde Ernährung basiert vor allem auf frischen Ausgangsprodukten mit täglich 3 Portionen Gemüse und 2 Portionen Früchten aus einem bunten Farbengemisch, gesunden Eiweissquellen und Kohlenhydraten aus Vollkornprodukten. Zu meiden sind generell Zucker, hoch verarbeitete Fertigprodukte und vor allem verarbeitete Fleischprodukte.',
      nutritionText2: 'Gemäss Ihren Angaben sind Ihre Ernährungsgewohnheiten',
      sleepTitle: 'Ungenügender Schlaf',
      sleepText1: 'Unser Verständnis für die Funktionen des Schlafs hat in den letzten Jahren enorm zugenommen. Der Schlaf dient nicht nur der körperlichen Erholung, sondern auch der Verfestigung von Gedächtnisinhalte. Im Schlaf wird das Hirn von schädlichen Stoffwechselprodukten der Nervenzellen gereinigt, was einem Abbau der geistigen Fähigkeiten entgegenwirkt. Mit wenigen Ausnahmen beträgt der tägliche Schlafbedarf zwischen 7 und 8 ½ Stunden. Auch ob Sie ein Morgenmensch oder eine Nachteule sind, ist individuell verschieden. Sie finden Ihr eigenes Schlafmuster am besten in einem Ferienaufenthalt, wo Sie ohne äussere Taktgeber und Programmpunkte frei ins Bett gehen und aufstehen können.',
      sleepText2: 'Gemäss Ihren Angaben ist Ihr Schlafverhalten ist',
      bloodPressureTitle: 'Bluthochdruck',
      bloodPressureText1: 'Auch ein hoher Blutdruck gehört zu den häufigsten Risikofaktoren und wie beim Diabetes besteht eine genetische Veranlagung. Ein erhöhter Blutdruck entwickelt sich schleichend, häufig parallel zu einer Gewichtszunahme. Hoher Blutdruck ist ein Risikofaktor für Herz- und Gefässleiden, vor allem auch für einen Hirnschlag. Ein verbesserter Lebensstil mit viel Bewegung und einer gesunden Ernährung kann häufig genügen, den Blutdruck wieder zu normalisieren.',
      bloodPressureText2: 'emäss unseren Berechnungen und Messungen ist Ihr Risiko:',
      glucoseTitle: 'Zuckerstoffwechsel',
      glucoseText1: 'Die Blutzuckerregulation gehört zu den wichtigsten Stoffwechselaufgaben. Ein abgestimmtes System an Hormonen und Enzymen sorgt für einen ausgeglichenen Zuckerspiegel, weitgehend unabhängig von Mahlzeiten. Beim Typ 2 Diabetes kommt es auf dem Boden einer genetischen Veranlagung und meistens im Rahmen einer Gewichtszunahme zu einer zunehmenden Entgleisung des Zuckerstoffwechsels und schliesslich zu Schäden an Gefässen und wichtigen Organen.',
      generalText2: 'Gemäss unseren Berechnungen und Messungen ist Ihr Risiko:',
      lipidsTitle: 'Fettstoffwechselstörung',
      lipidsText1: 'Wie auch beim Zuckerstoffwechsel bestimmt im Wesentlichen ein genetisches Profil die Höhe der Blutwerte von Cholesterin und Triglyceriden. Beide Arten von Fett sind zum einen für den Körper wichtige Bausteine, bei zu hohen Blutwerten sind sie jedoch schädlich, vor allem für die Blutgefässe. Dies ist wiederum die Grundlage für beispielsweise Herzinfarkte, Hirnschläge und weitere Durchblutungsstörungen.',
      ironMetabolismTitle: 'Eisenstoffwechsel',
      ironMetabolismText1: 'Eisen ist für viele Körperfunktionen zentral, vor allem aber ist es ein wichtiger Bestandteil von roten Blutkörperchen. Da die Eisenaufnahme im Darm begrenzt ist, kommt es bei erhöhten Verlusten oder ungenügender Einnahme rasch zu einem Mangel. Zu viel Eisen ist jedoch auch schädlich, weshalb es auch wichtig ist, eine Eisenüberladung frühzeitig festzustellen.',
      diseaseRisks: 'Krankheitsrisiken',
      diabetesMellitus2Title: 'Diabetes, auch Zuckerkrankheit genannt',
      diabetesMellitus2Text1: 'Der Diabetes Typ 2 ist eine der häufigsten Erkrankungen und es besteht eine genetische Veranlagung dazu. Die Krankheit entwickelt sich schleichend und meistens im Zusammenhang mit einer Gewichtszunahme. Diabetiker weisen ein deutlich erhöhtes Risiko für Herz- und Gefässleiden auf. Erste Massnahmen gegen die Krankheit sind regelmässige sportliche Aktivitäten und eine an Kalorien und Kohlehydraten reduzierte Ernährung mit dem Ziel, das Gewicht zu reduzieren.',
      diagnosedHypertensionTitle: 'Hoher Blutdruck',
      diagnosedHypertensionText1: 'Auch ein hoher Blutdruck gehört zu den häufigsten Erkrankungen und wie beim Diabetes besteht eine genetische Veranlagung. Ein erhöhter Blutdruck entwickelt sich über viele Jahre schleichend und häufig parallel zu einer Gewichtszunahme. Langjähriger Bluthochdruck führt zu Gefässschäden und ist ein Risikofaktor für Herzinfarkte, Hirnschläge und Nierenfunktionsstörung. Ein verbesserter Lebensstil mit viel Bewegung und einer gesunden Ernährung kann häufig genügen, den Blutdruck wieder zu normalisieren.',
      kidneyDeseaseTitle: 'Nierenfunktionsstörung',
      kidneyDeseaseText1: 'Die Nieren nehmen eine zentrale Rolle für die Regulierung des Wasser- und Salzhaushaltes ein und lassen den Körper viele Schadstoffe ausscheiden. Da eine eingeschränkte Nierenfunktion lange keine Beschwerden verursacht, ist eine gelegentliche Kontrolle entscheidend, bereits bei frühen Krankheitsstadien eingreifen zu können. Im Vordergrund steht dabei der Schutz vor weiterer Verschlechterung, indem Risikofaktoren und Medikamente optimiert werden.',
      liverTitle: 'Leberfunktionsstörung',
      liverText1: 'Die Leber ist unser zentrales Stoffwechselorgan und wird häufig als Chemiefabrik des Körpers bezeichnet. Nebst einer Reihe von Viren und anderen Krankheitserregern, welche die Leber angreifen, sind vor allem giftige Substanzen und ein regelmässiger erhöhter Alkoholkonsum für eine Schädigung verantwortlich. Wie bei der Nierenfunktion geht es darum, eine Schädigung frühzeitig zu erfassen und sämtliche schädlichen Substanzen zu eliminieren, beziehungsweise chronische Infektionen zu behandeln.',
      metabolicDysfunctionTitle: 'Fettstoffwechselstörung',
      metabolicDysfunctionText1: 'Wie auch beim Diabetes bestimmt im Wesentlichen ein genetisches Profil die Höhe der Blutwerte von Cholesterin und Triglyceriden. Sowohl ein erhöhtes Cholesterin als auch erhöhte Triglyceride spielen eine zentrale Rolle bei der Entstehung von sogenannten Plaques in den Blutgefässen. Diese sind wiederum die Basis für Gefässverschlüsse, welche zu Herzinfarkten und Hirnschlägen führen. Die Gefässschäden entwickeln sich über viele Jahre und mit einer frühen Erkennung und geeigneten Massnahmen kann der Krankheitsverlauf günstig beeinflusst, ja sogar teilweise rückgängig gemacht werden.',
      heartFailureTitle: 'Herzinsuffizienz',
      heartFailureText1: 'Eine ganze Reihe von auslösenden Faktoren und genetischen Varianten kann zu einer Schwächung der Herzmuskulatur führen. Wegen der abnehmenden Pumpfunktion kommt es zu einer eingeschränkten körperlichen Leistungsfähigkeit und damit zu einer erheblichen Einbusse der Lebensqualität. Gewisse Risikofaktoren lassen sich durch geeignete Massnahmen günstig beeinflussen und daher ist eine rechtzeitige Behandlung der Erkrankung wichtig.',
      overallRisk: 'Platzhalter Gesamtrisiko',
      healthSliceTitle: 'Allgemeiner Gesundheitszustand',
      healthSliceText: 'Usammenfassend kann Ihr Gesundheitszustand als überwiegend',
      healthSliceHigh: 'Wir gratulieren Ihnen zu Ihrem Ergebnis und wünschen Ihnen weiterhin gute Gesundheit. Wir empfehlen Ihnen in [x] Jahren oder bei veränderten Ausgangsbedingungen wieder einen Check-up durchzuführen.',
      healthSliceMid: 'Trotz teilweiser guter Grundlagen bestehen bei Ihnen Gesundheitsrisiken. Wir empfehlen Ihnen unsere Ratschläge zu beachten und sprechen Sie die aufgedeckten Risiken bei Ihrem nächsten Arztbesuch an.',
      healthSliceLow: 'Nach unserer Überzeugung sind Ihre Gesundheitsrisiken stark erhöht und wir raten Ihnen, die gesamte Situation mit Ihrem Hausarzt oder Hausärztin zu besprechen. Zusammen können Sie einen Plan erstellen, wie Sie schrittweise die Risiken angehen und so zu einem gesünderen Leben zurückkehren können.',
    }
  }
};

export default de;
