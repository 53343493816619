import React from 'react';
import round from 'lodash/round';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TranslatedText from 'components/TranslatedText';
import DeseaseRisksGraph from 'components/DeseaseRisksGraph';
import {normalizedValue} from 'helpers/utils';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

const DiseaseRisks = () => {
  const classes = useStyles();
  const data = JSON.parse(sessionStorage.getItem('diseaseRisks'));
  const {t} = useTranslation();

  return (
    <Box>
      <Box mt={2} mb={1}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={7}>
            <div className={classes.gradient} />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
      <Box mt={2} mb={1}>
        {data.map(row => (
          <Grid key={row.name} container spacing={2} justify="center" alignItems="center">
            <Grid item xs={4}>
              <TranslatedText text={row.name} />
            </Grid>
            <Grid item xs={7}>
              <DeseaseRisksGraph
                engineMin={0}
                engineMax={1}
                ciMin={normalizedValue(row.ciMin)}
                ciMax={normalizedValue(row.ciMax)}
                measured={normalizedValue(row.risk)}
                refVal={normalizedValue(row.risk / row.refVal)}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography>{row.value ? round(row.value, 2) : ''}</Typography>
            </Grid>
          </Grid>
        ))}

      </Box>
      <Box mt={2} mb={2}>
        <Grid container mt={2} spacing={4}>
          <Grid item><Typography>{t('plusRisks.chart.timeHorizon')}</Typography></Grid>
          <Grid item>
            <Grid container alignItems="baseline">
              <span className={classes.ageGender} />
              <Typography>- {t('plusRisks.chart.averageValue')}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <span className={classes.confidenceInterval} />
              <Typography>- {t('plusRisks.chart.confidenceInterval')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>

  );
};

export default DiseaseRisks;
