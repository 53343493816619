import {makeStyles} from '@material-ui/core/styles';

import {colorPrimary} from 'theme/variables';

import resultSuccess from 'assets/images/resultSuccess.png';
import resultAlert from 'assets/images/resultAlert.png';

export default makeStyles(theme => ({

  resultBack: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute'
  },

  resultSuccess: {
    backgroundImage: `url(${resultSuccess})`
  },

  resultAlert: {
    backgroundImage: `url(${resultAlert})`
  },

  resultWrapper: {
    textAlign: 'center',
  },

  successH: {
    fontWeight: 600,
    lineHeight: 'normal',
    color: colorPrimary,
    marginBottom: '24px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '64px',
    },
  },

  successP: {
    fontWeight: 500,
    lineHeight: 'normal',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
  },

  alertP: {
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
  },

  alertLink: {
    borderRadius: '20px',
    padding: '8px 22px 8px 22px',
    display: 'inline-block'
  },

  medlogo: {
    height: 54,
    width: 188,
    margin: '0 auto'
  },

}));
