import {
  borderRadius,
  colorPrimary,
  colorTextBase,
  colorTextMedium,
  controlHeightSmall,
  colorDanger,
  colorBorder,
  colorBorderHover,
  colorControl,
  colorControlDisabled,
  colorBgLightActive
} from 'theme/variables';

const setColorBorder = (state, hover) => {
  const error = state.selectProps.error && state.selectProps.touched;
  const focused = state.isFocused;

  if (error) {
    return colorDanger;
  }

  if (focused) {
    return colorPrimary;
  }

  if (hover) {
    return colorBorderHover;
  }

  return colorBorder;
};

const styles = {
  container: provided => ({
    ...provided,
    minWidth: '180px'
  }),

  control: (provided, state) => ({
    ...provided,
    fontSize: 16,
    fontWeight: 600,
    minHeight: controlHeightSmall,
    boxShadow: 'none',
    backgroundColor: state.isDisabled ? colorControlDisabled : colorControl,
    borderColor: setColorBorder(state, 0),
    borderRadius,

    '&:hover': {
      borderColor: setColorBorder(state, 1),
    }
  }),

  valueContainer: (provided) => ({
    ...provided,
    minHeight: controlHeightSmall - 2,
  }),

  input: (provided) => ({
    ...provided,
    color: colorTextBase,
    fontSize: 16,
    marginTop: 0,
    paddingTop: 0,

    '& input[type="text"]': {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 600
    }
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? colorTextMedium : colorTextBase,
    fontSize: 16,
    transform: 'none',
    top: 0,
    position: 'relative'
  }),

  placeholder: (provided) => ({
    ...provided,
    fontWeight: 400
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    whiteSpace: 'normal',
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
    marginRight: '8px',
  }),

  menu: provided => ({
    ...provided,
    marginTop: 0,
    fontWeight: 500,
    border: '1px solid transparent',
    borderTop: 'none',
    borderRadius: `0 0 ${borderRadius}px ${borderRadius}px`,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    zIndex: 999,
  }),

  option: provided => ({
    ...provided,
    '&:active': {
      backgroundColor: colorBgLightActive,
      textShadow: '0 0 currentColor',
    },
  }),

};
export const shortStyles = {
  control: (provided) => ({
    ...provided,
    fontWeight: 600,
    minHeight: 'auto',
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent'

  }),

  input: (provided) => ({
    ...provided,
    '& input[type="text"]': {
      width: '0!important'
    }
  }),

  singleValue: (provided) => ({
    ...provided,
    color: colorPrimary,
    fontSize: 16,
    transform: 'none',
    position: 'relative',
    textOverflow: 'inherit',
    padding: '0px 4px',
    top: 0,
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: () => ({
    display: 'none'
  }),

  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    zIndex: 4,
  }),
};

export default styles;
