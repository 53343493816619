import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
  controlItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    marginRight: '16px',
    fontWeight: 600,
    flexBasis: 550,

    [theme.breakpoints.down('810')]: {
      minHeight: 0,
      marginBottom: '16px',
      marginRight: 0,
    },
  },
  fieldWrapper: {
    [theme.breakpoints.down('810')]: {
      justifyContent: 'flex-end',
      marginRight: '15px'
    },
  },
  numField: {
    width: '100px'
  },
  validation: {
    width: '150px',
    textAlign: 'right',
    marginRight: '16px',

    [theme.breakpoints.down('810')]: {
      minHeight: 0,
      textAlign: 'left',
      marginRight: 0
    },
  },
}));
