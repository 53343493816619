import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from 'components/IconButton';
import {Form as CoreForm} from 'react-final-form';
import {useHistory} from 'react-router';
import RiskFactors from './RiskFactors';
import calculator from './formDecorator';

const decorators = [calculator];

const Form = ({initialValues, onSubmit, riskFactorsData}) => {
  const history = useHistory();

  const handlePrev = useCallback(() => {
    const params = {
      pathname: '/phase3',
      state: {toLastPage: true}
    };
    history.push(params);
  }, [history]);

  return (
    <CoreForm
      onSubmit={onSubmit}
      decorators={decorators}
      initialValues={initialValues}
      render={({handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <RiskFactors data={riskFactorsData} />
          <Box marginTop="30px" marginBottom="30px">
            <Grid container spacing={2} justify="center">
              <Grid item>
                <IconButton color="primary" onClick={handlePrev}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton type="submit" color="primary" disabled={submitting}>
                  <ArrowForwardIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    />
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  riskFactorsData: PropTypes.object,
};

export default Form;
