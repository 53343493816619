import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {format} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import _ from 'lodash';

import {Field} from 'react-final-form';
import {FormControl, FormHelperText} from '@material-ui/core';
import {colorDanger, colorBorder} from 'theme/variables';
import PrimaryCloseIcon from '@material-ui/icons/Close';

const Picker = styled(DatePicker)`
  color: unset;
  fieldset {
    border-color: ${({error, touched}) => (!!error && touched ? colorDanger : colorBorder)};
  }
`;

const CloseIcon = styled(PrimaryCloseIcon)`
  cursor: pointer;
  color: hsl(0,0%,80%);
  &:hover{
    color: hsl(0,0%,60%);
  }
`;

const view = ['year'];

const YearPicker = ({input: {value, onChange, ...input}, meta: {touched, error},
  fullWidth, isClearable, margin, disabled, ...rest}) => {
  const handleClear = useCallback((e) => {
    e.stopPropagation();
    onChange(null);
  }, [onChange]);

  return (
    <FormControl
      error={touched && !!error}
      fullWidth={fullWidth}
      margin={margin}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Picker
          variant="inline"
          autoOk
          orientation="portrait"
          views={view}
          error={touched && !!error}
          value={value || null}
          onChange={onChange}
          invalidDateMessage=""
          disabled={disabled}
          disableToolbar
          inputVariant="outlined"
          InputLabelProps={{
            variant: 'outlined',
          }}
          InputProps={{
            endAdornment: isClearable && !disabled ? <CloseIcon onClick={handleClear} /> : null
          }}
          PopoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            }
          }}
          {...input}
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

YearPicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.object,
  isClearable: PropTypes.bool,
};

const FormField = props => (
  <Field
    component={YearPicker}
    format={value => (value ? new Date(_.toString(value)) : undefined)}
    parse={value => (value ? format(value, 'yyyy') : undefined)}
    {...props}
  />
);

FormField.propTypes = {
  name: PropTypes.string
};

export default YearPicker;

export {FormField};
