import React from 'react';
import Box from '@material-ui/core/Box';
import RiskFactorGradient from 'components/RiskFactorGradient';

const RiskFactorsSummary = () => {
  const data = JSON.parse(sessionStorage.getItem('riskFactorsSummary'));

  return (
    <Box mb={4}>
      {data && (
      <Box mt={2}>
        <RiskFactorGradient title="riskFactors.obesity" value={data.obesity} type="bmi" />
        <RiskFactorGradient title="pageHeader.alcohol" value={data.alcohol} type="alcohol" />
        <RiskFactorGradient title="pageHeader.smoking" value={data.smoking} type="linear" />
        <RiskFactorGradient title="riskFactors.movement" value={data.movement} type="linear" />
        <RiskFactorGradient title="pageHeader.nutrition" value={data.nutrition} type="linear" />
        <RiskFactorGradient title="riskFactors.sleep" value={data.sleep} type="sleep" />
        <RiskFactorGradient title="riskFactors.glucose" value={data.glucose} type="glucose" />
        <RiskFactorGradient title="riskFactors.lipids" value={data.lipids} type="lipids" />
        <RiskFactorGradient title="riskFactors.bloodPressure" value={data.bloodPressure} type="bloodPressure" />
        <RiskFactorGradient title="riskFactors.ironMetabolism" value={data.ironMetabolism} type="ironMetabolism" />
      </Box>
      )}
    </Box>
  );
};

export default RiskFactorsSummary;
