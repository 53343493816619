import React from 'react';
import {Divider, Grid} from '@material-ui/core';
import {FormField as TextField} from 'components/form/TextField';
import {FormField as Select} from 'components/form/Select';
import {FormField as NumberField} from 'components/form/NumberField';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import HorizontalFormItem from 'layouts/FormItem/Horizontal';
import {useFormState} from 'react-final-form';
import {numericality} from 'redux-form-validators';
import {yesNoRadio, smokeProducts} from 'services/constants';
import StepHeader from 'layouts/StepHeader';
import {useTranslation} from 'react-i18next';
import useStyles from '../styles';

const Smoking = () => {
  const classes = useStyles();
  const {values} = useFormState();
  const {t} = useTranslation();
  return (
    <>
      <StepHeader title="pageHeader.smoking" />
      <Grid container spacing={2} className={classes.stepWrapper}>
        <HorizontalFormItem label="pageOneQuestions.smoking.smkNow" longLabel>
          <RadioButtonGroupField
            name="smkNow"
            items={yesNoRadio}
            type="radio"
          />
        </HorizontalFormItem>
        <HorizontalFormItem label="pageOneQuestions.smoking.smkEver" longLabel>
          <RadioButtonGroupField
            name="smkEver"
            items={yesNoRadio}
            type="radio"
            disabled={values.smkNow === '1'}
          />
        </HorizontalFormItem>
        {(values.smkNow === '1' || values.smkEver === '1') && (
          <HorizontalFormItem label="pageOneQuestions.smoking.smkStartYear" longLabel>
            <NumberField
              name="smkStartYear"
              variant="outlined"
              size="medium"
              parseToNumber
              decimalScale={0}
              className={classes.numField}
              validate={numericality({allowBlank: true, '>': '1900', '<=': new Date().getFullYear()})}
            />
          </HorizontalFormItem>
        )}
        {values.smkEver === '1' && (
        <HorizontalFormItem label="pageOneQuestions.smoking.smkQuitYear" longLabel>
          <NumberField
            name="smkQuitYear"
            variant="outlined"
            size="medium"
            parseToNumber
            decimalScale={0}
            className={classes.numField}
            validate={numericality({allowBlank: true, '>': '1900', '<=': new Date().getFullYear()})}
          />
        </HorizontalFormItem>
        )}
        {(values.smkNow === '1' || values.smkEver === '1') && (
        <HorizontalFormItem
          label={values.smkNow === '1' ?
            t('pageOneQuestions.smoking.smokePresentTime') :
            t('pageOneQuestions.smoking.smokePastTime')}
          longLabel
          translatedLabel
        >
          <Select
            name="smkPrd"
            options={smokeProducts}
            variant="outlined"
            isSearchable
            isClearable
          />
        </HorizontalFormItem>
        )}
        {(values.smkNow === '1' || values.smkEver === '1') && (values.smkPrd != null) && (
        <HorizontalFormItem
          label={values.smkNow === '1' ?
            t('pageOneQuestions.smoking.smkNumPresentTime', {name: values.smkPrd.label}) :
            t('pageOneQuestions.smoking.smkNumPastTime', {name: values.smkPrd.label})}
          longLabel
        >
          <TextField
            name="smkNum"
            variant="outlined"
            className={classes.numField}
            validate={numericality({allowBlank: true, '>': 0})}
          />
        </HorizontalFormItem>
        )}
      </Grid>
      <Divider />
    </>
  );
};

export default Smoking;
