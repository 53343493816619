import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(() => ({

  pageLogo: {
    maxWidth: '200px'
  },
  reportOl: {
    paddingLeft: '18px'
  }
}));
