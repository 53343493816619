import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import * as d3 from 'd3';

const STROKE_WIDTH = 'stroke-width';

export const getColorArray = () => {
  const colorArr = [];
  for (let i = 0; i < 5; i += 1) {
    colorArr.push(`rgb(${i * 63.75}, 255, 0)`);
  }
  for (let i = 12; i >= 0; i -= 1) {
    colorArr.push(`rgb(255, ${i * 19.6153846}, 0)`);
  }
  return colorArr;
};

function switchColor(value) {
  const colorArray = getColorArray();
  if (value < 0 || value >= 1) {
    return colorArray[colorArray.length - 1];
  }
  for (let i = 0; i < colorArray.length; i += 1) {
    if (value >= i * 0.056 && value < (i + 1) * 0.056) {
      return colorArray[i];
    }
  }
}

const DeseaseRisksGraph = ({engineMin, engineMax, ciMin, ciMax, measured, refVal, initialMeasured}) => {
  const d3Container = useRef(null);
  const checkMinMax = (value, min, max) => {
    if (value && value < min) {
      return min;
    }
    if (value && value > max) {
      return max;
    }
    return value;
  };
  const health = g => {
    const totalWidth = 200;
    const skipStartPixel = 0;
    const line = d3.line()([
      [skipStartPixel, 0],
      [totalWidth + skipStartPixel, 0]
    ]);
    const minPixel = skipStartPixel;
    const maxPixel = skipStartPixel + totalWidth;
    const scaleInPixel = totalWidth / (engineMax - engineMin);

    const ciMinPixel = checkMinMax(skipStartPixel + (ciMin - engineMin) * scaleInPixel, minPixel, maxPixel);
    const ciMaxPixel = checkMinMax(skipStartPixel + (ciMax - engineMin) * scaleInPixel, minPixel, maxPixel);

    let measuredPixel = skipStartPixel + (measured - engineMin) * scaleInPixel;
    let refPixel = skipStartPixel + (refVal - engineMin) * scaleInPixel;
    let initialMeasuredPixel = skipStartPixel + (initialMeasured - engineMin) * scaleInPixel;

    if (measuredPixel < minPixel || measuredPixel > maxPixel) {
      measuredPixel = -10;
      initialMeasuredPixel = -10;
      refPixel = -10;
    }
    const measuredLine = d3.line()([[initialMeasuredPixel, 3], [initialMeasuredPixel, -3]]);

    const triangle = d3.symbol()
      .type(d3.symbolTriangle)
      .size(5);

    g.append('path')
      .attr('d', line)
      .attr('stroke', '#ADC6CD')
      .attr(STROKE_WIDTH, 0.5);

    g.append('rect')
      .attr('height', 5)
      .attr('width', ciMaxPixel > ciMinPixel ? ciMaxPixel - ciMinPixel : ciMinPixel - ciMaxPixel)
      .attr('x', ciMaxPixel > ciMinPixel ? ciMinPixel : ciMaxPixel)
      .attr('y', -2.5)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('fill', '#ADC6CD')
      .attr('fill-opacity', 0.6);

    if (refVal) {
      g.append('path')
        .attr('d', triangle)
        .attr('fill', '#36BADB')
        .attr('transform', () => `translate(${refPixel} , 2)`);
    }

    if (initialMeasured) {
      g.append('path')
        .attr('d', measuredLine)
        .attr('stroke', 'grey')
        .attr('fill', 'none')
        .attr(STROKE_WIDTH, 0.5);
    }

    if (measured) {
      g.append('circle')
        .attr('cx', measuredPixel)
        .attr('cy', 0)
        .attr('r', 0.75)
        .attr('stroke', switchColor(measured))
        .attr('fill', 'none')
        .attr(STROKE_WIDTH, 1.5);
    }
  };

  useEffect(
    () => {
      if (d3Container.current) {
        const svg = d3.select(d3Container.current)
          .attr('viewBox', '0 -3 200 5')
          .style('width', '100%')
          .style('height', 'auto')
          .style('font', '10px sans-serif')
          .style('overflow', 'visible');

        svg.selectAll('*').remove();

        svg.append('g')
          .call(health);
      }
    }
  );
  return (
    <svg
      className="d3-component"
      ref={d3Container}
    />
  );
};
DeseaseRisksGraph.propTypes = {
  engineMin: PropTypes.number,
  engineMax: PropTypes.number,
  ciMin: PropTypes.number,
  ciMax: PropTypes.number,
  measured: PropTypes.number,
  initialMeasured: PropTypes.number,
  refVal: PropTypes.number
};

DeseaseRisksGraph.defaultProps = {
  ciMin: 0.2,
  ciMax: 0.25,
  measured: 0,
  initialMeasured: 0
};

export default DeseaseRisksGraph;
