import React from 'react';
import {Divider, Grid} from '@material-ui/core';
import {FormField as TextField} from 'components/form/TextField';
import {FormField as Select} from 'components/form/Select';
import {FormField as NumberField} from 'components/form/NumberField';
import HorizontalFormItem from 'layouts/FormItem/Horizontal';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import composeValidators from 'helpers/composeValidators';
import {combine, numericality, required} from 'redux-form-validators';
import {genderFieldConfig, heightUnits, weightUnits, months} from 'services/constants';
import StepHeader from 'layouts/StepHeader';
import useStyles from '../styles';

const defaultHeightUnit = heightUnits[0];
const defaultWeightUnit = weightUnits[0];

const maxDate = new Date().getFullYear() - 18;

const BasicData = () => {
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.basicData" />
      <Grid container spacing={2} className={classes.stepWrapper}>
        <HorizontalFormItem label="basicData.gender" isRequired>
          <RadioButtonGroupField
            name="gender"
            items={genderFieldConfig}
            type="radio"
            required
            validate={required()}
          />
        </HorizontalFormItem>
        <HorizontalFormItem label="basicData.birthYear" isRequired>
          <NumberField
            name="birthYear"
            variant="outlined"
            size="medium"
            parseToNumber
            decimalScale={0}
            className={classes.numField}
            validate={combine(required(), numericality({'<': maxDate}))}
          />
        </HorizontalFormItem>
        <HorizontalFormItem label="basicData.birthMonth" isRequired>
          <Select
            name="birthMonth"
            options={months}
            isSearchable
            isClearable
            validate={required()}
          />
        </HorizontalFormItem>
        <HorizontalFormItem label="basicData.height" isRequired>
          <Grid container className={classes.unitWrapper}>
            <Grid item>
              <TextField
                name="height"
                variant="outlined"
                className={classes.numField}
                required
                validate={composeValidators(required(), numericality({'>=': 40, '<=': 400}))}
              />
            </Grid>
            <Grid item>
              <Select
                name="heightUnit"
                shortSelect
                options={heightUnits}
                defaultValue={defaultHeightUnit}
              />
            </Grid>
          </Grid>
        </HorizontalFormItem>
        <HorizontalFormItem label="basicData.weight" isRequired>
          <Grid container className={classes.unitWrapper}>
            <Grid item>
              <TextField
                name="weight"
                variant="outlined"
                className={classes.numField}
                required
                validate={composeValidators(required(), numericality({'>=': 25, '<=': 400}))}
              />
            </Grid>
            <Grid item>
              <Select
                name="weightUnit"
                shortSelect
                options={weightUnits}
                defaultValue={defaultWeightUnit}
              />
            </Grid>
          </Grid>
        </HorizontalFormItem>
        <HorizontalFormItem label="basicData.addInfo">
          <TextField
            name="addInfo"
            variant="outlined"
            multiline
            rows={6}
          />
        </HorizontalFormItem>
      </Grid>
      <Divider />
    </>
  );
};

export default BasicData;
