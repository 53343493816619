import React from 'react';
import PropTypes from 'prop-types';
import Wizard from 'components/Wizard';
import BasicData from './Steps/BasicData';
import Smoking from './Steps/Smoking';
import Alcohol from './Steps/Alcohol';
import CoffeAndTea from './Steps/СoffeAndTea';
import Exercises from './Steps/Exercises';
import ExistingConditions from './Steps/ExistingConditions';
import Family from './Steps/Family';
import Sleep from './Steps/Sleep';
import Psychometric from './Steps/Psychometric';
import Nutrition from './Steps/Nutrition';
import Nutrition2 from './Steps/Nutrition2';
import calculator from './formDecorator';

const Form = ({initialValues, onSubmit}) => (
  <Wizard
    onSubmit={onSubmit}
    initialValues={initialValues}
    calculator={calculator}
  >
    <Wizard.Page>
      <BasicData />
    </Wizard.Page>
    <Wizard.Page>
      <Smoking />
    </Wizard.Page>
    <Wizard.Page>
      <Alcohol />
    </Wizard.Page>
    <Wizard.Page>
      <CoffeAndTea />
    </Wizard.Page>
    <Wizard.Page>
      <Exercises />
    </Wizard.Page>
    <Wizard.Page>
      <ExistingConditions />
    </Wizard.Page>
    <Wizard.Page>
      <Family />
    </Wizard.Page>
    <Wizard.Page>
      <Sleep />
    </Wizard.Page>
    <Wizard.Page>
      <Psychometric />
    </Wizard.Page>
    <Wizard.Page>
      <Nutrition />
    </Wizard.Page>
    <Wizard.Page>
      <Nutrition2 />
    </Wizard.Page>
  </Wizard>
);

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func
};

export default Form;
