import {makeStyles} from '@material-ui/core/styles';

import {
  pageHeaderHeight,
  pageFooterHeight
} from 'theme/variables';

export default makeStyles((theme) => ({

  pageContent: {
    minHeight: `calc(100vh - ${pageFooterHeight}px)`,
    paddingTop: pageHeaderHeight,
  },
  numField: {
    width: '100px'
  },
  filterWrapper: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  graphWrapper: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  chart: {
    position: 'sticky',
    top: '20px'
  }
}));
