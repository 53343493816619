import React from 'react';
import {Box, Divider, Grid} from '@material-ui/core';
import {FormField as Select} from 'components/form/Select';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import {yesNoNARadio, parents} from 'services/constants';
import HorizontalFormItem from 'layouts/FormItem/Horizontal';
import {useFormState} from 'react-final-form';
import StepHeader from 'layouts/StepHeader';
import useStyles from './styles';

const MorbidityFamily = () => {
  const classes = useStyles();
  const {values} = useFormState();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Box className={classes.blockWrapper}>
          <StepHeader title="pageHeader.existingConditions" />
          <Grid container spacing={2} className={classes.stepWrapper}>
            <HorizontalFormItem isSpaceBetween label="morbidity.diagnosedDiabet">
              <RadioButtonGroupField
                name="diagnosedDiabet"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            {values.diagnosedDiabet === '1' && (
              <HorizontalFormItem isSpaceBetween label="morbidity.highBloodSugar">
                <RadioButtonGroupField
                  name="highBloodSugar"
                  items={yesNoNARadio}
                  type="radio"
                />
              </HorizontalFormItem>
            )}
            <HorizontalFormItem isSpaceBetween label="morbidity.diagnosedHypertension">
              <RadioButtonGroupField
                name="diagnosedHypertension"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            {values.diagnosedHypertension === '1' && (
            <HorizontalFormItem isSpaceBetween label="morbidity.highBloodPressure">
              <RadioButtonGroupField
                name="highBloodPressure"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            )}
            <HorizontalFormItem isSpaceBetween label="morbidity.kidneyDesease">
              <RadioButtonGroupField
                name="kidneyDesease"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            <HorizontalFormItem isSpaceBetween label="morbidity.metabolicSyndrome">
              <RadioButtonGroupField
                name="metabolicSyndrome"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            <HorizontalFormItem isSpaceBetween label="morbidity.cardiovascular">
              <RadioButtonGroupField
                name="cardiovascular"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            <HorizontalFormItem isSpaceBetween label="morbidity.stroke">
              <RadioButtonGroupField
                name="stroke"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            <HorizontalFormItem isSpaceBetween label="morbidity.cancer">
              <RadioButtonGroupField
                name="cancer"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.blockWrapper}>
          <StepHeader title="pageHeader.family" />
          <Grid container spacing={2} className={classes.stepWrapper}>
            <HorizontalFormItem isSpaceBetween label="morbidity.diagnosedDiabet">
              <RadioButtonGroupField
                name="familyDiabetes"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            <HorizontalFormItem isSpaceBetween label="morbidity.diagnosedHypertension">
              <RadioButtonGroupField
                name="familyHipertension"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            {values.familyHipertension === '1' && (
            <HorizontalFormItem withMargin isSpaceBetween label="family.parents">
              <Select
                name="familyHipertensionCount"
                options={parents}
                isSearchable
                isClearable
              />
            </HorizontalFormItem>
            )}
            <HorizontalFormItem isSpaceBetween label="morbidity.cardiovascular">
              <RadioButtonGroupField
                name="familyCardiovascular"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
            <HorizontalFormItem isSpaceBetween label="morbidity.cancer">
              <RadioButtonGroupField
                name="familyCancer"
                items={yesNoNARadio}
                type="radio"
              />
            </HorizontalFormItem>
          </Grid>
        </Box>
      </Grid>

      <Divider />
    </Grid>
  );
};

export default MorbidityFamily;
