import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Grid, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import useStyles from './styles';

const Horizontal = ({label, children, isSpaceBetween, longLabel,
  isRequired, translatedLabel, withMargin, halfSize}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Grid
      container
      item
      xs={12}
      sm={halfSize ? 6 : 12}
      className={classes.controlItem}
      justify={isSpaceBetween ? 'space-between' : 'flex-start'}
    >
      <Typography className={clsx(classes.label, longLabel && classes.longLabel, withMargin && classes.marginLeft)}>
        {translatedLabel ? label : t(label)}
        {isRequired && <span className={classes.required}>*</span>}
      </Typography>
      <Box className={clsx(withMargin && classes.marginRight)}>{children}</Box>
    </Grid>
  );
};

Horizontal.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isSpaceBetween: PropTypes.bool,
  isRequired: PropTypes.bool,
  longLabel: PropTypes.bool,
  translatedLabel: PropTypes.bool,
  withMargin: PropTypes.bool,
  halfSize: PropTypes.bool
};

export default Horizontal;
