import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {Field} from 'react-final-form';
import {Box, Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  fieldRadio: {
    flexShrink: 0,

    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
  },
  radioLabel: {
    marginBottom: '14px',
    fontWeight: 600
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
}));

const RadioButton = ({input, label, meta: {touched, error}, setTouched, setError, ...rest}) => {
  useEffect(() => {
    setTouched(touched);
    setError(error);
  }, [touched, error, setTouched, setError]);
  return (
    <Box>
      <FormControlLabel
        label={label}
        control={<Radio color="primary" {...input} {..._.omit(rest, ['meta'])} />}
      />
    </Box>
  );
};

RadioButton.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  label: PropTypes.string,
  setTouched: PropTypes.func,
  setError: PropTypes.func,
};

const FormField = props => <Field component={RadioButton} {...props} />;

const RadioButtonGroup = ({name, items, ...rest}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [error, setError] = useState(undefined);
  const [touched, setTouched] = useState(false);
  return (
    <Box className={classes.fieldRadio}>
      <FormControl error={touched && !!error}>
        <RadioGroup className={classes.container}>
          {_.map(items, (item, index) => (
            <FormField
              key={index}
              name={name}
              label={t(item.label)}
              value={item.value}
              setError={setError}
              setTouched={setTouched}
              {...rest}
            />
          ))}
        </RadioGroup>
        {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
      </FormControl>
    </Box>
  );
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
};

export default RadioButtonGroup;
