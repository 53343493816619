import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {Field} from 'react-final-form';
import CoreSelect from 'react-select';
import {FormControl, FormHelperText, InputLabel} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import styles, {shortStyles} from './styles';

const Select = ({touched, error, label, showError, shortSelect, options, value, ...rest}) => {
  const {t} = useTranslation();
  const translatedOptions = options.map(option => ({
    value: option.value,
    label: t(option.label)
  }));
  const selectedOption = find(options, option => value.value === option.value);
  const translatedValue = selectedOption ? {
    value: selectedOption.value,
    label: t(selectedOption.label)
  } : null;

  return (
    <FormControl
      error={touched && !!error}
    >
      {!shortSelect && (<InputLabel>{t(label)}</InputLabel>)}
      <CoreSelect
        {...rest}
        touched={touched}
        error={error}
        value={translatedValue}
        options={translatedOptions}
        styles={shortSelect ? shortStyles : styles}
        placeholder={t('select.placeholderText')}
      />
      {showError && touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

Select.defaultProps = {
  showError: true,
  shortSelect: false,
};

Select.propTypes = {
  touched: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  showError: PropTypes.bool,
  shortSelect: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

const EnchSelect = ({input, meta: {touched, error}, label, ...rest}) => (
  <Select
    touched={touched}
    error={error}
    label={label}
    {...input}
    {...rest}
  />
);

EnchSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
      PropTypes.arrayOf(PropTypes.object)
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  label: PropTypes.string
};

const FormField = props => <Field component={EnchSelect} {...props} />;

FormField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Select;

export {FormField};
