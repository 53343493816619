import * as htmlToImage from 'html-to-image';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

async function getDocDefinition(t, needMedicine) {
  const elements = document.getElementById('pageLogo');
  const imgData = await htmlToImage.toPng(elements);
  const contentArray = [
    {
      image: imgData,
      width: 150,
      margin: [0, 0, 0, 10]
    },
    {
      text: t('phase1Report.header'),
      style: 'header'
    },
    {
      text: t('phase1Report.generalRecommendationTitle'),
      style: 'header'
    },
    {
      text: t('phase1Report.generalRecommendationSubTitle'),
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase1Report.point1Title'), counter: 1, style: 'headerOl'},
      ]
    },
    {
      text: t('phase1Report.point1Body'),
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase1Report.point2Title'), counter: 2, style: 'headerOl'},
      ]
    },
    {
      text: t('phase1Report.point2Body'),
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase1Report.point3Title'), counter: 3, style: 'headerOl'},
      ]
    },
    {
      text: t('phase1Report.point3Body'),
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase1Report.point4Title'), counter: 4, style: 'headerOl'},
      ]
    },
    {
      text: t('phase1Report.point4Body'),
      style: 'pOl'
    },
  ];

  if (needMedicine) {
    contentArray.splice(2, 0, [{
      text: t('phase1Report.needMedicineYes1'),
      style: 'p'
    },
    {
      text: t('phase1Report.needMedicineYes2'),
      style: 'p',
      margin: [0, 0, 0, 10]
    }]);
  } else {
    contentArray.splice(2, 0, [{
      text: t('phase1Report.needMedicineNo1'),
      style: 'p'
    },
    {
      text: t('phase1Report.needMedicineNo2'),
      style: 'p',
      margin: [0, 0, 0, 10]
    }]);
  }

  return ({
    content: contentArray,
    styles: {
      header: {
        fontSize: 20,
        bold: true,
        color: '#36BADB',
        margin: [0, 0, 0, 10]
      },
      headerOl: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      p: {
        margin: [0, 0, 0, 5]
      },
      pOl: {
        margin: [0, 0, 0, 10]
      }
    }
  });
}

export async function exportToPdf(t, needMedicine) {
  const doc = await getDocDefinition(t, needMedicine);
  pdfMake.createPdf(doc).open({}, window);
}
