// Variables - Styles that are used on more than one component------------------

// sizes-------------------------------------------------------------------------
const themeSpacing = 8;
const borderRadius = 4;

const pageHeaderHeight = 20;
const pageFooterHeight = 100;
const pageGutterXl = 48;
const pageGutterMd = 32;
const pageGutterSm = 24;

const controlHeightLarge = 56;
const controlHeightMedium = 48;
const controlHeightSmall = 40;
const controlHeightExtraSmall = 32;

const controlClassicGutter = 16;
const controlIndentTop = 12;
const controlIndentBottom = 4;

const fontSizeLarge = 18;
const fontSizeStandard = 16;
const fontSizeMedium = 15;
const fontSizeSmall = 14;
const fontSizeUpStandard = 13;
const fontSizeUpMedium = 12;

// additionat pallete------------------------------------------------------------

// text
const colorTextBase = 'rgb(42, 47, 66)'; // #2a2f42
const colorTextMedium = 'rgba(42, 47, 66, 0.7)';
const colorTextLight = 'rgba(42, 47, 66, 0.5)';
const colorTextExtraLight = 'rgba(42, 47, 66, 0.4)';
const colorTextContrast = '#ffffff';
const colorTextDark = '#181818';
const colorTextActive = '#52a6ff';
const colorLabelLight = '#8e8a99';
const colorLabelUppercase = '#8e8a99';

// primary
const colorPrimary = '#36BADB';
const colorPrimaryDark = '#2687ed';
const colorPrimaryLight = 'rgba(82, 166, 255, 0.5)';
const colorPrimaryExtraLight = 'rgba(82, 166, 255, 0.18)';

// danger
const colorDanger = '#ec5966';
const colorDangerDark = '#c73d49';
const colorDangerMedium = '#d94653';
const colorDangerLight = '#f28a94';
const colorDangerExtraLight = 'rgba(236, 89, 102, 0.15)';

// warning
const colorWarning = '#f49300';
const colorWarningDark = '#e68150';
const colorWarningExtraLight = 'rgba(255, 153, 0, 0.15)';

// success
const colorSuccess = '#0bb992';
const colorSuccessLight = '#7dc9b8';
const colorSuccessExtraLight = 'rgba(11, 185, 146, 0.15)';

// color border
const colorBorder = '#849CA3';
const colorBorderHover = '#e0e0e0';

// color Control
const colorControl = '#fff';
const colorControlDisabled = '#f9f9fa';

// color background
const colorBgPaper = '#ffffff';
const colorBgLight = '#ffffff';
const colorBgMedium = '#f2f3f4';
const colorBgLightActive = 'rgba(82, 166, 255, 0.08)';
const colorBgLightDanger = 'rgba(236, 89, 102, 0.06)';
const colorBgLightWarning = 'rgba(255, 153, 0, 0.05)';
const colorBgLightSuccess = 'rgba(11, 185, 146, 0.05)';
const colorBgDarkLowBrightness = '#61597A';

const colorGraphLineBlue = '#61597A';
const colorGraphLineViolet = '#5E57F6';
const colorGraphBarLightBlue = '#8884d840';

const colorGrayExtraLight = 'rgba(0,0,0,0.1)';
const colorGrayLight = '#d9d9d9';
const colorGray = '#858585';

const colorGold = '#FFD700';
const colorSilver = '#C0C0C0';
const colorBronze = '#cd7f32';

const colorHeader = '#7D969D';
const colorDivider = '#ADC6CD';

export {
  themeSpacing,
  borderRadius,
  pageHeaderHeight,
  pageFooterHeight,
  pageGutterXl,
  pageGutterMd,
  pageGutterSm,
  controlHeightLarge,
  controlHeightMedium,
  controlHeightSmall,
  controlHeightExtraSmall,
  controlClassicGutter,
  controlIndentTop,
  controlIndentBottom,
  fontSizeLarge,
  fontSizeStandard,
  fontSizeMedium,
  fontSizeSmall,
  fontSizeUpStandard,
  fontSizeUpMedium,
  colorTextBase,
  colorTextMedium,
  colorTextLight,
  colorTextExtraLight,
  colorTextContrast,
  colorTextDark,
  colorTextActive,
  colorLabelLight,
  colorLabelUppercase,
  colorPrimary,
  colorPrimaryDark,
  colorPrimaryLight,
  colorPrimaryExtraLight,
  colorDanger,
  colorDangerLight,
  colorDangerMedium,
  colorDangerExtraLight,
  colorDangerDark,
  colorWarning,
  colorWarningDark,
  colorWarningExtraLight,
  colorSuccess,
  colorSuccessLight,
  colorSuccessExtraLight,
  colorBorder,
  colorBorderHover,
  colorControl,
  colorControlDisabled,
  colorBgPaper,
  colorBgLight,
  colorBgMedium,
  colorBgLightActive,
  colorBgLightSuccess,
  colorBgLightDanger,
  colorBgLightWarning,
  colorBgDarkLowBrightness,
  colorGraphLineBlue,
  colorGraphBarLightBlue,
  colorGrayExtraLight,
  colorGray,
  colorGrayLight,
  colorGraphLineViolet,
  colorGold,
  colorBronze,
  colorSilver,
  colorHeader,
  colorDivider
};
