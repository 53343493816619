import get from 'lodash/get';
import createDecorator from 'final-form-calculate';
import {calculateCholesterolToHdl} from 'modules/phasePatientDoctor/utils';

const calculator = createDecorator(
  {
    field: 'lipidTotalCholesterol',
    updates: {
      ratioOfTotalCholesterolToHdl: (lipidTotalCholesterolValue, allValues) => {
        const highDensityLiprotein = get(allValues, 'highDensityLiprotein');
        return calculateCholesterolToHdl({
          lipidTotalCholesterol: lipidTotalCholesterolValue,
          highDensityLiprotein,
        });
      }
    }
  },
  {
    field: 'highDensityLiprotein',
    updates: {
      ratioOfTotalCholesterolToHdl: (highDensityLiproteinValue, allValues) => {
        const lipidTotalCholesterol = get(allValues, 'lipidTotalCholesterol');
        return calculateCholesterolToHdl({
          highDensityLiprotein: highDensityLiproteinValue,
          lipidTotalCholesterol,
        });
      }
    }
  },
);

export default calculator;
