import React, {useCallback, useMemo} from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import Header from 'layouts/Header';
import StartScreen from 'layouts/StartScreen';
import Container from '@material-ui/core/Container';
import {useHistory} from 'react-router';
import {phase1Service} from 'services/phase1.service';
import {heightUnits, weightUnits, months, smokeProducts, parents} from 'services/constants';
import useGetPatientData from 'helpers/hooks/useGetPatientData';
import {warning} from 'helpers/logger';
import {getPackYears, calculateBMI, normalizeRadio, normalizeSlider, INIT, SUBMIT} from 'modules/phasePatientDoctor/utils';
import {initialValues as defaultInitialValues, normalizeSmoking} from 'modules/phase1';
import Form from './Form';
import useStyles from './styles';

const CombinedPhase = () => {
  const classes = useStyles();
  const history = useHistory();
  const data = useGetPatientData();

  const initialValues = useMemo(() => {
    if (data) {
      const packYears = getPackYears(data);
      const heightUnit = find(heightUnits, ['value', get(data, 'heightUnitId')]);
      const weightUnit = find(weightUnits, ['value', get(data, 'weightUnitId')]);
      const birthMonth = find(months, ['value', get(data, 'birthMonth')]);
      return ({
        ...data,
        smkPrd: find(smokeProducts, ['value', get(data, 'smkPrd')]),
        smkNow: data.smkNow ? `${+data.smkNow}` : data.smkNow,
        smkEver: data.smkEver ? `${+data.smkEver}` : data.smkEver,
        heightUnit,
        weightUnit,
        packYears,
        bmi: calculateBMI({height: data.height, weight: data.weight, heightUnit, weightUnit}),
        diagnosedDiabet: normalizeRadio(data.diagnosedDiabet),
        highBloodSugar: normalizeRadio(data.highBloodSugar),
        diagnosedHypertension: normalizeRadio(data.diagnosedHypertension),
        highBloodPressure: normalizeRadio(data.highBloodPressure),
        kidneyDesease: normalizeRadio(data.kidneyDesease),
        metabolicSyndrome: normalizeRadio(data.metabolicSyndrome),
        cardiovascular: normalizeRadio(data.cardiovascular),
        stroke: normalizeRadio(data.stroke),
        cancer: normalizeRadio(data.cancer),
        familyHipertension: normalizeRadio(data.familyHipertension),
        familyCancer: normalizeRadio(data.familyCancer),
        familyCardiovascular: normalizeRadio(data.familyCardiovascular),
        familyDiabetes: normalizeRadio(data.familyDiabetes),
        nutFruit: normalizeSlider(data.nutFruit, INIT),
        nutVegetables: normalizeSlider(data.nutVegetables, INIT),
        nutRedMeat: normalizeSlider(data.nutRedMeat, INIT),
        nutChicken: normalizeSlider(data.nutChicken, INIT),
        nutFish: normalizeSlider(data.nutFish, INIT),
        familyHipertensionCount: find(parents, ['value', get(data, 'familyHipertensionCount')]),
        birthMonth
      });
    }
    return defaultInitialValues;
  }, [data]);

  const handleSubmit = useCallback(({smkPrd, smkNow, smkEver, smkStartYear, smkQuitYear, smkNum,
    weightUnit, heightUnit, familyHipertensionCount,
    nutFruit, nutVegetables, nutRedMeat, nutChicken, nutFish, birthMonth, ...rest}) => {
    const formattedSmoking = normalizeSmoking({smkNow, smkEver, smkStartYear, smkQuitYear, smkPrd, smkNum});
    const formattedData = {
      ...rest,
      ...formattedSmoking,
      weightUnitId: get(weightUnit, 'value'),
      heightUnitId: get(heightUnit, 'value'),
      familyHipertensionCount: get(familyHipertensionCount, 'value'),
      nutFruit: normalizeSlider(nutFruit, SUBMIT),
      nutVegetables: normalizeSlider(nutVegetables, SUBMIT),
      nutRedMeat: normalizeSlider(nutRedMeat, SUBMIT),
      nutChicken: normalizeSlider(nutChicken, SUBMIT),
      nutFish: normalizeSlider(nutFish, SUBMIT),
      birthMonth: get(birthMonth, 'value')
    };
    phase1Service.createPhase1({...formattedData, years: 1})
      .then(res => {
        sessionStorage.setItem('acmData', JSON.stringify(res));
        sessionStorage.setItem('patient', JSON.stringify({...res.entity, patientPhase: true}));
        if (res.resultCode) {
          localStorage.setItem('patientId', res.resultCode);
        }
        history.push('/riskfactorsummary');
      })
      .catch(warning);
  }, [history]);

  return (
    <>
      {!data && <StartScreen />}
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      </Container>
    </>
  );
};

export default CombinedPhase;
