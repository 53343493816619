import {makeStyles} from '@material-ui/core/styles';

import {
  pageHeaderHeight,
  pageFooterHeight
} from 'theme/variables';

export default makeStyles(() => ({

  pageContent: {
    minHeight: `calc(100vh - ${pageFooterHeight}px)`,
    paddingTop: pageHeaderHeight,
  },
}));
