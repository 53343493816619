export function getBaseUrl(): string {
  const applicationUrl = document.head.querySelector('meta[name=application-url]');
  return applicationUrl ? `${applicationUrl.getAttribute('content')}/` : '/';
}

export function makeUrl(url?: string): string {
  const baseUrl = getBaseUrl();
  if (url) {
    return baseUrl + url;
  }
  return baseUrl;
}
