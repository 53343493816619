import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import round from 'lodash/round';
import {Grid, Box, Typography, TextField as Text} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {FormField as NumberField} from 'components/form/NumberField';
import {useTranslation} from 'react-i18next';
import SliderD3 from 'components/SliderD3';

const useStyles = makeStyles({
  numField: {
    width: '100px'
  },
});

const SBP = 'bloodPressureSystolic';
const DBP = 'bloodPressureDiastolic';

function getCIRange(data, measuredName) {
  const leftVal = get(data, 'ciMin');
  const rightVal = get(data, 'ciMax');
  if (isNil(leftVal) || isNil(rightVal)) {
    return '';
  }
  if (measuredName === SBP || measuredName === DBP) {
    return `${round(leftVal)}-${round(rightVal)}`;
  }
  return `${round(leftVal, 1)}-${round(rightVal, 1)}`;
}

function getGuidelineRange(data, preasureGuidelines) {
  if (preasureGuidelines) {
    return `${preasureGuidelines.bpL}-${preasureGuidelines.bpH}`;
  }
  return `${get(data, 'guidelineMin')}-${get(data, 'guidelineMax')}`;
}

const RiskFactor = ({label, measuredName, measuredValue, engineMin, engineMax,
  preasureGuidelines, data, decimalScale, isActualDisbled}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <>
      <Grid item xs={3} sm={4}>
        <Typography variant="h5">{t(label)}</Typography>
      </Grid>
      <Grid item xs={9} sm={8}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box>
              <SliderD3
                engineMin={engineMin}
                engineMax={engineMax}
                guidelineMin={preasureGuidelines ? preasureGuidelines.bpL : get(data, 'guidelineMin')}
                guidelineMax={preasureGuidelines ? preasureGuidelines.bpH : get(data, 'guidelineMax')}
                ciMin={get(data, 'ciMin')}
                ciMax={get(data, 'ciMax')}
                measured={measuredValue}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item container xs={3}>
                <Text
                  variant="outlined"
                  margin="normal"
                  value={getGuidelineRange(data, preasureGuidelines)}
                  disabled
                  className={classes.numField}
                />
              </Grid>
              <Grid item container xs={3}>
                <Text
                  variant="outlined"
                  margin="normal"
                  value={round(get(data, 'value'), decimalScale)}
                  disabled
                  className={classes.numField}
                />
              </Grid>
              <Grid item container xs={3} alignItems="center">
                <Text
                  variant="outlined"
                  margin="normal"
                  value={getCIRange(data, measuredName)}
                  disabled
                  className={classes.numField}
                />
              </Grid>
              <Grid item container xs={3}>
                <NumberField
                  name={measuredName}
                  margin="normal"
                  variant="outlined"
                  size="medium"
                  parseToNumber
                  decimalScale={decimalScale}
                  className={classes.numField}
                  disabled={isActualDisbled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

RiskFactor.propTypes = {

  label: PropTypes.string,
  measuredName: PropTypes.string,
  measuredValue: PropTypes.number,
  engineMin: PropTypes.number,
  engineMax: PropTypes.number,
  data: PropTypes.object,
  decimalScale: PropTypes.number,
  preasureGuidelines: PropTypes.object,
  isActualDisbled: PropTypes.bool
};

RiskFactor.defaultProps = {
  decimalScale: 2,
  isActualDisbled: false
};

export default RiskFactor;
