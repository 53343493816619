import React, {useCallback} from 'react';
import Header from 'layouts/Header';
import Container from '@material-ui/core/Container';
import {useHistory} from 'react-router';
import {warning} from 'helpers/logger';
import {phase2Service} from 'services/phase2Licence.service';
import useGetPatientData, {useGetRiskFactorsData} from 'helpers/hooks/useGetPatientData';
import {useActions} from 'context';
import Form from './Form';
import useStyles from './styles';

const RiskFactors = () => {
  const classes = useStyles();
  const history = useHistory();
  const {setFilters} = useActions();
  const initialValues = useGetPatientData();
  const riskFactorsData = useGetRiskFactorsData();

  const handleSubmit = useCallback((formValues) => {
    const normalizedData = {
      ...formValues,
      years: 1,
    };
    return phase2Service.riskFactors(normalizedData)
      .then((res) => {
        setFilters({key: 'formValues', data: normalizedData});
        sessionStorage.setItem('patient', JSON.stringify(normalizedData));
        sessionStorage.setItem('riskFactors', JSON.stringify(res));
        history.push('/riskfactorsummary');
      })
      .catch(warning);
  }, [history, setFilters]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          riskFactorsData={riskFactorsData}
        />
      </Container>
    </>
  );
};

export default RiskFactors;
