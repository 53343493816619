import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import StepHeader from 'layouts/StepHeader';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import {useFormState} from 'react-final-form';
import {required} from 'redux-form-validators';
import {yesNoRadio2} from 'services/constants';

const ExistingConditions = () => {
  const {values} = useFormState();
  return (
    <>
      <StepHeader title="pageHeader.existingConditions" />
      <PhaseOneLayout title="pageOneQuestions.diagnosedHypertension" withoutDivider>
        <Grid xs={12} sm={6} item>
          <RadioButtonGroupField
            name="diagnosedHypertension"
            items={yesNoRadio2}
            type="radio"
            validate={required()}
          />
        </Grid>
      </PhaseOneLayout>
      {values.diagnosedHypertension && (
      <Fade in={Boolean(values.diagnosedHypertension)} timeout={1500}>
        <div>
          <PhaseOneLayout title="pageOneQuestions.highBloodPressure" withoutDivider>
            <Grid xs={12} sm={6} item>
              <RadioButtonGroupField
                name="highBloodPressure"
                items={yesNoRadio2}
                type="radio"
                validate={required()}
              />
            </Grid>
          </PhaseOneLayout>
        </div>
      </Fade>
      )}
      {values.highBloodPressure && (
      <Fade in={Boolean(values.highBloodPressure)} timeout={1500}>
        <div>
          <PhaseOneLayout title="pageOneQuestions.diagnosedDiabet" withoutDivider>
            <Grid xs={12} sm={6} item>
              <RadioButtonGroupField
                name="diagnosedDiabet"
                items={yesNoRadio2}
                type="radio"
                validate={required()}
              />
            </Grid>
          </PhaseOneLayout>
        </div>
      </Fade>
      )}
      {values.diagnosedDiabet && (
      <Fade in={Boolean(values.diagnosedDiabet)} timeout={1500}>
        <div>
          <PhaseOneLayout title="pageOneQuestions.highBloodSugar" withoutDivider>
            <Grid xs={12} sm={6} item>
              <RadioButtonGroupField
                name="highBloodSugar"
                items={yesNoRadio2}
                type="radio"
                validate={required()}
              />
            </Grid>
          </PhaseOneLayout>
        </div>
      </Fade>
      )}
      <Box mt={4}>
        <Divider />
      </Box>
    </>
  );
};

export default ExistingConditions;
