/* eslint-disable max-lines */
import {normalizedValue} from 'helpers/utils';
import * as htmlToImage from 'html-to-image';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const STRONGLY_INCREASED = 'report.stronglyIncreased';
const SLIGHTLY_INCREASED = 'report.slightlyIncreased';
export const GENERAL_TEXT = 'phase2Report.generalText2';
const LOW = 'report.low';

export const getRiskTextForReport = (value, type) => {
  switch (type) {
    case 'obesity':
      if (value < 17) {
        return 'report.underweight';
      }
      if (value >= 17 && value < 28) {
        return 'report.normal';
      }
      if (value >= 28 && value < 40) {
        return 'report.overweight';
      }
      return 'report.obese';
    case 'alcohol': {
      if (value < 0.3) {
        return 'report.harmless';
      }
      if (value >= 0.3 && value < 0.55) {
        return 'report.slightlyHarmful';
      }
      return 'report.extremelyHarmful';
    }
    case 'smoking': {
      return value < 0.5 ? 'report.harmlessCigarette' : 'report.requiresAction';
    }
    case 'movement': {
      if (value < 0.3) {
        return 'report.insufficient';
      }
      if (value >= 0.33 && value < 0.66) {
        return 'report.sufficient';
      }
      return 'report.good';
    }
    case 'nutrition': {
      if (value < 0.3) {
        return 'report.healthy';
      }
      if (value >= 0.33 && value < 0.66) {
        return 'report.notQuiteHealthy';
      }
      return 'report.unhealthy';
    }
    case 'sleep': {
      return value < 0.9 && value > 0.2 ? 'report.healthy' : 'report.notQuiteHealthy';
    }
    case 'bloodPressure': {
      if (value < 0.05 || value > 0.9) {
        return STRONGLY_INCREASED;
      }
      if ((value >= 0.7 && value <= 0.9) || (value >= 0.05 && value < 0.2)) {
        return SLIGHTLY_INCREASED;
      }
      return LOW;
    }
    case 'lipids': {
      if (value < 0.05 || value > 0.9) {
        return 'report.lipidStronglyIncreased';
      }
      if ((value >= 0.7 && value <= 0.9) || (value >= 0.05 && value < 0.2)) {
        return 'report.lipidSlightlyIncreased';
      }
      return 'report.lipidLow';
    }
    case 'glucose': {
      if (value > 0.9) {
        return STRONGLY_INCREASED;
      }
      if (value <= 0.9 && value > 0.7) {
        return SLIGHTLY_INCREASED;
      }
      return LOW;
    }
    case 'ironMetabolism': {
      return value < 0.6 && value > 0.4 ? LOW : 'report.increased';
    }
    default:
      return '';
  }
};

export const getDiseaseTextForReport = (diseaseData, name) => {
  const value = diseaseData.find(el => el.name === name);
  const newValue = normalizedValue(value.risk);
  if (newValue < 0.3) {
    return LOW;
  }
  if (newValue > 0.7) {
    return STRONGLY_INCREASED;
  }
  return SLIGHTLY_INCREASED;
};

export const getOverallRisk = (value) => {
  if (value > 0.9) {
    return 'overallRisk.good';
  }
  if (value <= 0.9 && value >= 0.7) {
    return 'overallRisk.fair';
  }
  return 'overallRisk.poor';
};

async function getDocDefinition({t, bmi, riskData, diseaseData, overallData}) {
  const logo = document.getElementById('pageLogo');
  const riskFactors = document.getElementById('riskFactorsChart');
  const diseaseRisks = document.getElementById('diseaseRisksChart');
  const overallRisks = document.getElementById('overallRisksChart');
  const logoData = await htmlToImage.toPng(logo);
  const riskFactorsData = await htmlToImage.toPng(riskFactors);
  const diseaseRisksData = await htmlToImage.toPng(diseaseRisks);
  const overallRisksData = await htmlToImage.toPng(overallRisks);
  const contentArray = [
    {
      image: logoData,
      width: 150,
      margin: [0, 0, 0, 10]
    },
    {
      text: t('phase2Report.header'),
      style: 'header'
    },
    {
      text: t('phase2Report.headerText1'),
      style: 'p'
    },
    {
      text: t('phase2Report.headerText2'),
      style: 'pOl'
    },
    {
      text: t('phase2Report.riskFactors'),
      style: 'header'
    },
    {
      image: riskFactorsData,
      fit: [600, 300],
      margin: [0, 0, 0, 20]
    },
    {
      ol: [
        {text: t('phase2Report.overweightTitle'), counter: 1, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.overweightText1'),
      style: 'pOl'
    },
    {
      text: `${t('phase2Report.overweightText2', {bmi})} ${t(getRiskTextForReport(riskData.obesity, 'obesity'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.alcoholTitle'), counter: 2, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.alcoholText1'),
      style: 'pOl'
    },
    {
      text: `${t('phase2Report.alcoholText2')} ${t(getRiskTextForReport(riskData.alcohol, 'alcohol'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.smokingTitle'), counter: 3, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.smokingText1'),
      style: 'pOl'
    },
    {
      text: `${t('phase2Report.smokingText2')} ${t(getRiskTextForReport(riskData.smoking, 'smoking'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.movementTitle'), counter: 4, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.movementText1'),
      style: 'pOl'
    },
    {
      text: `${t('phase2Report.movementText2')} ${t(getRiskTextForReport(riskData.movement, 'movement'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.nutritionTitle'), counter: 5, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.nutritionText1'),
      style: 'pOl'
    },
    {
      text: `${t('phase2Report.nutritionText2')} ${t(getRiskTextForReport(riskData.nutrition, 'nutrition'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.sleepTitle'), counter: 6, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.sleepText1'),
      style: 'pOl'
    },
    {
      text: `${t('phase2Report.sleepText2')} ${t(getRiskTextForReport(riskData.sleep, 'sleep'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.bloodPressureTitle'), counter: 7, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.bloodPressureText1'),
      style: 'pOl'
    },
    {
      text: `${t('phase2Report.bloodPressureText2')} ${t(getRiskTextForReport(riskData.bloodPressure, 'bloodPressure'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.glucoseTitle'), counter: 8, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.glucoseText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getRiskTextForReport(riskData.glucose, 'glucose'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.lipidsTitle'), counter: 9, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.lipidsText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getRiskTextForReport(riskData.lipids, 'lipids'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.ironMetabolismTitle'), counter: 10, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.ironMetabolismText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getRiskTextForReport(riskData.ironMetabolism, 'ironMetabolism'))}`,
      style: 'pOl'
    },
    {
      text: t('phase2Report.diseaseRisks'),
      style: 'header'
    },
    {
      image: diseaseRisksData,
      fit: [500, 300],
      margin: [0, 0, 0, 20]
    },
    {
      ol: [
        {text: t('phase2Report.diabetesMellitus2Title'), counter: 1, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.diabetesMellitus2Text1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.diabetesMellitus2'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.diagnosedHypertensionTitle'), counter: 2, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.diagnosedHypertensionText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'morbidity.diagnosedHypertension'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.kidneyDeseaseTitle'), counter: 3, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.kidneyDeseaseText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'morbidity.kidneyDesease'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.liverTitle'), counter: 4, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.liverText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.liver'))}`,
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'morbidity.kidneyDesease'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.metabolicDysfunctionTitle'), counter: 5, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.metabolicDysfunctionText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.metabolicDysfunction'))}`,
      style: 'pOl'
    },
    {
      ol: [
        {text: t('phase2Report.heartFailureTitle'), counter: 6, style: 'headerOl'},
      ]
    },
    {
      text: t('phase2Report.heartFailureText1'),
      style: 'pOl'
    },
    {
      text: `${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.heartFailure'))}`,
      style: 'pOl'
    },
    {
      text: t('phase2Report.overallRisk'),
      style: 'header'
    },
    {
      image: overallRisksData,
      fit: [500, 300],
      margin: [0, 0, 0, 20]
    },
    {
      text: t('phase2Report.healthSliceTitle'),
      style: 'header'
    },
    {
      text: `${t('phase2Report.healthSliceText')} ${t(getOverallRisk(overallData.healthSlice))}`,
      style: 'pOl'
    },
  ];
  if (overallData.healthSlice > 0.9) {
    contentArray.push(t('phase2Report.healthSliceHigh'));
  }
  if (overallData.healthSlice <= 0.9 && overallData.healthSlice >= 0.7) {
    contentArray.push(t('phase2Report.healthSliceMid'));
  }
  if (overallData.healthSlice < 0.7) {
    contentArray.push(t('phase2Report.healthSliceLow'));
  }
  return ({
    content: contentArray,
    styles: {
      header: {
        fontSize: 20,
        bold: true,
        color: '#36BADB',
        margin: [0, 0, 0, 10]
      },
      headerOl: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      p: {
        margin: [0, 0, 0, 5]
      },
      pOl: {
        margin: [0, 0, 0, 10]
      }
    }
  });
}

export async function exportToPdf({t, bmi, riskData, diseaseData, overallData}) {
  const doc = await getDocDefinition({t, bmi, riskData, diseaseData, overallData});
  pdfMake.createPdf(doc).open({}, window);
}
