import {makeStyles} from '@material-ui/core/styles';

import {
  pageHeaderHeight,
  pageFooterHeight,
} from 'theme/variables';

export default makeStyles((theme) => ({

  pageContent: {
    minHeight: `calc(100vh - ${pageFooterHeight}px)`,
    paddingTop: pageHeaderHeight,
  },
  filterWrapper: {
    order: 2,
    paddingTop: '0!important',
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  tableWrapper: {
    order: 1,
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 100,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  table: {
    position: 'sticky',
    top: '20px'
  },
  gradient: {
    width: '100%',
    height: '30px',
    backgroundImage: 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,255,0,1) 23.5294117%, rgba(255,0,0,1) 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px'
  },
  baselineValue: {
    width: '3px',
    height: '14px',
    backgroundColor: 'grey',
    marginRight: '10px'
  },
  ageGender: {
    border: '6px solid transparent',
    borderBottom: '10px solid #36BADB',
    marginRight: '10px'
  }
}));
