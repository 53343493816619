import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {useTranslation} from 'react-i18next';

const formatter = (value) => `${value}%`;

const Chart = ({chartData}) => {
  const {t} = useTranslation();
  const data = useMemo(() => {
    if (chartData) {
      return chartData.map(el => ({
        time: el.time,
        intervention: Math.round(el.intervention * 1000) / 10,
        noIntervention: Math.round(el.noIntervention * 1000) / 10
      }));
    }
    return [];
  }, [chartData]);
  return (
    <ResponsiveContainer height={450} width="100%">
      <LineChart
        data={data}
        margin={{top: 5, right: 30, left: 20, bottom: 5}}
      >
        <XAxis ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} dataKey="time" label={{value: t('drillDownGraph.chart.years'), position: 'insideBottomRight', offset: -10}} />
        <YAxis unit="%" label={{value: t('drillDownGraph.chart.survival'), angle: -90, position: 'insideBottomLeft', dy: -110}} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={formatter} cursor={{stroke: 'red', strokeWidth: 2}} />
        <Legend />
        <Line strokeWidth={2} type="monotone" dataKey="noIntervention" stroke="blue" name={t('drillDownGraph.chart.noIntervention')} dot={false} activeDot={{r: 8}} />
        <Line type="monotone" dataKey="intervention" stroke="green" name={t('drillDownGraph.chart.intervention')} dot={false} activeDot={{r: 8}} />
      </LineChart>
    </ResponsiveContainer>

  );
};

Chart.propTypes = {
  chartData: PropTypes.array,
};

export default Chart;
