import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepWrapper: {
    padding: theme.spacing(4, 0),
  },
  calculateBtn: {
    padding: '14px 57px',
    lineHeight: 'normal'
  },
  unitWrapper: {
    width: 'auto'
  },
  numField: {
    width: '100px'
  },
  fieldsWrapper: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  avatar: {
    width: 200,
    height: 200
  },
  blockWrapper: {
    border: '1px solid #ADC6CD',
    borderRadius: '5px',
    padding: '16px',
    height: '100%'
  },
  test: {
    marginLeft: '30px'
  }
}));
