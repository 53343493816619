import {makeUrl} from 'helpers/url';
import {baseGet} from 'services/base.service';

const SERVICE_URL = 'api/user';

const getUrl = (): string => makeUrl(SERVICE_URL);

function getCurrentInfo(): Promise<void> {
  return baseGet(getUrl(), {});
}

export const userService = {getCurrentInfo};
