/* eslint-disable max-len */
import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import {Field} from 'react-final-form';
import {Box, ClickAwayListener, Grow, Grid, IconButton, Slider, FormControl,
  FormHelperText, Paper, Popper, Typography, TextField} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import InfoIcon from '@material-ui/icons/Info';
import fruitImage from 'assets/images/fruit.png';
import fishImage from 'assets/images/fish.png';
import redMeatImage from 'assets/images/redmeat.png';
import vegetablesImage from 'assets/images/vegetables.png';
import poultry from 'assets/images/poultry.png';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';

function getPopOverImg(food) {
  switch (food) {
    case 'fish':
      return fishImage;
    case 'fruit':
      return fruitImage;
    case 'redMeat':
      return redMeatImage;
    case 'vegetables':
      return vegetablesImage;
    case 'poultry':
      return poultry;
    default:
      return null;
  }
}

const StyledFormControl = styled(FormControl)`
  .MuiTypography-root {
    font-weight: 600;
  }
  .MuiSlider-markLabel[data-index='0']{
    transform: none;
  }
  .MuiSlider-markLabel[data-index='1'] {
    transform: translateX(-100%);
  }
  .MuiSlider-valueLabel {
    font-size: 1em;
    left: calc(-50% - 1px)
  }
  .MuiSlider-rail {
    height: 5px
  }
  .MuiSlider-track {
    height: 5px
  }
  .MuiSlider-thumb {
    width: 15px;
    height: 15px;
  }
  .MuiSlider-mark {
    height: 0
  }
`;

const useStyles = makeStyles({
  withLabelDisplay: {
    marginTop: '40px'
  },
  popOver: {
    zIndex: 10,
    backgroundColor: '#fff'
  },
  popOverWrapper: {
    maxWidth: '270px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  popOverImg: {
    maxWidth: '220px',
    marginTop: '16px'
  },
  numField: {
    width: '60px',
    flexShrink: 0,
    marginRight: '16px',
  },
  subLabel: {
    flexShrink: 0,
    marginLeft: '16px',
  },
  sliderWrapper: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '8px'
  },
  infoIcon: {
    color: '#B3C5CC'
  }
});

function NumberFormatCustom(props) {
  const {inputRef, onChange, ...other} = props;

  const handleChange = useCallback((values) => {
    onChange({
      target: {
        value: values.value,
      },
    });
  }, [onChange]);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={1}
      onValueChange={handleChange}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const CustomSlider = ({input, label, meta: {touched, error}, withoutTranslation,
  withLabelDisplay, popOverText, popOverImage, withInput, subLabel, min, max, marks, ...rest}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(input.value);
  const anchorRef = React.useRef(null);
  const {t} = useTranslation();
  const translatedMarks = withoutTranslation ? marks : marks.map(el => ({
    value: el.value,
    label: isArray(el.label) ? `${el.label[1]} ${t(el.label[0])}` : t(el.label)
  }));

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, [setOpen]);

  const handleSliderChange = useCallback((event, newValue) => {
    input.onChange(newValue);
    setVal(newValue);
  }, [input, setVal]);

  const handleInputChange = useCallback((event) => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value);
    input.onChange(newValue);
    setVal(newValue);
  }, [input, setVal]);

  // const handleBlur = useCallback(() => {
  //   if (val < min) {
  //     setVal(min);
  //   } else if (val > max) {
  //     setVal(max);
  //   }
  // }, [setVal, val, min, max]);

  return (
    <StyledFormControl error={touched && !!error} fullWidth className={classes.formControl}>
      <Typography gutterBottom>
        {t(label)}
        {popOverText && (
          <IconButton
            ref={anchorRef}
            aria-controls={open ? input.name : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <InfoIcon className={classes.infoIcon} />
          </IconButton>
        )}
      </Typography>
      {popOverText && (
        <Popper className={classes.popOver} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box className={classes.popOverWrapper} id={input.name}>
                    <Typography className={classes.popOverText}>{t(popOverText)}</Typography>
                    <img className={classes.popOverImg} src={getPopOverImg(popOverImage)} alt="" />
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {withInput ? (
        <Grid container spacing={2} className={classes.sliderWrapper}>
          <TextField
            variant="outlined"
            className={classes.numField}
            value={val}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {onChange: handleInputChange}
            }}
          />
          <Slider
            {...input}
            {...rest}
            min={min}
            max={max}
            marks={translatedMarks}
            value={typeof val === 'number' ? val : 0}
            onChange={handleSliderChange}
            className={withLabelDisplay ? classes.withLabelDisplay : ''}
          />
          {subLabel && (<Typography className={classes.subLabel}>{subLabel}</Typography>)}
        </Grid>
      ) : (
        <Slider
          {...input}
          {...rest}
          min={min}
          max={max}
          marks={translatedMarks}
          onChange={(event, value) => input.onChange(value)}
          className={withLabelDisplay ? classes.withLabelDisplay : ''}
        />
      )}

      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </StyledFormControl>
  );
};

CustomSlider.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  label: PropTypes.string,
  withLabelDisplay: PropTypes.bool,
  popOverText: PropTypes.string,
  popOverImage: PropTypes.string,
  withInput: PropTypes.bool,
  subLabel: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.array,
  withoutTranslation: PropTypes.bool
};

const FormField = props => (<Field component={CustomSlider} {...props} />);

FormField.propTypes = {
  name: PropTypes.string.isRequired
};

export default CustomSlider;

export {FormField};
