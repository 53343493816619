import React from 'react';
import {Grid} from '@material-ui/core';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import {yesNoRadio2} from 'services/constants';
import StepHeader from 'layouts/StepHeader';

const Family = () => (
  <>
    <StepHeader title="pageHeader.family" />
    <PhaseOneLayout title="pageOneQuestions.family">
      <Grid xs={12} sm={6} item>
        <RadioButtonGroupField
          name="familyCardiovascular"
          items={yesNoRadio2}
          type="radio"
        />
      </Grid>
    </PhaseOneLayout>
  </>
);

export default Family;
