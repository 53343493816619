import {makeUrl} from 'helpers/url';
import {baseGet, basePut} from 'services/base.service';
import {IRiskFactors} from './dto/IRiskFactors';
import {IPatientData} from './dto/IPatientData';
import {IRiskFactorsSummary} from './dto/IRiskFactorsSummary';
import {IMorbidityRisks} from './dto/IMorbidityRisks';
import {IMortalityRisks} from './dto/IMortalityRisks';
import {IDiseaseRisks} from './dto/IDiseaseRisks';
import {IGraph} from './dto/IGraph';
import {IGraphRequest} from './dto/IGraphRequest';

const SERVICE_URL = 'api/license/phase2';

function findPhase1(code: string): Promise<IPatientData> {
  return baseGet(makeUrl(`${SERVICE_URL}/find_phase1?code=${code}`), {});
}

function imputed(data: IPatientData): Promise<IRiskFactors> {
  return basePut(makeUrl(`${SERVICE_URL}/imputed`), data);
}

function riskFactors(data: IPatientData): Promise<IRiskFactors> {
  return basePut(makeUrl(`${SERVICE_URL}/riskFactors`), data);
}

function riskFactorsSummary(data: IPatientData): Promise<IRiskFactorsSummary> {
  return basePut(makeUrl(`${SERVICE_URL}/riskFactorsSummary`), data);
}

function morbidityRisks(data: IPatientData): Promise<IMorbidityRisks> {
  return basePut(makeUrl(`${SERVICE_URL}/morbidityRisks`), data);
}

function mortalityRisks(data: IPatientData): Promise<IMortalityRisks> {
  return basePut(makeUrl(`${SERVICE_URL}/mortalityRisks`), data);
}

function diseaseRisks(data: IPatientData): Promise<IDiseaseRisks> {
  return basePut(makeUrl(`${SERVICE_URL}/diseaseRisks`), data);
}
function graph(data: IGraph): Promise<IGraphRequest> {
  return basePut(makeUrl(`${SERVICE_URL}/grpah/chf`), data);
}

export const phase2Service = {
  findPhase1,
  imputed,
  riskFactors,
  riskFactorsSummary,
  morbidityRisks,
  mortalityRisks,
  diseaseRisks,
  graph
};
