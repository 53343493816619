import React from 'react';
import {Divider, Grid} from '@material-ui/core';
import {FormField as Slider} from 'components/form/Slider';
import StepHeader from 'layouts/StepHeader';
import {marks} from 'services/constants';
import useStyles from '../styles';

const Psychometric = () => {
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.psychometric" />
      <Grid container spacing={2} className={classes.stepWrapper}>
        <Grid item xs={12} sm={6}>
          <Slider
            label="pageOneQuestions.psychometric"
            name="healthyFeel"
            min={0}
            step={0.1}
            max={1}
            marks={marks.psychometricMarks}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default Psychometric;
