/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'react-final-form';
import {Slider, FormControl, FormHelperText} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {withStyles} from '@material-ui/core/styles';

const CustomSlider = withStyles({
  root: {
    height: 5,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 5,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 5,
  },
  mark: {
    height: 0
  },
  marked: {
    marginBottom: 0
  },
  markLabel: {
    transform: 'translateX(-100%)',
    '&[data-index="0"]': {
      transform: 'translateX(0)'
    }
  }
})(Slider);

function ThumbComponent(props) {
  const value = props['aria-valuenow'];
  return (
    <span {...props}>
      {value}
    </span>
  );
}

const PhaseOneSlider = ({input, meta: {touched, error}, marks, ...rest}) => {
  const {t} = useTranslation();
  const translatedMarks = marks.map(el => ({
    value: el.value,
    label: t(el.label)
  }));
  return (
    <FormControl error={touched && !!error} fullWidth>
      <CustomSlider
        {...input}
        {...rest}
        marks={translatedMarks}
        ThumbComponent={ThumbComponent}
        onChange={(event, value) => input.onChange(value)}
      />
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

PhaseOneSlider.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  marks: PropTypes.array
};

const FormField = props => (<Field component={PhaseOneSlider} {...props} />);

FormField.propTypes = {
  name: PropTypes.string.isRequired
};

export default PhaseOneSlider;

export {FormField};
