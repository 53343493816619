import React, {useCallback, useState} from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import Header from 'layouts/Header';
import StartScreen from 'layouts/StartScreen';
import Container from '@material-ui/core/Container';
import {useHistory} from 'react-router';
import {warning} from 'helpers/logger';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
import {phase1Service} from 'services/phase1.service';
import Form from './Form';
import useStyles from './styles';

export const initialValues = {
  healthyFeel: 0.5,
  gender: 'M',
  smkNow: '0',
  smkEver: '0',
  diagnosedHypertension: null,
  diagnosedDiabet: null,
  highBloodPressure: null,
  highBloodSugar: null,
};

function normalizeSlider(value) {
  return isNil(value) ? null : value / 7;
}

export function normalizeSmoking(values) {
  const {smkNow, smkEver, smkStartYear, smkQuitYear, smkPrd, smkNum} = values;
  if (!(+smkNow) && !(+smkEver)) {
    return {
      smkNow: !!+smkNow,
      smkEver: !!+smkEver,
      smkStartYear: undefined,
      smkQuitYear: undefined,
      smkPrd: undefined,
      smkNum: undefined
    };
  }
  return {
    smkNow: !!+smkNow,
    smkEver: !!+smkEver,
    smkPrd: get(smkPrd, 'value'),
    smkStartYear,
    smkQuitYear,
    smkNum
  };
}

const Phase1 = () => {
  const classes = useStyles();
  const history = useHistory();
  const [pristine, setPristine] = useState(true);
  const handleSubmit = useCallback(({smkPrd, smkStartYear, smkQuitYear, weightUnit, smkNum,
    heightUnit, smkNow, smkEver, nutFruit, nutVegetables, nutRedMeat, nutChicken, nutFish, birthMonth, ...rest}) => {
    const formattedSmoking = normalizeSmoking({smkNow, smkEver, smkStartYear, smkQuitYear, smkPrd, smkNum});
    const formattedData = {
      ...rest,
      ...formattedSmoking,
      weightUnitId: get(weightUnit, 'value'),
      heightUnitId: get(heightUnit, 'value'),
      nutFruit: normalizeSlider(nutFruit),
      nutVegetables: normalizeSlider(nutVegetables),
      nutRedMeat: normalizeSlider(nutRedMeat),
      nutChicken: normalizeSlider(nutChicken),
      nutFish: normalizeSlider(nutFish),
      birthMonth: get(birthMonth, 'value')
    };

    return phase1Service.createPhase1(formattedData)
      .then((res) => {
        sessionStorage.setItem('phase1', JSON.stringify(res));
        localStorage.setItem('patientId', res.resultCode);
        setPristine(true);
        history.push('/phase1Result');
      })
      .catch(warning);
  }, [history]);

  return (
    <>
      <StartScreen />
      <Header />
      <RouteLeavingGuard when={!pristine} />
      <Container className={classes.pageContent} maxWidth="md">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      </Container>
    </>
  );
};

export default Phase1;
