import React from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';

import ConfirmationDialog from 'components/ConfirmationDialog';

const RouteLeavingGuard = ({when, message, ...rest}) => (
  <NavigationPrompt when={when}>
    {({onConfirm, onCancel}) => (
      <ConfirmationDialog
        text={message}
        open
        onClose={onCancel}
        onSubmit={onConfirm}
        {...rest}
      />
    )}
  </NavigationPrompt>
);

RouteLeavingGuard.defaultProps = {
  message: 'When switching to another section of the system, all changes not saved on the current page will be lost. Proceed?',
  cancelButtonText: 'No',
  confirmButtonText: 'Yes',
};

RouteLeavingGuard.propTypes = {
  message: PropTypes.string,
  when: PropTypes.bool.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
};

export default RouteLeavingGuard;
