import React from 'react';
import {Box, Grid} from '@material-ui/core';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import {FormField as Slider} from 'components/form/PhaseOneSlider';
import StepHeader from 'layouts/StepHeader';
import {marks} from 'services/constants';
import vegetables from 'assets/images/vegetablesNew.png';
import fruit from 'assets/images/fruitNew.png';
import useStyles from '../styles';

const Nutrition = () => {
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.nutrition" />
      <PhaseOneLayout title="pageOneQuestions.fruitsVegetables">
        <Grid container spacing={4} item xs={12} sm={6}>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={fruit} alt="Fruit" title="Fruit" className={classes.sliderImage} />
              <Slider
                name="nutFruit"
                min={0}
                step={1}
                max={28}
                marks={marks.fruitMarks}
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={vegetables} alt="Vegetables" title="Vegetables" className={classes.sliderImage} />
              <Slider
                name="nutVegetables"
                min={0}
                step={1}
                max={28}
                marks={marks.vegetableMarks}
              />
            </Box>
          </Grid>
        </Grid>
      </PhaseOneLayout>
    </>
  );
};

export default Nutrition;
