import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(() => ({

  resultBack: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute'
  },

  resultWrapper: {
    textAlign: 'center',
  }

}));
