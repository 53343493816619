import React from 'react';
import get from 'lodash/get';
import {Divider, Grid} from '@material-ui/core';
import {useFormState} from 'react-final-form';
import MetabolicFormItem from 'layouts/MetabolicFormItem/Horizontal';
import StepHeader from 'layouts/StepHeader';
import {getAge, validatePressure, SYSTOLIC, DYASTOLIC} from 'helpers/utils';
import useStyles from './styles';

const MetabolicMetrics = () => {
  const {values} = useFormState();
  const age = getAge(get(values, 'birthYear', 1));
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.metabolicMetrics" />
      <Grid container spacing={2} className={classes.stepWrapper}>
        <MetabolicFormItem
          name="bloodPressureSystolic"
          label="metrics.bloodPressureSystolic"
          validation="100-120"
          validationPressure={validatePressure(get(values, 'gender'), age, SYSTOLIC)}
          decimalScale={0}
        />
        <MetabolicFormItem
          name="bloodPressureDiastolic"
          label="metrics.bloodPressureDiastolic"
          validation="60-80"
          validationPressure={validatePressure(get(values, 'gender'), age, DYASTOLIC)}
          decimalScale={0}
        />
        <MetabolicFormItem
          name="lipidTotalCholesterol"
          label="metrics.lipidTotalCholesterol"
          validation="<5"
        />
        <MetabolicFormItem
          name="highDensityLiprotein"
          label="metrics.highDensityLiprotein"
          validation=">1"
        />
        <MetabolicFormItem
          name="lowDensityLiprotein"
          label="metrics.lowDensityLiprotein"
          validation="<3"
        />
        <MetabolicFormItem
          name="ratioOfTotalCholesterolToHdl"
          label="metrics.ratioOfTotalCholesterolToHdl"
          validation="<5"
          isDisabled
        />
        <MetabolicFormItem
          name="triglycerides"
          label="metrics.triglycerides"
          validation="<2"
        />
        <MetabolicFormItem
          name="fastingBloodGlucose"
          label="metrics.fastingBloodGlucose"
          validation="3.5-5.6"
        />
        <MetabolicFormItem
          name="glycatedHemoglobin"
          label="metrics.glycatedHemoglobin"
          validation="4.4-5.7"
        />
        <MetabolicFormItem
          name="cReactiveProtein"
          label="metrics.cReactiveProtein"
          validation="<5"
        />
        <MetabolicFormItem
          name="alanineAminotransferase"
          label="metrics.alanineAminotransferase"
          validation="<50"
        />
        <MetabolicFormItem
          name="aspartateAminotransferase"
          label="metrics.aspartateAminotransferase"
          validation="<50"
        />
        <MetabolicFormItem
          name="gammaGlutamylTransferase"
          label="metrics.gammaGlutamylTransferase"
          validation="<60"
        />
        <MetabolicFormItem
          name="glomerularFiltrationRate"
          label="metrics.glomerularFiltrationRate"
          validation=">60"
        />
        <MetabolicFormItem
          name="albuminCreatinineUrineRatio"
          label="metrics.albuminCreatinineUrineRatio"
          validation="<1"
        />
      </Grid>
      <Divider />
    </>
  );
};

export default MetabolicMetrics;
