/* eslint-disable max-lines */
const fr = {
  translations: {
    datepicker: {
      placeholderText: 'jj.mm.aaaa'
    },
    table: {
      loading: 'chargement...'
    },
    select: {
      placeholderText: 'Choisir, SVP...',
      smokeProducts: {
        cigarettes: 'Cigarettes',
        cigar: 'Cigare',
        pipe: 'Pipe',
        chewingTobacco: 'tabac à mâcher',
        snuff: 'Sniff',
        eCigs: 'E-cigs',
        other: 'Autre',
      },
      heightUnits: {
        cm: 'cm',
        inch: 'pouce'
      },
      weightUnits: {
        kg: 'kg',
        pound: 'livre'
      },
      parents: {
        one: 'un',
        both: 'les deux'
      }
    },
    radio: {
      gender: {
        male: 'Masculin',
        female: 'Feminin',
      },
      yesNo: {
        no: 'Non',
        yes: 'Oui',
        na: 'NA',
        dontKnow: 'Ne\'sais pas'
      },
    },
    marks: {
      more: 'ou plus',
      less: 'ou moins',
      hour: 'h',
      week: 's',
      day: 'j',
      moreHours: '$t(marks.hour) $t(marks.more)',
      terrible: 'Terrible',
      perfect: 'Parfait',
      seldom: 'Rarement',
      perWeek: 'Par Semaine',
      never: 'Jamais',
      often: 'souvent',
    },
    header: {
      instructions: 'Instructions',
      help: 'Aide',
      logo: 'Logo'
    },
    pageHeader: {
      basicData: 'Données Basiques',
      smoking: 'Fumeur',
      alcohol: 'Alcool',
      coffeAndTea: 'Café et Thé',
      exercises: 'Exercices sportifs',
      existingConditions: 'Etat Actuel',
      medications: 'Médicaments',
      family: 'Famille',
      psychometric: 'Bon  Etat Général',
      nutrition: 'Nutrition',
      search: 'Chercher',
      lifeStyle: 'Style de vie',
      morbidity: 'Morbidité',
      metabolicMetrics: 'Mesures et Résultats de labo',
      riskFactors: 'Facteurs de risque',
      riskFactorSummary: 'Résumé des facteurs de risque',
      precursorRisks: 'Risque de Morbidité',
      mortalityRisks: 'Risque de Mortalité',
      СHD: 'Ð¡HF Survie et choix d’nterventions',
      plusRisks: 'Risques',
      diseaseRisks: 'Risques de maladie',
      sleep: 'Sommeil'
    },
    basicData: {
      name: 'Nom',
      familyName: 'Nom de famille',
      gender: 'Genre',
      birthDate: 'Date de naissance',
      height: 'Taille',
      weight: 'Poids',
      bmi: 'IMC (kg/m2)',
      birthYear: 'Année de naissance',
      birthMonth: 'Mois de naissance',
      addInfo: 'Information complémentaire'
    },
    pageOneQuestions: {
      smoking: {
        smkNow: 'Etes-vous fumeur actuellement?',
        smkEver: 'Avez-vous déjà fumé',
        smkStartYear: 'Quand avez-vous commencé à fumer?',
        smkQuitYear: 'Quand avez-vous cessé?',
        smoke: 'Quel produit {{fois}} utilisez-vous?',
        smkNum: 'Environ combien {{nom}} {{de fois}} fumez-vous par jour?',
        smokePresentTime: 'Quel produit utilisez-vous?',
        smokePastTime: 'Quel produit avez-vous utilisé?',
        smkNumPresentTime: 'Environ combien {{nom}} fumez-vous par jour?',
        smkNumPastTime: 'Environ combien {{nom}} avez-vous fumé par jour?'
      },
      alcohol: 'En moyenne, quelle quantité d’alcool prenez-vous par semaine ?',
      coffeAndTea: 'En moyenne, combien de tasses de café et/ou de thé buvez-vous par jour?',
      exercises: 'En moyenne, combien d’heures par jour pratiquez-vous des exercices physiques?',
      diagnosedHypertension: 'Un médecin vous a t- il déjà dit que vous aviez une tension artérielle élevée (Hypertension artérielle)?',
      diagnosedDiabet: 'Un médecin vous a-t-il déjà dit que vous aviez un taux de sucre élevé (diabète)?',
      highBloodPressure: ' Etes-vous sous traitement anti hypertenseur?',
      highBloodSugar: 'Etes-vous sous traitement pour un taux de sucre élevé?',
      family: 'Un de vos parents a-t-il développé une maladie cardio vasculaire avant l’âge de 60 ans?',
      sleepWeekdays: 'Combien d’heures de sommeil avez-vous par jour ouvré?',
      sleepWeekends: 'Combien d’heures de sommeil avez-vous par jour férié ou le weekend?',
      isRefreshed: 'Vous sentez vous reposé au réveil?',
      psychometric: 'Comment vous sentez-vous, sur la plan santé?',
      fruitsVegetables: 'Combien de fois mangez-vous des fruits et légumes par semaine ?',
      fishMeat: 'Combien de fois par semaine mangez-vous de la viande rouge, du porc ou du poisson?'
    },
    phaseOneResult: {
      Condition: {
        good: 'bon',
        veryGood: 'très bon',
        excellent: 'excellent',
      },
      graph: {
        health: 'Votre santé',
        estimate: 'estimation'
      },
      result: {
        success: 'Félicitations! Votre santé semble {{santé}}!<br /> continuer vos habitudes de vie saine.',
        failure: `Il se peut que vous ayez quelques soucis de santé à surveiller .
          Nous vous suggérons de consulter votre médecin 
          De nos laboratoires affiliés pour un bilan plus poussé,
          Nous aimerons réaliser un examen de sang pour compléter votre dossier médical 
          Pour prendre rendez-vous cliquer sur le lien ci-dessous. `,
        id: 'Votre Id'
      },
      report: 'Avoir le rapport'
    },
    search: {
      error: 'Patient introuvable',
      find: 'Chercher'
    },
    lifeStyle: {
      smokeTime: 'Tabagisme',
      packYears: 'Paquets-années',
      since: 'Depuis',
      to: 'à',
      aDay: 'par jour',
      alcoBeerPerWeek: {
        label: 'Bière',
        popOverText: 'En moyenne, combien de bières prenez-vous par semaine?',
      },
      alcoWinePerWeek: {
        label: 'Vin',
        popOverText: 'En moyenne, quelle quantité de vin prenez-vous par semaine?',
      },
      alcoWhiskeyPerWeek: {
        label: 'Liqueurs',
        popOverText: 'En moyenne, quelle quantité d’alcool prenez-vous par semaine?',
      },
      coffePerDay: {
        label: 'Café',
        popOverText: 'En moyenne, environ combien de tasses de café prenez-vous par jour?',
      },
      teaPerDay: {
        label: 'Thé',
        popOverText: 'En moyenne, environ combien de tasses de thé prenez-vous par jour?',
      },
      exerciseWalkPerWeek: {
        label: 'Marche',
        popOverText: 'En moyenne, environ combien d’heures marchez-vous par semaine?',
      },
      exerciseRunPerWeek: {
        label: 'Course',
        popOverText: 'En moyenne, environ combien d’heures courrez-vous par semaine?',
      },
      exerciseBicyclePerWeek: {
        label: 'Bicyclette',
        popOverText: 'En moyenne, environ combien d’heures par semaine faites-vous du vélo?',
      },
      exerciseSimulatorsPerWeek: {
        label: 'Simulateurs',
        popOverText: 'En moyenne, environ combien d’heures par semaine passez-vous sur simulateurs?',
      },
      exerciseSwimmingPerWeek: {
        label: 'Natation',
        popOverText: 'En moyenne, environ combien d’heures par semaine faites-vous en natation?',
      },
      nutFruit: {
        label: 'Fruit',
        popOverText: 'Environ combien de fois par jour vous mangez un ou deux fruits ou plus?',
      },
      nutVegetables: {
        label: 'Végétaux',
        popOverText: 'Environ combien de fois par jour vous mangez un ou deux végétaux ou plus?',
      },
      nutChicken: {
        label: 'Charcuterie',
        popOverText: 'A Quel rythme à peu près mangez-vous 150 grammes ou plus, de charcuterie par semaine?',
      },
      nutRedMeat: {
        label: 'Viande rouge',
        popOverText: 'A Quel rythme à peu près, mangez-vous 150 grammes ou plus  de viande rouge par semaine?'
      },
      nutFish: {
        label: 'Poisson',
        popOverText: 'A Quel rythme à peu près mangez-vous 150 grammes ou plus de poisson par semaine?'
      },
    },
    morbidity: {
      treated: 'Traité',
      diagnosedDiabet: 'Diabetes',
      diagnosedHypertension: 'Hypertension',
      highBloodSugar: '$t(morbidité.diagnostiquéDiabet) $t(morbidity.treated)',
      highBloodPressure: '$t(morbidité.diagnotiquédHypertension) $t(morbidity.treated)',
      kidneyDesease: 'Maladie rénale',
      metabolicSyndrome: 'Disfonctionnement Métabolique',
      cardiovascular: 'Cardiovasculaire',
      stroke: 'Choc',
      cancer: 'Cancer',
    },
    family: {
      parents: 'Parents'
    },
    metrics: {
      mmHg: 'mmHg',
      mmolL: 'mmol/L',
      UL: 'U/L',
      bloodPressureSystolic: 'Tension artérielle systolique ($t(metrics.mmHg))',
      bloodPressureDiastolic: 'Tension artérielle diastolique ($t(metrics.mmHg))',
      lipidTotalCholesterol: 'Cholesterol total ($t(metrics.mmolL))',
      highDensityLiprotein: 'High-density lipoprotein HDL ($t(metrics.mmolL))',
      lowDensityLiprotein: 'Low-density lipoprotein LDL ($t(metrics.mmolL))',
      ratioOfTotalCholesterolToHdl: 'Ratio total cholesterol sur HDL',
      triglycerides: 'Triglycerides ($t(metrics.mmolL))',
      fastingBloodGlucose: 'Glucose sanguine à jeun ($t(metrics.mmolL))',
      glycatedHemoglobin: 'hémoglobine Glyquée (%)',
      cReactiveProtein: 'C-reactive protein (mg/L)',
      alanineAminotransferase: 'Alanine aminotransferase ($t(metrics.UL))',
      aspartateAminotransferase: 'Aspartate aminotransferase ($t(metrics.UL))',
      gammaGlutamylTransferase: 'Gamma-glutamyl transferase ($t(metrics.UL))',
      glomerularFiltrationRate: 'Filtration Glomerulaire (mL/min/1.73 m^2)',
      albuminCreatinineUrineRatio: 'Ration albumin-creatinine dans les urines (mg/mmol)',
    },
    riskFactors: {
      normalRange: 'Intervalle statistique dans la Population',
      normalValue: 'Valeur espérée',
      expectedRange: 'Intervalle espéré',
      actual: 'Actuel',
      obesity: 'Obesité',
      movement: 'Mouvement',
      sleep: 'Sommeil',
      glucose: 'Glucose',
      lipids: 'Lipides  ',
      bloodPressure: 'Pression sanguine',
      ironMetabolism: 'Metabolisme du fer',
    },
    risks: {
      timeHorizon: 'Horizon de temps{{temps}} Années',
      riskRatio: 'Risque absolu',
      cardiovascular: 'Maladie ardiovasculaire',
      metabolicDysfunction: 'Disfonctionneent Métabolique',
      liver: 'Maladie hépatique',
      dementia: 'Démence',
      atherosclerosis: 'Athérosclerose',
      COPD: 'Maladie Obstructive Chronic pulmonaire',
      OSA: 'Apnée du sommeil Obstructive',
      ACM: 'Toute cause de mortalité',
      CHF: 'Insuffisance cardiaque congestive',
      aMI: 'Infarctus aigue du myocarde',
      ESLD: 'Insuffisance hépatique terminale',
      ESRD: 'Insuffisance rénale terminale',
      diabetesMellitus2: 'Diabetes de type 2',
      heartFailure: 'Insuffisance cardiaque',
      totalCancer: 'Total cancer'
    },
    export: {
      printExport: 'Imprimer/Exporter',
      report: 'rapport',
      patient: '$t(export.imprimerExport) patient $t(export.rapport)',
      physician: '$t(export.imprimerExport) médecin $t(export.rapport)',
    },
    drillDownGraph: {
      chart: {
        years: 'Années',
        survival: 'Espérance de vie probable',
        intervention: 'intervention',
        noIntervention: 'non $t(drillDownGraph.chart.intervention)',
      },
      filtres: {
        data: 'Données',
        intervention: 'Intervention',
        age: 'Age',
        gender: 'Sex',
        weight: '$t(basicData.weight) (kg)',
        nyhaClass: 'Classe NYHA',
        ejectionFraction: 'Fraction d’éjection(%)',
        ischemicEtiology: ' Etiologie Ischemique ',
        sbp: 'TA (mmHG)',
        sodium: 'Sodium (mEq/L)',
        totalCholesterol: 'Cholesterol Total (mmol/L)',
        hemoglobin: 'Hemoglobine (g/dL)',
        lymphocytes: 'Lymphocytes (%)',
        uricACid: ' Acid Uric (mg/dL)',
        diureticDose: 'Dose Diuretique (mg/day)',
        aceInhibitor: 'inhibiteur de l’Ace ',
        bBlocker: 'Î² blocker',
        arb: 'ARB',
        ksparingDiuretic: 'K-sparing diuretic',
        allopurinolUse: 'Utilisation d’Allopurinol',
        statinUse: 'Utilisation de statines',
        biventricularPacemaker: ' pacemaker Biventriculaire ',
        icd: 'ICD',
        biventricularIcd: ' ICD Biventricular ',
      }
    },
    plusRisks: {
      chart: {
        timeHorizon: ' horizon temporaire: 5 ans',
        averageValue: 'Valeur moyenne',
        baseLineValue: 'Valeur basse',
        confidenceInterval: 'intervalle de confiance',
        reset: 'Reinitialiser'
      },
      filter: {
        quitSmoking: 'Cesser de fumer'
      }
    },
    month: {
      january: 'Janvier',
      february: 'Fevrier',
      march: 'Mars',
      april: 'Avril',
      may: 'Mai',
      june: 'Juin',
      july: 'Juillet',
      august: 'Août',
      september: 'Septembre',
      october: 'Octobre',
      november: 'Novembre',
      december: 'Decembre',
    },
    report: {
      underweight: 'Faible poids',
      normal: 'normal',
      overweight: 'surpoids',
      obese: 'obèse',
      harmless: 'inoffensif',
      harmlessCigarette: 'Cigarette inoffensive',
      slightlyHarmful: 'légèrement nocif',
      extremelyHarmful: 'extremement nocif',
      requiresAction: 'Nécessite une action de votre part',
      insufficient: 'insuffisant',
      sufficient: 'suffisant',
      good: 'bon',
      healthy: 'Bonne santé',
      notQuiteHealthy: 'Pas vraiment bonne santé',
      unhealthy: 'mauvaise santé',
      stronglyIncreased: 'Augmentation importante',
      slightlyIncreased: 'Légère augmenté',
      low: 'Bas',
      increased: 'Augmenté',
      lipidStronglyIncreased: 'Augmentation importante',
      lipidSlightlyIncreased: 'Légère augmenté',
      lipidLow: 'bas',
    },
    overallRisk: {
      good: 'Bon',
      fair: 'Acceptable',
      poor: 'Mauvais'
    },
    validator: {
      presence: 'valeur requise',
      tooLong: 'est trop long (le maximum est {compter, nombre} {compter, pluriel, one {character} autres {characters}})',
      wrongLength: 'Est la longueur anormale (devrait être {compter, numbrer} {compter, pluriel, un {character} autres {characters}})',
      invalid: 'est invalide',
      notANumber: 'n’est pas un nombre',
      notAnInteger: 'n’est pas un nombre entier',
      greaterThan: 'doit être supérieur à {compter, nombre}',
      lessThanOrEqualTo: 'doit être inférieur à {compter, nombre}',
      greaterThanOrEqualTo: 'Doit être supérieur ou égal à{compter, nombre}',
      lessThan: 'doit être inférieur à {compter, nombre}'
    },
    combinedPhaseResult: {
      id: 'Votre Id',
      info: 'Merci de noter si vous voulez échanger les informations avec votre soignant.',
      report: 'Avoir le rapport'
    },
    phase1Report: {
      pdfBtn: 'PDF',
      header: 'Phase 1 doc',
      needMedicineYes1: 'Vous pourriez avoir des problèmes de santé qui devraient faire l’objet d’un bilan. Les conseils ci-dessous peuvent vous aider à rendre votre vie plus saine.  Cependant, nous vous recommandons fortement de contacter votre médecin traitant à ce sujet ou de vous rendre dans l’une de nos structures de référence pour une analyse plus détaillée. Nous y ferons des analyses poussées pour avoir un aperçu complet de votre état de santé.',
      needMedicineYes2: 'pour prendre rendez-vous, utilisez le lien ci- dessous: [LIEN VERS LE SITE DE REFERENCE]',
      needMedicineNo1: 'Toutes nos félicitations! Votre santé n’est exposée à aucun risque significatif. Assurez-vous de maintenir un mode de vie sain et envisagez de refaire un bilan de santé dans xx ans. Les conseils ci-dessous peuvent vous aider à rendre votre vie plus saine. Même avec vos bons résultats, un contrôle plus approfondi pourrait être intéressant et nous vous invitons à contacter l’un de nos laboratoires pour une prise de sang.',
      needMedicineNo2: 'pour prendre rendez-vous, utilisez le lien ci- dessous: [LIEN VERS LE SITE DE REFERENCE]',
      generalRecommendationTitle: 'Conseils généraux de santé',
      generalRecommendationSubTitle: 'Voici quatre pratiques essentielles, fondées sur des données probantes pour une bonne santé.',
      point1Title: 'Ne’pas fumer',
      point1Body: 'Le tabagisme est de loin le facteur de risque le plus important. Cependant, que vous arrêtiez ou non, cela dépend de votre propre volonté. » Un certain nombre d’études sur l’espérance de vie de fumeurs de différents groupes d\'âge et de sexe révèlent que les fumeurs perdent entre 5 et 11 ans d’espérance de vie. Le tabagisme est également le principal facteur de risque connu pour affecter le développement des broncho-pneumopathies obstructives (BPCOC) et du cancer du poumon. Une étude récente a révélé que fumer ne serait-ce qu’une cigarette par jour entraîne des conséquences importantes sur la santé, notamment un risque plus élevé de crise cardiaque et d\'accident vasculaire cérébral. Cela justifie de prendre la décision d’arrêter de fumer! Il existe de nombreuses sociétés et programmes différents pour encourager les gens comme vous à arrêter de fumer.',
      point2Title: 'Exercices sportifs réguliers',
      point2Body: 'Il est facile de placer l’exercice et l’activité physique au bas de votre liste de choses à faire, mais être actif pour maintenir son endurance est l’une des choses les plus importantes que vous puissiez faire. Au moins 75 minutes de haute intensité ou 150 minutes d’activité physique d’intensité modérée ou une combinaison des deux réparties en 4 à 6 séances d’entraînement hebdomadaires contribuent à réduire significativement les risques cardiovasculaires. Nous vous recommandons de faire un entraînement de force 2 jours par semaine pour cibler tous les groupes musculaires, mais surtout les muscles centraux. Nous vous recommandons donc un plan d’entraînement sur six jours composés des éléments suivants : force, endurance, coordination/équilibre et flexibilité.',
      point3Title: 'Manger sain et équilibré',
      point3Body: 'Il existe littéralement des tonnes de recommandations sur la façon de manger sainement. Nous vous recommandons fortement de prêter attention à un régime méditerranéen, bien documenté et le mieux prouvé scientifiquement. Les ingrédients les plus importants du régime méditerranéen sont l’huile d’olive, de nombreux légumes frais, des fruits et du poisson. Un verre de vin (rouge) est également autorisé. Si possible, ne mangez pas seul, mais en famille ou entre amis. Manger en commun présente des avantages pour la santé et vous permet de vraiment profiter d’une pause loin de votre quotidien. Évitez le sucre, les produits prêts à l’emploi et les aliments hautement transformés comme la farine blanche. Buvez plus d’eau, surtout lorsque vous transpirez.',
      point4Title: 'Dormez suffisamment',
      point4Body: 'Ces dernières années, l’importance du sommeil pour la santé en général et en particulier pour les performances cognitives humaines normales a été mise en avant. Pendant le sommeil paradoxal (également appelé phase de rêve), tout le contenu de la mémoire est consolidé et un sommeil profond élimine les déchets toxiques et nettoie ainsi le cerveau des déchets quotidiens. Les points les plus importants d’une bonne hygiène du sommeil sont : une chambre sombre, calme et fraîche, évitez la caféine après 16 heures et toute activité stimulante après 21 heures, en particulier pas d’e-mails, de SMS, etc. au lit. À partir de 21 heures environ, assurez-vous de passer tous les écrans en mode nuit. Ne vous couchez pas non plus le ventre plein et évitez de travailler ou même de regarder la télévision dans votre chambre. Et enfin, le lit doit être confortable et l’oreiller doit permettre à la tête et au cou d’être détendus et bien soutenus.'
    },
    phase2Report: {
      header: 'Vos résultats',
      headerText1: 'Merci d’utiliser notre service de bilan numérique pour en savoir plus sur vos risques de santé. Les analyses et déclarations suivantes sont basées sur vos données et mesures liées à la santé ainsi que sur les résultats de laboratoire. L’analyse est basée sur les connaissances collectives de plus de 5 000 professionnels de la santé et sur les résultats d’études cliniques comprenant plus de 400 millions d’années-personnes de données.',
      headerText2: 'Nous vous fournirons vos résultats, les interpréterons et vous donnerons de précieux conseils sur la façon dont vous pouvez améliorer votre état de santé.',
      riskFactors: 'Facteurs de risque',
      overweightTitle: 'Surpoids et obésité',
      overweightText1: 'Le surpoids et l’obésité font partie des principaux facteurs de risque d’un certain nombre de maladies, telles que les maladies cardiovasculaires, les dysfonctionnements hépatiques et le diabète. De plus, l’augmentation du poids corporel peut entraîner une augmentation des problèmes articulaires et vous exposer à des maux de dos. L’insuffisance pondérale peut causer des problèmes de santé, tout comme le surpoids, car des réserves sont importantes en cas de maladies graves.',
      overweightText2: 'Avec un indice de masse corporelle de {{bmi}}, votre poids par rapport à votre taille est :',
      alcoholTitle: 'Usage nocif de l’alcool',
      alcoholText1: 'Au fil des ans, les scientifiques ont documenté qu’au-delà d’une certaine quantité et/ou fréquence, la consommation d’alcool peut entraîner des problèmes de santé durables, même chez les personnes en bonne santé. L’alcool est l’une des nombreuses substances qui peuvent endommager votre foie et une consommation excessive d’alcool perturbe le sommeil et a donc un impact négatif sur votre santé mentale. En outre, le risque d’accident est considérablement accru sous l’influence de l’alcool',
      alcoholText2: 'Selon vos informations, votre consommation d’alcool est:',
      smokingTitle: 'Tabagisme',
      smokingText1: 'Fumer ou surtout inhaler de la fumée de cigarette est le facteur de risque le plus important. Cependant, tout dépend de votre propre volonté d’ arrêter ou non. L’espérance de vie des fumeurs est inférieure d’au moins 10 ans à celle des non-fumeurs en raison de maladies pulmonaires, de maladies cardiovasculaires et surtout de divers types de cancer. Fumer ne serait-ce qu’une cigarette par jour a des conséquences importantes sur la santé. Cela justifie la résolution d’arrêter de fumer!',
      smokingText2: 'Votre comportement est:',
      movementTitle: 'Mode de vie sédentaire',
      movementText1: 'La technologie moderne a changé nos vies et l’a rendue plus confortable et plus pratique en engageant les gens dans des comportements qui dépensent très peu d’énergie. Le temps passé à être sédentaire est un facteur de risque émergent de mauvaise santé. L’activité physique prévient ou retarde principalement le risque de maladies cardiovasculaires, de cancer et de déclin des capacités mentales. Nous vous recommandons d’inclure 75 minutes d’activité physique d’intensité élevée ou 150 minutes d’activité physique d’intensité modérée ou une combinaison des deux réparties en 4 à 6 séances d’entraînement par semaine dans votre liste de choses à faire. Faire de la musculation 2 jours par semaine pour cibler tous les groupes musculaires serait un complément idéal à votre mode de vie saine.',
      movementText2: 'Selon vos informations, votre profil de mouvement est:',
      nutritionTitle: 'Habitudes alimentaires malsaines ',
      nutritionText1: 'Les habitudes alimentaires modernes se traduisent souvent par une consommation d’aliments riches en énergie et sans nutriments, riches en sel, en sucre, en graisses et en conservateurs. Une alimentation saine comprend 2 portions de fruits et 2 portions de légumes par jour, des grains entiers et des aliments protéinés. Envisagez de réduire ou d’éviter le sucre, les produits finis hautement transformés et en particulier les produits de viande transformés.',
      nutritionText2: 'Selon vos informations, vos habitudes alimentaires sont:',
      sleepTitle: 'Manque de sommeil',
      sleepText1: 'Notre compréhension de l’intérêt du sommeil a énormément augmenté ces dernières années. Le sommeil donne à votre corps non seulement le temps de se reposer et de se recharger, mais il est également crucial pour la capacité de votre cerveau à apprendre et à se souvenir, il chasse les sous-produits métaboliques toxiques de votre cerveau et le nettoie pendant que vous dormez. La quantité de sommeil dont une personne a besoin dépend de beaucoup de choses, mais les adultes en bonne santé ont besoin en moyenne de 7 à 8 heures et demie de sommeil par nuit. Si vous êtes une alouette du matin ou un oiseau de nuit, c’est une question de préférence et de chronotype. La meilleure façon de perfectionner vos habitudes de sommeil est d’utiliser votre temps libre naturellement pendant vos vacances, où vous pouvez vous coucher et vous lever librement sans réveil.',
      sleepText2: 'Selon vos informations, vos habitudes de sommeil sont:',
      bloodPressureTitle: 'Hypertension artérielle',
      bloodPressureText1: 'L’hypertension artérielle n’est qu’une des maladies les plus courantes et, tout comme dans le cas du diabète, il existe une prédisposition génétique à celle-ci et elle se développe également progressivement. L’hypertension artérielle est un facteur de risque de maladies cardiovasculaires, en particulier d’accident vasculaire cérébral. Les changements de mode de vie peuvent souvent suffire à normaliser la tension artérielle.',
      bloodPressureText2: 'Selon nos calculs et mesures, votre risque est:',
      glucoseTitle: 'Métabolisme du sucre',
      glucoseText1: 'La régulation du glucose dans le corps est l’une des tâches métaboliques les plus importantes. Un équilibre délicat entre les hormones et les enzymes assure un taux de sucre équilibré en grande partie indépendamment des repas. Les personnes atteintes de diabète de type 2 n’ont pas de mode de transmission clair, bien que de nombreuses personnes touchées aient au moins un membre de leur famille proche, comme un parent ou un frère ou une sœur, atteint de la maladie. Le risque de développer un diabète de type 2 augmente avec le nombre de membres de la famille touchés. Au fil du temps, une glycémie élevée peut diminuer l’élasticité des vaisseaux sanguins et endommager les organes vitaux.',
      generalText2: 'Selon nos calculs et mesures, votre risque est :',
      lipidsTitle: 'Trouble du métabolisme des lipides',
      lipidsText1: 'Comme dans le cas du trouble du métabolisme du sucre, votre profil génétique détermine essentiellement la quantité de cholestérol et de triglycérides dans votre sang. Les deux graisses sont des éléments constitutifs essentiels pour l’organisme, mais si leurs valeurs sont trop élevées, elles sont nocives, en particulier pour les vaisseaux sanguins et peuvent entraîner des maladies cardiaques, des accidents vasculaires cérébraux ou d’autres troubles circulatoires.',
      ironMetabolismTitle: 'Métabolisme du fer',
      ironMetabolismText1: 'Le fer est un élément essentiel à la production de sang, et environ 70% du fer de votre corps se trouve dans les globules rouges appelés hémoglobine. Étant donné que l’absorption du fer dans la partie supérieure de l’intestin est limitée, en cas d’absorption altérée ou de diminution de l’apport causée par un certain nombre de raisons, des conditions médicales connexes à la perte de sang en passant par les choix alimentaires, une carence en fer ou une sidéropénie peut survenir. La détection précoce de la carence en fer est extrêmement importante.',
      diseaseRisks: 'Risques de maladie',
      diabetesMellitus2Title: 'Diabète',
      diabetesMellitus2Text1: 'Le diabète de type 2 est l’une des maladies les plus courantes, souvent causées par des facteurs génétiques. La maladie se développe insidieusement et est généralement liée à une prise de poids. Les diabétiques ont un risque considérablement accru de maladies cardiovasculaires. Des activités sportives régulières et un régime pauvre en glucides visant à réduire le poids sont les éléments clés de la prévention et de la prise en charge du diabète de type 2.',
      diagnosedHypertensionTitle: 'Hypertension',
      diagnosedHypertensionText1: 'L’hypertension artérielle n’est qu’une des maladies les plus courantes et, tout comme dans le cas du diabète, il existe une prédisposition génétique à celle-ci. L’hypertension artérielle se développe progressivement sur de nombreuses années, combinée à une prise de poids. L’hypertension artérielle est un facteur de risque de maladies cardiovasculaires, en particulier d’accident vasculaire cérébral ainsi que de dysfonctionnement rénal. Des changements de mode de vie vers plus d’activités physiques et une alimentation plus saine peuvent souvent suffire à normaliser la tension artérielle.',
      kidneyDeseaseTitle: 'Dysfonctionnement rénal',
      kidneyDeseaseText1: 'Les reins sont essentiels à l’équilibre hydrique et salé du corps ainsi qu’à l’élimination des déchets et de l’excès de liquide du corps. Comme il n’y a généralement pas de symptômes de maladie rénale à un stade précoce, un contrôle occasionnel est crucial pour pouvoir détecter et traiter la maladie le plus tôt possible.',
      liverTitle: 'Dysfonctionnement hépatique',
      liverText1: 'Le foie est notre organe métabolique central et est souvent appelé «l’usine chimique du corps». Les problèmes hépatiques peuvent être causés par divers facteurs qui endommagent le foie, tels que des virus, des substances toxiques, une consommation excessive d’alcool, etc. Au fil du temps, les conditions qui endommagent le foie peuvent entraîner des cicatrices, ce qui peut entraîner une insuffisance hépatique, une maladie potentiellement mortelle. Mais un traitement précoce peut donner au foie le temps de guérir.',
      metabolicDysfunctionTitle: 'Trouble du métabolisme des lipides',
      metabolicDysfunctionText1: 'Des taux élevés de triglycérides et de cholestérol peuvent être héréditaires (génétiques) ou peuvent être causés par d’autres conditions médicales. Les troubles du métabolisme des lipides sont caractérisés par un durcissement des artères, qui se produit lorsque des graisses, du cholestérol et d’autres substances s’accumulent dans les parois des artères. Ces dépôts sont appelés plaques. Au fil du temps, ces plaques peuvent rétrécir ou bloquer complètement les artères et causer des problèmes dans tout le corps. Une détection précise et précoce du trouble du métabolisme des lipides est essentielle pour prendre des décisions rapides sur le traitement afin de le ralentir ou même de l’inverser partiellement.',
      heartFailureTitle: 'Insuffisance cardiaque',
      heartFailureText1: 'Un nombre important de facteurs et de variantes génétiques peuvent déclencher un affaiblissement des muscles cardiaques, ce qui compromet la capacité de pompage du cœur et entraîne ainsi une perte considérable de qualité de vie. Certains facteurs de risque d’insuffisance cardiaque peuvent être contrôlés et même évités à temps.',
      overallRisk: 'Risque global',
      healthSliceTitle: 'État de santé général',
      healthSliceText: 'En général, votre état de santé général peut être considéré comme équivalent à la plupart',
      healthSliceHigh: 'Nous vous félicitons pour votre résultat ! Assurez-vous de maintenir vos bonnes habitudes pour une vie saine. Nous vous recommandons d’envisager de refaire un bilan de santé dans xx ans.',
      healthSliceMid: 'Malgré quelques résultats positifs, nous avons détecté plusieurs résultats négatifs pour la santé. Connaître les risques auxquels vous êtes exposé peut vous aider à trouver des moyens d’éviter les problèmes de santé. Nous vous recommandons de consulter votre médecin sur les résultats de votre examen.',
      healthSliceLow: 'Nous avons découvert que vous courez un risque accru de problèmes de santé. Nous vous recommandons fortement d’envisager de prendre rendez-vous avec votre médecin pour discuter des résultats de votre examen. Avec votre médecin ou votre conseiller médical de confiance, vous pouvez élaborer votre plan de retour à un mode de vie saine.',
    }
  }
};

export default fr;
