/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/unbound-method */

function showSnackbar(errorMessage: string): void{
  const el = document.getElementById('snackbar');
  if (el) {
    el.style.visibility = 'visible';
    el.innerHTML = errorMessage;
    setTimeout(() => { el.style.visibility = 'hidden'; }, 5000);
  }
}

const log = console.log;
const warning = (error: string): void => {
  showSnackbar(error);
  console.warn(error);
};
const error = console.error;

export {
  log,
  warning,
  error,
};
