import React, {useEffect, useMemo, useRef, useCallback} from 'react';
import clsx from 'clsx';
import * as d3 from 'd3';
import {Box, Button, Container, Typography} from '@material-ui/core';
import i18n from 'i18n';
import {useHistory} from 'react-router';
import {useTranslation, Trans} from 'react-i18next';
import useStyles from './styles';

export const getColorArray = () => {
  const colorArr = [];
  for (let i = 0; i < 5; i += 1) {
    colorArr.push(`rgb(${i * 63.75}, 255, 0)`);
  }
  for (let i = 12; i >= 0; i -= 1) {
    colorArr.push(`rgb(255, ${i * 19.6153846}, 0)`);
  }
  return colorArr;
};

function switchColor(value) {
  const colorArray = getColorArray();
  if (value < 0 || value >= 0.34) {
    return colorArray[colorArray.length - 1];
  }
  for (let i = 0; i < colorArray.length; i += 1) {
    if (value >= i * 0.02 && value < (i + 1) * 0.02) {
      return colorArray[i];
    }
  }
}

function switchOffset(value, isSecondRow) {
  switch (value) {
    case 'de': return isSecondRow ? '-24' : '-8';
    case 'ru': return isSecondRow ? '-19' : '-33';
    case 'en': return isSecondRow ? '-22' : '-30';
    default: return '-30';
  }
}

const Phase1Result = () => {
  const classes = useStyles();
  const d3Container = useRef(null);
  const {t} = useTranslation();
  const history = useHistory();
  const data = useMemo(() => {
    const phase1Data = JSON.parse(sessionStorage.getItem('phase1'));
    const slice = phase1Data.healthVal;
    let stateName = t('phaseOneResult.condition.good');
    if (slice >= 0.85 && slice < 0.89) {
      stateName = t('phaseOneResult.condition.veryGood');
    } else if (slice >= 0.89) {
      stateName = t('phaseOneResult.condition.excellent');
    }
    return {
      healthVal: phase1Data.healthVal,
      state: phase1Data.needMedicine,
      absACMmrt: 1 - phase1Data.healthSlice,
      stateName,
      id: phase1Data.resultCode
    };
  }, [t]);

  const legend = l => {
    l.call(g => g
      .append('text')
      .attr('x', switchOffset(i18n.language.slice(0, 2)))
      .attr('y', '-3')
      .attr('font-size', `${i18n.language.startsWith('ru') ? '9px' : '12px'}`)
      .text(`${t('phaseOneResult.graph.health')}`)
      .style('fill', 'gray'));
    l.call(g => g
      .append('text')
      .attr('x', switchOffset(i18n.language.slice(0, 2), true))
      .attr('y', '12')
      .attr('font-size', `${i18n.language.startsWith('ru') ? '9px' : '12px'}`)
      .text(`${t('phaseOneResult.graph.estimate')}`)
      .style('fill', 'gray'));
  };

  const health = g => {
    const startAngleValue = data.healthVal * 5.5 + 3.5;
    const arc = d3
      .arc()
      .innerRadius(40)
      .outerRadius(60)
      .endAngle(3.6); // It's in radian, so Pi = 3.14 = bottom.

    g.append('path')
      .datum({startAngle: 9})
      .style('fill', '#bfbfbf')
      .attr('d', arc);

    const foreground = g
      .append('path')
      .datum({startAngle: 3.6})
      .style('fill', switchColor(data.absACMmrt))
      .style('stroke', 'white')
      .style('stroke-width', '1px')
      .style('opacity', 0.9)
      .attr('d', arc);

    foreground
      .transition()
      .duration(5000)
      .call((transition, newAngle) => {
        transition.attrTween('d', (d) => {
          const interpolate = d3.interpolate(d.startAngle, newAngle);
          // eslint-disable-next-line func-names
          return function (s) {
            // eslint-disable-next-line no-param-reassign
            d.startAngle = interpolate(s);
            return arc(d);
          };
        });
      }, startAngleValue);
  };

  useEffect(
    () => {
      if (d3Container.current) {
        const svg = d3.select(d3Container.current)
          .attr('viewBox', '-150 -60 300 120')
          .style('width', '100%')
          .style('height', 'auto')
          .style('font', '10px sans-serif');

        svg.append('g')
          .call(health);
        svg.append('g')
          .call(legend);
      }
    }
  );

  const handleNext = useCallback(() => {
    history.push('/phase1Report');
  }, [history]);

  return (
    <div className={clsx(classes.resultSuccess, classes.resultBack)}>

      <Container maxWidth="sm">
        <Box className={classes.resultWrapper}>
          <svg
            className="d3-component"
            ref={d3Container}
          />
        </Box>
        <Box>
          <Typography variant="h1" align="center">
            {`${t('phaseOneResult.result.id')}: ${data.id}`}
          </Typography>
        </Box>
        {!data.state ? (
          <Box className={classes.resultWrapper}>
            <Typography variant="body1" className={classes.successP}>
              <Trans i18nKey="phaseOneResult.result.success" values={{health: data.stateName}} />
            </Typography>
          </Box>
        ) : (
          <Box className={classes.resultWrapper}>
            <Typography variant="body1" className={classes.alertP}>
              <Trans i18nKey="phaseOneResult.result.failure" />
            </Typography>
            <Typography variant="body1">
              [LINK]
            </Typography>
            {/* <Link
              className={classes.alertLink}
              target="_blank"
              href="https://www.hirslanden.ch/en/corporate/hirslanden-private-hospital-group/contact/healthline.html"
            >
              <CardMedia
                className={classes.medlogo}
                title="Clinic logo"
                image={medlogo}
              />
            </Link> */}
          </Box>
        )}
        <Box mb={2} mt={2} textAlign="center">
          <Button
            onClick={handleNext}
            color="primary"
            variant="outlined"
          >
            {t('phaseOneResult.report')}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Phase1Result;
