import {makeStyles} from '@material-ui/core/styles';
import {colorDivider} from 'theme/variables';

export default makeStyles(theme => ({
  stepWrapper: {
    padding: theme.spacing(4, 0),
    alignItems: 'center'
  },
  calculateBtn: {
    padding: '14px 57px',
    lineHeight: 'normal'
  },
  unitWrapper: {
    flexBasis: '100%'
  },
  fieldsWrapper: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  avatar: {
    width: 200,
    height: 200
  },
  headerContainer: {
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 3,
    borderBottom: `1px solid ${colorDivider}`,
    padding: '16px 0px',
    margin: '0px 8px',
  },
  normalLegend: {
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      backgroundColor: '#d3e6d1',
      width: '16px',
      height: '16px',
      marginRight: '10px'
    }
  },
  expectedLegend: {
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      backgroundColor: '#b2b2ff',
      width: '16px',
      height: '16px',
      marginRight: '10px'
    }
  },
}));
