import React, {useCallback, useEffect, useState} from 'react';
import Header from 'layouts/Header';
import get from 'lodash/get';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from 'components/IconButton';
import Divider from '@material-ui/core/Divider';
import useGetPatientData, {useGetCurrentUserConfigs} from 'helpers/hooks/useGetPatientData';
import StepHeader from 'layouts/StepHeader';
import {phase2Service} from 'services/phase2Licence.service';
import RiskFactorGradient from 'components/RiskFactorGradient';
import {useHistory} from 'react-router';
import {warning} from 'helpers/logger';
import useStyles from './styles';

const getBackLink = (client, isPatientPhase) => {
  if (client !== 'base') {
    return '/patient';
  }
  return isPatientPhase ? '/phase2' : '/riskfactors';
};

const RiskFactorsSummary = () => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const history = useHistory();
  const patientData = useGetPatientData();
  const userConfig = useGetCurrentUserConfigs();

  const handlePrev = useCallback(() => {
    const client = get(userConfig, 'config.client');
    const link = getBackLink(client, patientData.patientPhase);
    const params = {
      pathname: link,
      state: {toLastPage: patientData.patientPhase}
    };
    history.push(params);
  }, [history, patientData, userConfig]);

  const handleNext = useCallback(() => {
    const link = patientData.patientPhase ? '/diseaseRisks' : '/morbidityrisks';
    history.push(link);
  }, [history, patientData]);

  useEffect(() => {
    phase2Service.riskFactorsSummary(patientData)
      .then((res) => {
        setData(res);
        sessionStorage.setItem('riskFactorsSummary', JSON.stringify(res));
      })
      .catch(warning);
  }, [patientData]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <StepHeader title="pageHeader.riskFactorSummary" />
        {data && (
        <Box mt={2}>
          <RiskFactorGradient title="riskFactors.obesity" value={data.obesity} type="bmi" />
          <RiskFactorGradient title="pageHeader.alcohol" value={data.alcohol} type="alcohol" />
          <RiskFactorGradient title="pageHeader.smoking" value={data.smoking} type="linear" />
          <RiskFactorGradient title="riskFactors.movement" value={data.movement} type="linear" />
          <RiskFactorGradient title="pageHeader.nutrition" value={data.nutrition} type="linear" />
          <RiskFactorGradient title="riskFactors.sleep" value={data.sleep} type="sleep" />
          <RiskFactorGradient title="riskFactors.glucose" value={data.glucose} type="glucose" />
          <RiskFactorGradient title="riskFactors.lipids" value={data.lipids} type="lipids" />
          <RiskFactorGradient title="riskFactors.bloodPressure" value={data.bloodPressure} type="bloodPressure" />
          <RiskFactorGradient title="riskFactors.ironMetabolism" value={data.ironMetabolism} type="ironMetabolism" />
        </Box>
        )}
        <Divider />
        <Box marginTop="30px" marginBottom="30px">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <IconButton color="primary" onClick={handlePrev}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleNext}>
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>

  );
};

export default RiskFactorsSummary;
