/* eslint-disable max-lines */

const tr = {

  translations: {

    datepicker: {

      placeholderText: 'dd.mm.yyyy'

    },

    table: {

      loading: 'Yükleniyor...'

    },

    select: {

      placeholderText: 'Lütfen seç...',

      smokeProducts: {

        cigarettes: 'Sigaralar',

        cigar: 'Puro',

        pipe: 'Boru',

        chewingTobacco: 'Çiğnemelik tütün',

        snuff: 'Enfiye',

        eCigs: 'E-Sigara',

        other: 'Diğer',

      },

      heightUnits: {

        cm: 'Santim',

        inch: 'inç'

      },

      weightUnits: {

        kg: 'Kg',

        pound: 'pound'

      },

      parents: {

        one: 'bir',

        both: 'ikisi birden'

      }

    },

    radio: {

      gender: {

        male: 'Erkek',

        female: 'Kadın',

      },

      yesNo: {

        no: 'Hayır',

        yes: 'Evet',

        na: 'NA',

        dontKnow: 'Bilmiyorum'

      },

    },

    marks: {

      more: 'yada daha fazla',

      less: 'veya daha az',

      hour: 'Saat',

      week: 'Hafta',

      day: 'Gün',

      moreHours: '$t(marks.hour) $t(marks.more)',

      terrible: 'Kötü',

      perfect: 'Mükemmel',

      seldom: 'Nadiren',

      perWeek: 'Haftada',

      never: 'Hiç Bir Zaman',

      often: 'Sıklıkla',

    },

    header: {

      instructions: 'Talimatlar',

      help: 'Yardım',

      logo: 'Logo'

    },

    pageHeader: {

      basicData: 'Basit Data',

      smoking: 'Sigara içmek',

      alcohol: 'Alkol',

      coffeAndTea: 'Kahve ve Çay',

      exercises: 'Egzersiz',

      existingConditions: 'Mevcut Koşullar',

      medications: 'İlaçlar',

      family: 'Aile',

      psychometric: 'Genel Refah',

      nutrition: 'Beslenme',

      search: 'Arama',

      lifeStyle: 'aşam Tarzı',

      morbidity: 'Hastalık',

      metabolicMetrics: 'Ölçümler ve Laboratuar Sonuçları',

      riskFactors: 'Risk Faktörleri',

      riskFactorSummary: 'Risk Faktörü Özeti',

      precursorRisks: 'Risk Faktörü Özeti',

      mortalityRisks: 'Hastalık Riskleri',

      СHD: 'СHF Hayatta Kalma ve Müdahale Seçenekleri',

      plusRisks: 'Riskler',

      diseaseRisks: 'Hastalık Riskleri',

      sleep: 'Uyku'

    },

    basicData: {

      name: 'İsim',

      familyName: 'Soyadı',

      gender: 'Cinsiyet',

      birthDate: 'Doğum tarihi',

      height: 'Boyunuz',

      weight: 'Kilonuz',

      bmi: 'VKİ (kg/m2)',

      birthYear: 'Doğum yılı',

      birthMonth: 'Doğum Ayı',

      addInfo: 'Ek bilgi',

    },

    pageOneQuestions: {

      smoking: {

        smkNow: 'Şu anda sigara içiyor musunuz?',

        smkEver: 'Hiç sigara içtiniz mi?',

        smkStartYear: 'Sigaraya ne zaman başladın?',

        smkQuitYear: 'Ne zaman bıraktın?',

        smoke: 'Hangi ürünü {{time}} kullanıyorsunuz?',

        smkNum: 'Günde yaklaşık kaç {{name}} {{time}} sigara içiyorsun?',

        smokePresentTime: 'Hangi ürünü kullanıyorsunuz?',

        smokePastTime: 'Hangi ürünü kullandınız?',

        smkNumPresentTime: 'Günde yaklaşık kaç {{name}} sigara içiyorsun?',

        smkNumPastTime: 'Günde yaklaşık kaç {{name}} sigara içtiniz?'

      },

      alcohol: 'Haftada ortalama kaç bardak içki içiyorsunuz?',

      coffeAndTea: 'Günde ortalama kaç fincan kahve veya çay içersiniz?',

      exercises: 'Haftada ortalama kaç saat egzersiz yapıyorsun?',

      diagnosedHypertension: 'Doktorunuz size hiç yüksek tansiyonunuz (hipertansiyon) olduğunu söyledi mi?',

      diagnosedDiabet: 'Doktorunuz size hiç kan şekerinizin (diyabet) yüksek olduğunu söyledi mi?',

      highBloodPressure: 'Yüksek tansiyon tedavisi görüyor musunuz?',

      highBloodSugar: 'Yüksek kan şekeri tedavisi görüyor musunuz?',

      family: 'Aılenizden herhangi biri 60 yaşından önce kardiyovasküler hastalık geliştirdi mi?',

      sleepWeekdays: 'Hafta içi kaç saat uyuyorsun?',

      sleepWeekends: 'Hafta sonları veya tatillerde kaç saat uyuyorsunuz?',

      isRefreshed: 'Yenilenmiş olarak uyanıyor musunuz?',

      psychometric: 'Kendinizi ne kadar sağlıklı hissediyorsunuz?',

      fruitsVegetables: 'Haftada yaklaşık ne sıklıkta meyve ve sebze yiyorsunuz?',

      fishMeat: 'Haftada ne sıklıkta et veya beyaz et yiyorsunuz?'

    },

    phaseOneResult: {

      condition: {

        good: 'İyi',

        veryGood: 'Çok İyi',

        excellent: 'Mükemmel',

      },

      graph: {

        health: 'Sağlığınız',

        estimate: 'Tahmin'

      },

      result: {

        success: 'Tebrikler! Sağlığınız  {{health}}!görünüyor!<br /> Sağlıklı alışkanlıklarınızı sürdürün.',

        failure: `İncelemeniz gereken bazı sağlık sorunlarınız olabilir.



          Doktorunuza danışmanızı veya bir doktora gitmenizi öneririz.



          daha derin bir değerlendirme için bağlı laboratuvarlarımızın



          kan testi yaptırmak ve tıbbi geçmişinizi tamamlamak istiyoruz.



          Randevu oluşturmak için aşağıdaki linke tıklayın.`,

        id: 'Kimliğiniz'

      },

      report: 'Rapor Al'

    },

    search: {

      error: 'Hasta Bulunamadi',

      find: 'Arama'

    },

    lifeStyle: {

      smokeTime: 'Sigara İçiliyor',

      packYears: 'Paket-yılı',

      since: 'itibaren',

      to: 'to',

      aDay: 'bir gün',

      alcoBeerPerWeek: {

        label: 'Bira',

        popOverText: 'Haftada ortalama kaç bira içiyorsun?',

      },

      alcoWinePerWeek: {

        label: 'Şarap',

        popOverText: 'Haftada ortalama kaç şarap içersin?',

      },

      alcoWhiskeyPerWeek: {

        label: 'Likör',

        popOverText: 'Haftada ortalama kaç tane alkollü içki içersin?',

      },

      coffePerDay: {

        label: 'Kahve',

        popOverText: 'Günde ortalama kaç fincan kahve içersiniz?',

      },

      teaPerDay: {

        label: 'Çay',

        popOverText: 'Günde ortalama kaç bardak çay içersiniz?',

      },

      exerciseWalkPerWeek: {

        label: 'Yürümek',

        popOverText: 'Haftada ortalama kaç saat yürüyorsunuz?',

      },

      exerciseRunPerWeek: {

        label: 'Koşmak',

        popOverText: 'Haftada ortalama kaç saat koşuyorsun?',

      },

      exerciseBicyclePerWeek: {

        label: 'Bisiklet',

        popOverText: 'Haftada ortalama kaç saat bisiklet sürüyorsunuz?',

      },

      exerciseSimulatorsPerWeek: {

        label: 'Simülatörler',

        popOverText: 'Simülatörleri ortalama olarak haftada kaç saat kullanıyorsunuz?',

      },

      exerciseSwimmingPerWeek: {

        label: 'Yüzme',

        popOverText: 'Haftada ortalama kaç saat yüzersin?',

      },

      nutFruit: {

        label: 'Meyve',

        popOverText: 'Günde ne sıklıkla iki veya daha fazla meyve yersiniz?',

      },

      nutVegetables: {

        label: 'Sebzeler',

        popOverText: 'Günde ne sıklıkla bir veya daha fazla porsiyon sebze yersiniz?',

      },

      nutChicken: {

        label: 'Beyaz Et',

        popOverText: 'Haftada ne sıklıkla 150 gram veya daha fazla beyaz et yersiniz?',

      },

      nutRedMeat: {

        label: 'Kırmızı et',

        popOverText: 'Haftada ne sıklıkla 150 gram veya daha fazla kırmızı et yersiniz?',

      },

      nutFish: {

        label: 'Balık',

        popOverText: 'Haftada ne sıklıkla 150 gram veya daha fazla balık yersiniz?',

      },

    },

    morbidity: {

      treated: 'Tedavi edildi',

      diagnosedDiabet: 'Diyabet',

      diagnosedHypertension: 'Hipertansiyon',

      highBloodSugar: '$t(morbidity.diagnosedDiabet) $t(morbidity.treated)',

      highBloodPressure: '$t(morbidity.diagnosedHypertension) $t(morbidity.treated)',

      kidneyDesease: 'Böbrek hastalığı',

      metabolicSyndrome: 'Metabolik işlev bozukluğu',

      cardiovascular: 'Kardiyovasküler',

      stroke: 'İnme',

      cancer: 'Kanser',

    },

    family: {

      parents: 'Ebeveynler'

    },

    metrics: {

      mmHg: 'mmHg',

      mmolL: 'mmol/L',

      UL: 'U/L',

      bloodPressureSystolic: 'Sistolik kan basıncı  ($t(metrics.mmHg))',

      bloodPressureDiastolic: 'Diastolik kan basıncı  ($t(metrics.mmHg))',

      lipidTotalCholesterol: 'Toplam kolesterol  ($t(metrics.mmolL))',

      highDensityLiprotein: 'Yüksek yoğunluklu  HDL ($t(metrics.mmolL))',

      lowDensityLiprotein: 'Düşük yoğunluklu LDL ($t(metrics.mmolL))',

      ratioOfTotalCholesterolToHdl: 'Toplam kolesterolün oranı HDL',

      triglycerides: 'Trigliseritler ($t(metrics.mmolL))',

      fastingBloodGlucose: 'Açlık kan şekeri ($t(metrics.mmolL))',

      glycatedHemoglobin: 'GGlikatlı hemoglobin (%)',

      cReactiveProtein: 'C-reaktif protein (mg/L)',

      alanineAminotransferase: 'Alanin aminotransferaz  ($t(metrics.UL))',

      aspartateAminotransferase: 'Aspartat aminotransferaz  ($t(metrics.UL))',

      gammaGlutamylTransferase: 'Gama-glutamil transferaz  ($t(metrics.UL))',

      glomerularFiltrationRate: 'Glomerüler filtrasyon hızı  (mL/min/1.73 m^2)',

      albuminCreatinineUrineRatio: 'Albümin-kreatinin idrar oranı (mg/mmol)',

    },

    riskFactors: {

      normalRange: 'Nüfus istatistikleri aralığı',

      normalValue: 'Beklenen değer',

      expectedRange: 'Beklenen aralık',

      actual: 'Hareket',

      obesity: 'obezite',

      movement: 'Movement',

      sleep: 'uyku',

      glucose: 'Glikoz',

      lipids: 'Lipitler  ',

      bloodPressure: 'Kan basıncı',

      ironMetabolism: 'Demir metabolizması',

    },

    risks: {

      timeHorizon: 'Zaman Ufku {{time}} Yıl',

      riskRatio: 'Mutlak riskler',

      cardiovascular: 'Kardiyovasküler hastalık',

      metabolicDysfunction: 'Metabolik disfonksiyon',

      liver: 'Karaciğer hastalığı',

      dementia: 'Bunama',

      atherosclerosis: 'ateroskleroz',

      COPD: 'Kronik obstrüktif akciğer hastalığı',

      OSA: 'tıkayıcı uyku apnesi',

      ACM: 'Tüm nedenlere bağlı ölüm',

      CHF: 'Konjestif kalp yetmezliği',

      aMI: 'Akut miyokard enfarktüsü',

      ESLD: 'Son dönem karaciğer hastalığı',

      ESRD: 'Son dönem böbrek hastalığı',

      diabetesMellitus2: 'Şeker Hastalığı 2',

      heartFailure: 'Kalp yetmezliği',

      totalCancer: 'Toplam kanser'

    },

    export: {

      printExport: 'Yazdır/Dışa Aktar',

      report: 'Rapor',

      patient: '$t(export.printExport) hasta $t(export.report)',

      physician: '$t(export.printExport) doktor $t(export.report)',

    },

    drillDownGraph: {

      chart: {

        years: 'Yıl',

        survival: 'Hayatta kalma olasılığı',

        intervention: 'müdahale',

        noIntervention: 'yok $t(drillDownGraph.chart.intervention)',

      },

      filters: {

        data: 'Veri',

        intervention: 'Müdahale',

        age: 'Müdahale',

        gender: 'Cinsiyet',

        weight: '$t(basicData.weight) (kg)',

        nyhaClass: 'NYHA Sınıfı',

        ejectionFraction: 'Ejeksiyon Kesri (%)',

        ischemicEtiology: 'İskemik Etiyoloji',

        sbp: 'SBP (mmHG)',

        sodium: 'Sodyum (mEq/L)',

        totalCholesterol: 'Toplam Kolesterol  (mmol/L)',

        hemoglobin: 'Hemoglobin (g/dL)',

        lymphocytes: 'Lenfositler (%)',

        uricACid: 'Ürik Asit (mg/dL)',

        diureticDose: 'Diüretik dozu  (mg/day)',

        aceInhibitor: 'As inhibitörü',

        bBlocker: 'β engelleyici',

        arb: 'ARB',

        ksparingDiuretic: 'K-tutucu diüretik',

        allopurinolUse: 'Allopurinol kullanımı',

        statinUse: 'Statin kullanımı',

        biventricularPacemaker: 'Biventriküler kalp pili',

        icd: 'ICD',

        biventricularIcd: 'Biventriküler ICD',

      }

    },

    plusRisks: {

      chart: {

        timeHorizon: 'Zaman ufku: 5 yıl',

        averageValue: 'Ortalama değer',

        baseLineValue: 'BTemel değer',

        confidenceInterval: 'Güven aralığı',

        reset: 'Sıfırla'

      },

      filter: {

        quitSmoking: 'Sigarayı bırakın'

      }

    },

    month: {

      january: 'Ocak',

      february: 'Şubat',

      march: 'Mart',

      april: 'Nisan',

      may: 'Mayıs',

      june: 'Haziran',

      july: 'Temmuz',

      august: 'Ağustos',

      september: 'Eylül',

      october: 'Ekim',

      november: 'Kasım',

      december: 'Aralık',

    },

    report: {

      underweight: 'zayıf',

      normal: 'normal',

      overweight: 'aşırı kilo',

      obese: 'obez',

      harmless: 'zararsız',

      harmlessCigarette: 'zararsız sigara',

      slightlyHarmful: 'biraz Zararlı',

      extremelyHarmful: 'son derece zararlı',

      requiresAction: 'sizin tarafınızdan işlem yapılmasını gerektirir',

      insufficient: 'yetersiz',

      sufficient: 'yeterli',

      good: 'iyi',

      healthy: 'sağlıklı',

      notQuiteHealthy: 'pek sağlıklı değil',

      unhealthy: 'pek sağlıklı değil',

      stronglyIncreased: 'kuvvetle Arttı',

      slightlyIncreased: 'biraz arttı',

      low: 'düşük',

      increased: 'az',

      lipidStronglyIncreased: 'güçlü bir şekilde arttı',

      lipidSlightlyIncreased: 'biraz arttı',

      lipidLow: 'düşük',

    },

    overallRisk: {

      good: 'İyi',

      fair: 'Normal',

      poor: 'Düşük'

    },

    validator: {

      presence: 'değer gereklidir',

      tooLong: 'çok uzun (en fazla {count, number} {count, plural, one {character} başka {characters}})',

      wrongLength: 'uzunluk çok fazla (olmalıdır {count, number} {count, plural, one {character} başka {characters}})',

      invalid: 'geçersiz',

      notANumber: 'geçersiz numara',

      notAnInteger: 'bir tamsayı değil',

      greaterThan: 'değerinden büyük olmalıdır {count, number}',

      lessThanOrEqualTo: ' küçük olmalıdır {count, number}',

      greaterThanOrEqualTo: 'büyük veya eşit olmalıdır {count, number}',

      lessThan: 'değerinden küçük olmalıdır {count, number}'

    },

    combinedPhaseResult: {

      id: 'Kimliğiniz',

      info: 'Sağlık uzmanınızla bilgi paylaşmak istiyorsanız bunu yazın.',

      report: 'Rapor al'

    },

    phase1Report: {
      pdfBtn: 'PDF',
      header: 'Aşama 1 belgesi',
      needMedicineYes1: 'Araştırılması gereken bazı sağlık sorunlarınız olabilir. Aşağıdaki ipuçları hayatınızı daha sağlıklı hale getirmenize yardımcı olabilir. Bununla birlikte, bu konuda birinci basamak doktorunuzla iletişime geçmenizi veya daha ayrıntılı bir analiz için laboratuvarlarımızdan birini ziyaret etmenizi önemle tavsiye ederiz. Sağlık durumunuzun tam bir resmi için bazı kan testleri yapacağız.',
      needMedicineYes2: 'Randevu almak için lütfen bu bağlantıyı link: [LINK TO REFERENCE SITE]',
      needMedicineNo1: 'Tebrikler! Sağlığınız önemli bir riske maruz kalmamıştır. Sağlıklı bir yaşam tarzı sürdürdüğünüzden emin olun ve xx yıl sonra tekrar sağlık kontrolü yapmayı düşünün. Aşağıdaki ipuçları hayatınızı daha sağlıklı hale getirmenize yardımcı olabilir. İyi sonuçlarınız olsa bile, daha geniş bir kontrol faydalı olabilir ve sizi kan testi için laboratuvarlarımızdan biriyle iletişime geçmeye davet ediyoruz.',
      needMedicineNo2: 'Randevu almak için lütfen bunu kullanın link: [LINK TO REFERENCE SITE]',
      generalRecommendationTitle: 'Genel sağlık ipuçları',
      generalRecommendationSubTitle: 'İşte sağlıklı olmak için kanıta dayalı dört pratik anahtarımız.',
      point1Title: 'Sigara içme',
      point1Body: 'Sigara içmek açık ara en önemli risk faktörüdür, ancak bırakıp bırakmamak tamamen kendi iradenizle ilgilidir. Farklı yaş ve cinsiyet gruplarındaki sigara içenler arasında yaşam beklentisini inceleyen bir dizi çalışma, sigara içenlerin 5 ila 11 yıl arasında yaşam süresi kaybettiğini ortaya koymaktadır. Sigara içmek aynı zamanda kronik obstrüktif akciğer hastalığı (KOAH) ve akciğer kanseri gelişimini etkilediği bilinen ana risk faktörüdür. En yeni bir çalışma, günde bir sigara içmenin bile önemli sağlık sonuçları, yani daha yüksek kalp krizi ve felç riski taşıdığını buldu. Bu, sigarayı bırakmaya kararlı olmak demektir! Sizin gibi insanları sigarayı bırakmaya teşvik edecek birçok farklı topluluk ve program mevcuttur.',
      point2Title: 'Düzenli Egzersiz',
      point2Body: 'Yapılacaklar listenizin en altına egzersiz ve aktivite koymak kolaydır, ancak dayanıklılığı korumak için aktif olmak yapabileceğiniz en önemli şeylerden biridir. En az 75 dk. Haftada 4-6 antrenman seansına bölünmüş yüksek yoğunluklu veya 150 dakikalık orta yoğunluklu fiziksel aktivite veya her ikisinin kombinasyonu, kardiyovasküler riskleri önemli ölçüde azaltmaya yardımcı olur. Haftada 2 gün, özellikle genel kasları olmak üzere tüm kas gruplarını hedef alan bir kuvvet antrenmanı yapmanızı öneririz. Bu nedenle size şu bileşenlerden oluşan altı günlük bir egzersiz planı öneriyoruz: güç, dayanıklılık, koordinasyon / denge ve esneklik.',
      point3Title: 'Sağlıklı Beslenme',
      point3Body: 'Nasıl sağlıklı besleneceğine dair kelimenin tam anlamıyla tonlarca tavsiye var. İyi belgelenmiş ve en iyi bilimsel olarak kanıtlanmış bir Akdeniz diyetine dikkat etmenizi şiddetle tavsiye ederiz. Akdeniz diyetinin en önemli malzemeleri zeytinyağı, bol taze sebze, meyve ve balıktır. Bir bardak (kırmızı) şaraba da izin verilir. Mümkünse tek başınıza değil, aileniz veya arkadaşlarınızla birlikte yemek yiyin. Ortak yemek yemenin sağlık açısından faydaları vardır ve günlük yaşamınızdan gerçekten uzaklaşmanın keyfini çıkarmanıza olanak tanır. Şekerden, hazır ürünlerden ve beyaz un gibi çok işlenmiş gıdalardan kaçının. Özellikle terlediğinizde daha fazla su için.',
      point4Title: 'Yeterli Uyku Almak',
      point4Body: 'Son yıllarda, genel olarak sağlık ve özellikle normal insan bilimsel performansı için uykunun önemi ön plana çıkmıştır. REM uykusu sırasında (rüya aşaması da denir), tüm hafıza içeriği pekiştirilir ve derin bir uyku zehirli atıkları dışarı atar ve böylece beyni günlük atıklardan arındırır. İyi bir uyku hijyeninin en önemli noktaları şunlardır: karanlık, sessiz ve serin bir yatak odası, saat 16:00\'dan sonra kafeinden kaçının. ve akşam 9\'dan sonra herhangi bir uyarıcı aktivite, özellikle yatakta e-posta, kısa mesaj vb. yakl. akşam 9. tüm ekranları gece moduna geçirdiğinizden emin olun. Ayrıca tok karnına yatmayın ve yatak odanızda çalışmaktan ve hatta TV izlemekten kaçının. Ve son olarak, yatak rahat olmalı ve yastık baş ve boynun gevşemesine ve iyi desteklenmesine izin vermelidir.',
    },
    phase2Report: {
      header: 'Sonuçların',
      headerText1: 'Sağlık riskleriniz hakkında daha fazla bilgi edinmek için dijital check-up hizmetimizi kullandığınız için teşekkür ederiz. Aşağıdaki analizler ve beyanlar, sağlıkla ilgili verilerinize ve ölçümlerinizin yanı sıra laboratuvar sonuçlarına dayanmaktadır. Analiz, 5.000\'den fazla tıp uzmanının toplu bilgisine ve 400 milyon kişilerden veri içeren klinik araştırmaların sonuçlarına dayanmaktadır.',
      headerText2: 'Sonuçlarınızı size sunacağız, yorumlayacağız ve sağlık durumunuzu nasıl iyileştirebileceğiniz konusunda size değerli ipuçları vereceğiz.',
      riskFactors: 'Risk Faktörleri',
      overweightTitle: 'Fazla Kilo ve Obezite',
      overweightText1: 'Aşırı kilo ve obezite, kardiyovasküler hastalıklar, karaciğer fonksiyon bozukluğu ve diyabet gibi bir dizi hastalık için ana risk faktörlerine aittir. Ek olarak, artan vücut ağırlığı eklem problemlerinin artmasına neden olabilir ve sizi sırt ağrısı geliştirme riskine sokar. Fazla kilolu olmak gibi zayıf olmak da sağlık sorunlarına neden olabilir çünkü ciddi hastalıklarda rezervler önemlidir.',
      overweightText2: 'Vücut kitle indeksi  {{bmi}}, olduğunda, kilonuz boyunuzla orantılıdır:',
      alcoholTitle: 'Alkolün Zararları',
      alcoholText1: 'Belli bir miktarın ve/veya sıklığın üzerinde alkol tüketiminin sağlıklı kişilerde bile kalıcı sağlık sorunlarına yol açabileceğini bilim adamları yıllar içinde belgelemişlerdir. Alkol, karaciğerinize zarar verebilecek birkaç maddeden biridir ve aşırı alkol tüketimi uykuyu bozarak ruh sağlığınızı olumsuz etkiler. Ek olarak, alkolün etkisi altında kaza riski önemli ölçüde artar.',
      alcoholText2: 'According to your information your alcohol consumption is:',
      smokingTitle: 'Sigaranın Zararları',
      smokingText1: 'Sigara içmek veya özellikle sigara dumanını teneffüs etmek en önemli risk faktörüdür ancak bırakıp bırakmamak tamamen kendi iradenizle ilgilidir. Sigara içenlerin yaşam beklentisi, akciğer hastalıkları, kalp damar hastalıkları ve özellikle çeşitli kanser türleri nedeniyle içmeyenlere göre en az 10 yıl daha kısadır. Günde bir sigara bile içmek sağlık açısından önemli sonuçlar doğurur. Bu, sigarayı bırakmaya kararlı olmak demektir!',
      smokingText2: 'Davranışınız:',
      movementTitle: 'Yaşam Tarzı',
      movementText1: 'Modern teknoloji hayatımızı değiştirdi ve insanları çok az enerji harcayan davranışlarda bulunmaya teşvik etmeyi daha rahat ve kullanışlı hale getirdi. Hareketsiz olarak geçirilen zaman, kötü sağlık için ortaya çıkan bir risk faktörüdür. Fiziksel aktivite öncelikle kardiyovasküler hastalıklar, kanser ve zihinsel yeteneklerde azalma riskini önler veya geciktirir. 75 dk. yüksek yoğunluklu veya 150 dakikalık orta yoğunluklu fiziksel aktivite veya her ikisinin kombinasyonu, yapılacaklar listenize haftada 4-6 egzersiz seansına bölünmüştür. Haftada 2 gün tüm kas gruplarını hedef alan bir kuvvet antrenmanı yapmak, sağlıklı yaşam tarzınız için ideal bir tamamlayıcı olacaktır.',
      movementText2: 'Bilgilerinize göre profiliniz:',
      nutritionTitle: 'Sağlıksız Beslenme Alışkanlıkları',
      nutritionText1: 'Modern beslenme alışkanlıkları, genellikle enerjisi yoğun ve besin açısından zengin olmayan, tuz, şeker, yağ ve koruyucu maddeler açısından zengin yiyeceklerin tüketilmesiyle sonuçlanır. Sağlıklı beslenme günde 2 porsiyon meyve ve 2 porsiyon sebzeden oluşur, tam tahıllar ve proteinli yiyecekler. Şekeri, yüksek oranda işlenmiş bitmiş ürünleri ve özellikle işlenmiş et ürünlerini azaltmayı veya bunlardan kaçınmayı düşünün.',
      nutritionText2: 'Verdiğiniz bilgilere göre beslenme alışkanlıklarınız:',
      sleepTitle: 'Uykusuzluk',
      sleepText1: 'Uykunun amacına ilişkin anlayışımız son yıllarda büyük ölçüde arttı. Uyku, vücudunuza sadece dinlenmek ve yeniden şarj olmak için zaman vermekle kalmaz, aynı zamanda beyninizin öğrenme ve hatırlama yeteneği için de çok önemlidir, toksik metabolik yan ürünleri beyninizden dışarı atar ve siz uyurken beyni temizler. Bir kişinin ihtiyaç duyduğu uyku miktarı birçok şeye bağlıdır, ancak sağlıklı yetişkinlerin her gece ortalama 7 ila 8 ½ saat uykuya ihtiyacı vardır. Bir sabah şakası veya gece kuşu olmanız bir tercih meselesi ve kronotipinizdir. Uyku alışkanlıklarınızı mükemmelleştirmenin en iyi yolu, çalar saat olmadan özgürce yatıp kalkabileceğiniz tatilde boş zamanınızı doğal bir şekilde değerlendirmektir.',
      sleepText2: 'Bilgilerinize göre uyku alışkanlıklarınız:',
      bloodPressureTitle: 'Yüksek tansiyon',
      bloodPressureText1: 'Yüksek tansiyon en yaygın hastalıklardan sadece biridir ve tıpkı şeker hastalığında olduğu gibi genetik bir yatkınlık vardır ve yavaş yavaş gelişir. Yüksek tansiyon, kardiyovasküler hastalık, özellikle inme için bir risk faktörüdür. Yaşam tarzı değişiklikleri genellikle kan basıncını normalleştirmek için yeterli olabilir.',
      bloodPressureText2: 'Hesaplamalarımıza ve ölçümlerimize göre riskiniz:',
      glucoseTitle: 'Şeker Metabolizması',
      glucoseText1: 'Vücutta glikozun düzenlenmesi en önemli metabolik görevlerden biridir. Hormonlar ve enzimler arasındaki hassas denge, öğünlerden büyük ölçüde bağımsız olarak dengeli bir şeker seviyesi sağlar. Tip 2 diyabetli kişiler, açık bir kalıtım modeline sahip değildir, ancak etkilenen birçok kişinin ebeveyn veya kardeş gibi hastalığı olan en az bir yakın aile üyesi vardır. Tip 2 diyabet geliştirme riski, etkilenen aile üyelerinin sayısı ile artar. Zamanla, yüksek kan şekeri seviyeleri kan damarlarının esnekliğini azaltabilir ve hayati organlara zarar verebilir.',
      generalText2: 'Hesaplamalarımıza ve ölçümlerimize göre riskiniz:',
      lipidsTitle: 'Şeker Metabolizması Bozukluğu',
      lipidsText1: 'Şeker metabolizması bozukluğu durumunda olduğu gibi, genetik profiliniz esas olarak kanınızdaki kolesterol ve trigliserit miktarını belirler. Her iki yağ da vücut için temel yapı taşlarıdır, ancak değerleri çok yüksekse, özellikle kan damarları için zararlıdırlar ve kalp hastalığına, felçlere veya diğer dolaşım bozukluklarına yol açabilirler.',
      ironMetabolismTitle: 'Demir Metabolizması',
      ironMetabolismText1: 'Demir, kan üretimi için gerekli bir elementtir ve vücudunuzdaki demirin yaklaşık yüzde 70\'i hemoglobin adı verilen kırmızı kan hücrelerinde bulunur. Bağırsağın üst kısmında meydana gelen demir emilimi sınırlı olduğu için ilgili tıbbi durumlardan kan kaybına, diyet seçimlerine kadar birçok nedenden dolayı emilimin bozulması veya alımının azalması durumunda demir eksikliği veya sideropeni oluşabilir. Demir eksikliğinin erken tespiti son derece önemlidir.',
      diseaseRisks: 'Hastalık Riskleri',
      diabetesMellitus2Title: 'Diabetes',
      diabetesMellitus2Text1: 'Tip 2 diyabet, sıklıkla genetik faktörlerin neden olduğu en yaygın hastalıklardan biridir. Hastalık sinsice gelişir ve genellikle kilo alımıyla bağlantılıdır. Şeker hastalarında kardiyovasküler hastalık riski önemli ölçüde artar. Kilo vermeyi amaçlayan düzenli spor aktiviteleri ve düşük karbonhidratlı diyet, tip 2 diyabetin önlenmesi ve yönetiminde temel unsurlardır.',
      diagnosedHypertensionTitle: 'Hipertansiyon',
      diagnosedHypertensionText1: 'Yüksek tansiyon veya hipertansiyon, en yaygın hastalıklardan sadece biridir ve tıpkı diyabet durumunda olduğu gibi, buna genetik bir yatkınlık vardır. Yüksek tansiyon, kilo alımı ile birlikte yıllar içinde kademeli olarak gelişir. Yüksek tansiyon, kardiyovasküler hastalık, özellikle inme ve böbrek fonksiyon bozukluğu için bir risk faktörüdür. Daha fazla fiziksel aktiviteye ve daha sağlıklı beslenmeye yönelik yaşam tarzı değişiklikleri genellikle kan basıncını normalleştirmek için yeterli olabilir.',
      kidneyDeseaseTitle: 'Böbrek Disfonksiyonu',
      kidneyDeseaseText1: 'Böbrekler, vücudun su ve tuz dengesinin dengelenmesi, atık ürünlerin ve fazla sıvının vücuttan atılması için gereklidir. Erken evrelerde genellikle böbrek hastalığının hiçbir belirtisi olmadığından, hastalığı mümkün olan en kısa sürede tespit edip tedavi edebilmek için ara sıra yapılan bir kontrol çok önemlidir.',
      liverTitle: 'Karaciğer Disfonksiyonu',
      liverText1: 'Karaciğer bizim merkezi metabolik organımızdır ve genellikle \'vücudun kimyasal fabrikası\' olarak anılır. Karaciğer sorunlarına virüsler, toksik maddeler, aşırı alkol tüketimi gibi karaciğere zarar veren çeşitli faktörler neden olabilir. - tehdit edici durum. Ancak erken tedavi karaciğere iyileşmesi için zaman verebilir.',
      metabolicDysfunctionTitle: 'Lipit metabolizması bozukluğu',
      metabolicDysfunctionText1: 'Yüksek trigliserit ve kolesterol seviyeleri kalıtsal olabilir (genetik) veya diğer tıbbi durumlardan kaynaklanabilir. Lipit metabolizması bozuklukları, \'arterlerin sertleşmesi\' ile karakterize edilir; Bu, atardamar duvarlarında yağ, kolesterol ve diğer maddeler biriktiğinde olur. Bu birikintilere plak denir. Zamanla, bu plaklar arterleri daraltabilir veya tamamen tıkayabilir ve vücutta sorunlara neden olabilir. Lipit metabolizması bozukluğunun doğru ve erken tespiti, onu yavaşlatmak ve hatta kısmen tersine çevirmek için zamanında karar verilmesi için gereklidir.',
      heartFailureTitle: 'Kalp Yetmezliği',
      heartFailureText1: 'Önemli sayıda faktör ve genetik varyant, kalp kaslarının zayıflamasına neden olabilir, bu da kalbin pompalama yeteneğini tehlikeye atar ve dolayısıyla yaşam kalitesinde önemli bir kayba neden olur. Kalp yetmezliği için belirli risk faktörleri kontrol edilebilir ve hatta zamanında önlenebilir.',
      overallRisk: 'Genel Risk',
      healthSliceTitle: 'Genel Sağlık Durumu',
      healthSliceText: 'Genel olarak, genel sağlık durumunuz çoğunlukla şu şekilde kabul edilebilir:',
      healthSliceHigh: 'Sonuç için sizi tebrik ediyoruz! Sağlıklı yaşam için iyi alışkanlıklarınızı sürdürdüğünüzden emin olun. xx yıl sonra tekrar sağlık kontrolü yaptırmayı düşünmenizi öneririz.',
      healthSliceMid: 'Bazı olumlu sonuçlara rağmen, birkaç olumsuz sağlık sonucu tespit ettik. Karşılaştığınız riskleri bilmek, sağlık sorunlarından kaçınmanın yollarını bulmanıza yardımcı olabilir. Check-up sonuçlarınızı doktorunuza veya hekiminize danışmanızı öneririz.',
      healthSliceLow: 'Sağlık sorunları açısından yüksek risk altında olduğunuzu öğrendik. Check-up sonuçlarınızı görüşmek için doktorunuzdan randevu almanızı önemle tavsiye ederiz. Güvendiğiniz doktorunuz veya tıbbi danışmanınızla birlikte, sağlıklı bir yaşam tarzına dönüş planınızı hazırlayabilirsiniz.',
    }

  }

};

export default tr;
