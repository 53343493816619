import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(() => ({
  snackBarVisible: {
    '-webkit-animation': 'fadein 0.5s, fadeout 0.5s 2.5s',
    animation: 'fadein 0.5s, fadeout 0.5s 2.5s',
  },

  '@-webkit-keyframes fadein': {
    '0%': {
      bottom: 0,
      opacity: 0,
    },
    '100%': {
      bottom: '30px',
      opacity: 1,
    },
  },

  '@keyframes fadein': {
    '0%': {
      bottom: 0,
      opacity: 0,
    },
    '100%': {
      bottom: '30px',
      opacity: 1,
    }
  },

  '@-webkit-keyframes fadeout': {
    '0%': {
      bottom: '30px',
      opacity: 1,
    },
    '100%': {
      bottom: 0,
      opacity: 0,
    },
  },

  '@keyframes fadeout': {
    '0%': {
      bottom: '30px',
      opacity: 1,
    },
    '100%': {
      bottom: 0,
      opacity: 0,
    },
  },
}));
