import React, {useCallback, useEffect, useState} from 'react';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import Header from 'layouts/Header';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from 'components/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import RiskFactorGradient from 'components/RiskFactorGradient';
import StepHeader from 'layouts/StepHeader';
import useGetPatientData from 'helpers/hooks/useGetPatientData';
import {useHistory} from 'react-router';
import {normalizedValue} from 'helpers/utils';
import {phase2Service} from 'services/phase2Licence.service';
import {warning} from 'helpers/logger';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

function normalizedData(data) {
  const {allCauseMortality, cancer, cardiovascular, congestiveHeartFailure} = data;
  const riskArray = [
    {name: 'risks.ACM', refVal: allCauseMortality.refVal, value: allCauseMortality.value},
    {name: 'morbidity.cancer', refVal: cancer.refVal, value: cancer.value},
    {name: 'risks.cardiovascular', refVal: cardiovascular.refVal, value: cardiovascular.value},
    {name: 'risks.CHF', refVal: congestiveHeartFailure.refVal, isLink: true, value: congestiveHeartFailure.value},
    {name: 'morbidity.stroke', refVal: 0, value: 0},
    {name: 'risks.ESLD', refVal: 0, value: 0},
    {name: 'risks.ESRD', refVal: 0, value: 0},
    {name: 'risks.COPD', refVal: 0, value: 0},
  ];
  return orderBy(riskArray, ['risk'], ['desc']);
}

const MortalityRisks = () => {
  const classes = useStyles();
  const userData = useGetPatientData();
  const [data, setData] = useState(null);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    phase2Service.mortalityRisks({...userData, years: 10})
      .then((res) => {
        setData(normalizedData(res));
      })
      .catch(warning);
  }, [userData]);

  const handlePrev = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleFinish = useCallback(() => {
    history.push('/export');
  }, [history]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <StepHeader title="pageHeader.mortalityRisks" subtitle={t('risks.timeHorizon', {time: 10})} />
        <Box mt={2}>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={10} />
            <Grid item xs={2}>
              <Typography variant="h5" align="right">{t('risks.riskRatio')}</Typography>
            </Grid>
          </Grid>
        </Box>
        {data && (
        <Box mt={2}>
          {data.map((el => (
            <RiskFactorGradient
              key={el.name}
              title={el.name}
              value={el.value !== 0 ? normalizedValue(el.value) : null}
              refVal={el.refVal !== 0 ? normalizedValue(el.value / el.refVal) : null}
              type="phase3"
              isLink={el.isLink}
              visibleValue={el.value !== 0 ? round(el.value, 2) : undefined}
            />
          )))}
        </Box>
        )}
        <Divider />
        <Box marginTop="30px" marginBottom="30px">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <IconButton color="primary" onClick={handlePrev}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleFinish}>
                <DoneIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>

  );
};

export default MortalityRisks;
