import React, {useMemo, useEffect, memo, useState} from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {FormSpy} from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {FormField as Slider} from 'components/form/Slider';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import HorizontalFormItem from 'layouts/FormItem/Horizontal';
import {useActions, useFilters} from 'context';
import {marks, yesNoRadio} from 'services/constants';

const getSmokeQuitDate = (smoking) => (smoking === '1' ? new Date().getFullYear() : null);

const Filters = ({weight, initialValues, isReset, handleSubmit}) => {
  const filters = useFilters();
  const [key, setKey] = useState(0);
  const {setFilters} = useActions();
  const isSmoking = get(initialValues, 'smkNow', false);
  const weightMinMax = useMemo(() => ({
    min: Math.round(Number(weight) - (0.25 * weight)),
    max: Math.round(Number(weight) + (0.25 * weight)),
  }), [weight]);
  const weightMarks = useMemo(() => ([
    {
      value: weightMinMax.min,
      label: `${weightMinMax.min}`
    },
    {
      value: weightMinMax.max,
      label: `${weightMinMax.max}`
    }
  ]), [weightMinMax.min, weightMinMax.max]);

  useEffect(() => {
    if (!isReset) {
      setKey(prevKey => prevKey + 1);
    }
  }, [isReset]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box>
        <Grid container spacing={4} mt={1} alignItems="center">
          {isSmoking && (
          <Grid item xs={12} sm={6}>
            <HorizontalFormItem isSpaceBetween label="plusRisks.filter.quitSmoking">
              <RadioButtonGroupField
                name="smoking"
                items={yesNoRadio}
                type="radio"
              />
            </HorizontalFormItem>
          </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="basicData.weight"
              name="weight"
              min={weightMinMax.min}
              step={1}
              max={weightMinMax.max}
              marks={weightMarks}
              withInput
              withoutTranslation
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.exerciseWalkPerWeek.label"
              name="exerciseWalkPerWeek"
              min={0}
              step={0.5}
              max={6}
              marks={marks.exerciseMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.exerciseRunPerWeek.label"
              name="exerciseRunPerWeek"
              min={0}
              step={0.5}
              max={6}
              marks={marks.exerciseMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.exerciseBicyclePerWeek.label"
              name="exerciseBicyclePerWeek"
              min={0}
              step={0.5}
              max={6}
              marks={marks.exerciseMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.exerciseSimulatorsPerWeek.label"
              name="exerciseSimulatorsPerWeek"
              min={0}
              step={0.5}
              max={6}
              marks={marks.exerciseMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.alcoBeerPerWeek.label"
              name="alcoBeerPerWeek"
              min={0}
              step={1}
              max={20}
              marks={marks.alcoMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.alcoWinePerWeek.label"
              name="alcoWinePerWeek"
              min={0}
              step={1}
              max={20}
              marks={marks.alcoMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.alcoWhiskeyPerWeek.label"
              name="alcoWhiskeyPerWeek"
              min={0}
              step={1}
              max={20}
              marks={marks.alcoMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.coffePerDay.label"
              name="coffePerDay"
              min={0}
              step={1}
              max={6}
              marks={marks.coffeeTeaMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.teaPerDay.label"
              name="teaPerDay"
              min={0}
              step={1}
              max={6}
              marks={marks.coffeeTeaMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.nutFruit.label"
              name="nutFruit"
              min={0}
              step={1}
              max={28}
              marks={marks.fruitMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.nutVegetables.label"
              name="nutVegetables"
              min={0}
              step={1}
              max={28}
              marks={marks.vegetableMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.nutChicken.label"
              name="nutChicken"
              min={0}
              step={1}
              max={14}
              marks={marks.redMeatMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.nutRedMeat.label"
              name="nutRedMeat"
              min={0}
              step={1}
              max={14}
              marks={marks.redMeatMarks}
              withInput
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slider
              key={key}
              label="lifeStyle.nutFish.label"
              name="nutFish"
              min={0}
              step={1}
              max={14}
              marks={marks.fishMarks}
              withInput
            />
          </Grid>
        </Grid>
      </Box>
      <FormSpy
        subscription={{values: true}}
        onChange={({values}) => {
          setFilters({key: 'plusFilters',
            data: {
              ...filters.plusFilters,
              ...values,
              smkQuitYear: isSmoking ? getSmokeQuitDate(values.smoking) : values.smkQuitYear,
              smkEver: values.smoking === '1',
              smkNow: values.smoking === '0'
            }});
        }}
      />
    </form>
  );
};

Filters.propTypes = {
  weight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  isReset: PropTypes.bool
};

export default memo(Filters);
