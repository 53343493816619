import React from 'react';
import {AppBar, Container, Grid, Toolbar} from '@material-ui/core';
import {Link} from 'react-router-dom';
import logo from 'assets/images/check_logo.png';
import {useTranslation} from 'react-i18next';
import CoreSelect from 'react-select';
import {switchValidatorLang} from 'helpers/utils';
import {shortStyles} from 'components/form/Select/styles';
import useStyles from './styles';

export const langs = [
  {value: 'de', label: 'DE'},
  {value: 'fr', label: 'FR'},
  {value: 'it', label: 'IT', isDisabled: true},
  {value: 'en', label: 'EN'},
  {value: 'sp', label: 'SP', isDisabled: true},
  {value: 'ru', label: 'RU'},
  {value: 'tr', label: 'TR'},
];

const Header = () => {
  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng.value);
    switchValidatorLang(lng.value);
  };

  return (
    <AppBar position="static" className={classes.header}>
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <Grid container className={classes.headerWrapper}>
            <Grid item>
              <Link to="/">
                <img src={logo} alt={t('header.logo')} className={classes.pageLogo} />
              </Link>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={4} className={classes.linksWrapper}>
                <Grid item>
                  <CoreSelect
                    options={langs}
                    defaultValue={langs.find(el => el.value === i18n.language)}
                    onChange={changeLanguage}
                    styles={shortStyles}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
