import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Field} from 'react-final-form';
import {Switch, FormControl, FormControlLabel, FormHelperText, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    flex-direction: row; 
  }
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px;
    margin-right: 0px;
  }
  .MuiGrid-container {
    width: auto;
    margin-left: 20px;
    flex-wrap: nowrap;
  }
`;

const useStyles = makeStyles({
  fullWidthSwitch: {
    width: '100%',
    justifyContent: 'space-between',
    '& .MuiFormControlLabel-label': {
      fontWeight: 600,
    },
  },
  withoutLabel: {
    '& .MuiGrid-container': {
      marginLeft: 0
    }
  }
});

const SwitchField = ({input, meta: {error, touched}, label, withoutLabel, fullWidthSwitch, ...rest}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <StyledFormControl error={touched && !!error} fullWidth={!withoutLabel}>
      <FormControlLabel
        label={t(label)}
        labelPlacement="start"
        className={clsx(fullWidthSwitch && classes.fullWidthSwitch, withoutLabel && classes.withoutLabel)}
        control={(
          <Grid component="label" container alignItems="center">
            <Grid item>{t('radio.yesNo.no')}</Grid>
            <Grid item>
              <Switch
                color="primary"
                {...input}
                {...rest}
              />
            </Grid>
            <Grid item>{t('radio.yesNo.yes')}</Grid>
          </Grid>
      )}
      />
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </StyledFormControl>
  );
};

SwitchField.defaultProps = {
  fullWidthSwitch: false,
  withoutLabel: false
};

SwitchField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  label: PropTypes.string,
  fullWidthSwitch: PropTypes.bool,
  withoutLabel: PropTypes.bool
};

const FormField = props => <Field component={SwitchField} type="checkbox" {...props} />;

FormField.propTypes = {
  name: PropTypes.string.isRequired
};

export default SwitchField;

export {FormField};
