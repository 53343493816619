import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Form} from 'react-final-form';
import IconButton from 'components/IconButton';

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    calculator: PropTypes.func,
    initialValues: PropTypes.object,
    toLastPage: PropTypes.bool,
  }

  static Page = ({children}) => children

  constructor(props) {
    super(props);
    this.state = {
      page: props.toLastPage ? React.Children.count(props.children) - 1 : 0,
      values: props.initialValues || {}
    };
  }

  next = (values) => {
    const {children} = this.props;
    this.setState(state => ({
      page: Math.min(state.page + 1, children.length - 1),
      values
    }));
  }

  previous = () => this.setState(state => ({
    page: Math.max(state.page - 1, 0)
  }))

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const {page} = this.state;
    const {children} = this.props;
    const activePage = React.Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  }

  handleSubmit = values => {
    const {children, onSubmit} = this.props;
    const {page} = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    }
    this.next(values);
  }

  render() {
    const {children, calculator} = this.props;
    const {page, values} = this.state;
    const lastPage = React.Children.count(children) - 1;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === lastPage;
    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        decorators={calculator !== undefined ? [calculator] : null}
      >
        {({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <Box marginTop="30px" marginBottom="30px">
              <Grid container spacing={2} justify="center">
                {page > 0 && (
                  <Grid item>
                    <IconButton color="primary" onClick={this.previous}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                )}
                {!isLastPage &&
                <Grid item>
                  <IconButton type="submit" color="primary">
                    <ArrowForwardIcon />
                  </IconButton>
                </Grid>}
                {isLastPage && (
                <Grid item>
                  <IconButton type="submit" color="primary" disabled={submitting}>
                    <ArrowForwardIcon />
                  </IconButton>
                </Grid>
                )}
              </Grid>
            </Box>
          </form>
        )}
      </Form>
    );
  }
}
