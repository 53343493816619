import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Grid, Typography, Divider} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

const PhaseOneLayout = ({children, title, withoutDivider}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <>
      <Grid
        container
        spacing={2}
        className={clsx(classes.stepWrapper, withoutDivider && classes.stepWrapperWithoutDivider)}
      >
        <Grid xs={12} item>
          <Typography variant="h6">{t(title)}</Typography>
        </Grid>
        {children}
      </Grid>
      {!withoutDivider && (<Divider />)}
    </>
  );
};

PhaseOneLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  withoutDivider: PropTypes.bool,
};

export default PhaseOneLayout;
