import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({

  exportBack: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute'
  },

  exportWrapper: {
    textAlign: 'center',
    minHeight: '200px',
    padding: 16,
    display: 'flex',
    border: '1px solid',
    borderRadius: '5px'
  },

  alertLink: {
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
  },
  pdfIcon: {
    height: '36px',
    marginRight: '20px'
  }
}));
