/* eslint-disable max-len */
import React from 'react';
import {Box, Button, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import RiskFactorsSummary from './RiskFactorsSummary';
import DiseaseRisks from './DiseaseRisks';
import AcmChart from './AcmChart';
import useStyles from './styles';
import {getRiskTextForReport, getDiseaseTextForReport, getOverallRisk, exportToPdf, GENERAL_TEXT} from './utils';

const Report = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const patient = JSON.parse(sessionStorage.getItem('patient'));
  const riskData = JSON.parse(sessionStorage.getItem('riskFactorsSummary'));
  const diseaseData = JSON.parse(sessionStorage.getItem('diseaseRisks'));
  const overallData = JSON.parse(sessionStorage.getItem('acmData'));
  return (
    <Box>
      <Button
        onClick={() => exportToPdf({
          t,
          bmi: patient.bmi,
          riskData,
          diseaseData,
          overallData
        })}
        color="primary"
        variant="outlined"
        size="small"
      >
        {t('phase1Report.pdfBtn')}
      </Button>
      <Box mb={4}>
        <Typography variant="h2">{t('phase2Report.header')}</Typography>
        <Box mb={2}>
          <Typography variant="body2">{t('phase2Report.headerText1')}</Typography>
        </Box>
        <Box>
          <Typography variant="body2">{t('phase2Report.headerText2')}</Typography>
        </Box>
      </Box>
      <Box mb={2}>
        <Typography variant="h2">{t('phase2Report.riskFactors')}</Typography>
      </Box>
      <div id="riskFactorsChart">
        <RiskFactorsSummary />
      </div>
      <Box>
        <ol className={classes.reportOl}>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.overweightTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.overweightText1')}</Typography>
              </Box>
              <Box>{`${t('phase2Report.overweightText2', {bmi: patient.bmi})} ${t(getRiskTextForReport(riskData.obesity, 'obesity'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.alcoholTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.alcoholText1')}</Typography>
              </Box>
              <Box>{`${t('phase2Report.alcoholText2')} ${t(getRiskTextForReport(riskData.alcohol, 'alcohol'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.smokingTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.smokingText1')}</Typography>
              </Box>
              <Box>{`${t('phase2Report.smokingText2')} ${t(getRiskTextForReport(riskData.smoking, 'smoking'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.movementTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.movementText1')}</Typography>
              </Box>
              <Box>{`${t('phase2Report.movementText2')} ${t(getRiskTextForReport(riskData.movement, 'movement'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.nutritionTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.nutritionText1')}</Typography>
              </Box>
              <Box>{`${t('phase2Report.nutritionText2')} ${t(getRiskTextForReport(riskData.nutrition, 'nutrition'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.sleepTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.sleepText1')}</Typography>
              </Box>
              <Box>{`${t('phase2Report.sleepText2')} ${t(getRiskTextForReport(riskData.sleep, 'sleep'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.bloodPressureTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.bloodPressureText1')}</Typography>
              </Box>
              <Box>{`${t('phase2Report.bloodPressureText2')} ${t(getRiskTextForReport(riskData.bloodPressure, 'bloodPressure'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.glucoseTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.glucoseText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getRiskTextForReport(riskData.glucose, 'glucose'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.lipidsTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.lipidsText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getRiskTextForReport(riskData.lipids, 'lipids'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.ironMetabolismTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.ironMetabolismText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getRiskTextForReport(riskData.ironMetabolism, 'ironMetabolism'))}`}</Box>
            </Box>
          </li>
        </ol>
      </Box>
      <Box mb={2}>
        <Typography variant="h2">{t('phase2Report.diseaseRisks')}</Typography>
      </Box>
      <div id="diseaseRisksChart">
        <DiseaseRisks />
      </div>
      <Box>
        <ol className={classes.reportOl}>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.diabetesMellitus2Title')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.diabetesMellitus2Text1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.diabetesMellitus2'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.diagnosedHypertensionTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.diagnosedHypertensionText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'morbidity.diagnosedHypertension'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.kidneyDeseaseTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.kidneyDeseaseText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'morbidity.kidneyDesease'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.liverTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.liverText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.liver'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.metabolicDysfunctionTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.metabolicDysfunctionText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.metabolicDysfunction'))}`}</Box>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase2Report.heartFailureTitle')}</Typography>
              <Box mb={2}>
                <Typography variant="body2">{t('phase2Report.heartFailureText1')}</Typography>
              </Box>
              <Box>{`${t(GENERAL_TEXT)} ${t(getDiseaseTextForReport(diseaseData, 'risks.heartFailure'))}`}</Box>
            </Box>
          </li>
        </ol>
      </Box>
      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="h2">{t('phase2Report.overallRisk')}</Typography>
        </Box>
        <div id="overallRisksChart">
          <AcmChart />
        </div>
        <Box mb={2}>
          <Typography variant="h2">{t('phase2Report.healthSliceTitle')}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body2">{`${t('phase2Report.healthSliceText')} ${t(getOverallRisk(overallData.healthSlice))}`}</Typography>
        </Box>
        {overallData.healthSlice > 0.9 && (
          <Box mb={2}>
            <Typography variant="body2">{t('phase2Report.healthSliceHigh')}</Typography>
          </Box>
        )}
        {overallData.healthSlice <= 0.9 && overallData.healthSlice >= 0.7 && (
          <Box mb={2}>
            <Typography variant="body2">{t('phase2Report.healthSliceMid')}</Typography>
          </Box>
        )}
        {overallData.healthSlice < 0.7 && (
          <Box mb={2}>
            <Typography variant="body2">{t('phase2Report.healthSliceLow')}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Report;
