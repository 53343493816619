import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Field} from 'react-final-form';
import CoreTextField from '@material-ui/core/TextField';

const StyledTextField = styled(CoreTextField)`
  .MuiOutlinedInput-multiline {
    padding: ${({label}) => (!label ? '18.5px 14px' : '26px 14px 11px')};
  }
`;

const TextField = ({input, meta: {error, touched}, showErrorOnRender, label, ...rest}) => {
  const {t} = useTranslation();
  return (
    <StyledTextField
      label={t(label)}
      error={!showErrorOnRender ? touched && !!error : !!error}
      helperText={!showErrorOnRender ? touched && error : error}
      {...input}
      {...rest}
    />
  );
};
TextField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  showErrorOnRender: PropTypes.bool,
  label: PropTypes.string
};

const FormField = props => <Field component={TextField} {...props} />;

FormField.propTypes = {
  name: PropTypes.string.isRequired
};

export default CoreTextField;

export {FormField};
