import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepWrapper: {
    padding: theme.spacing(4, 0),
  },
  media: {
    height: 213,
    width: 148,
    margin: '0 auto',
  },
  fruitMedia: {
    height: 261,
    width: 256,
    margin: '0 auto',
  },
  vegetablesMedia: {
    height: 298,
    width: 426,
    margin: '0 auto',
  },
  fishMedia: {
    height: 95,
    width: 363,
    margin: '0 auto',
  },
  redmeatMedia: {
    height: 112,
    width: 325,
    margin: '0 auto',
  },
  unitWrapper: {
    width: 'auto'
  },
  numField: {
    '& .MuiInputBase-root': {
      width: '100px'
    }
  },
  sliderWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  sliderImage: {
    width: '75px',
    minWidth: '75px',
    height: '75px',
    marginRight: '25px'
  }
}));
