import React from 'react';
import PropTypes from 'prop-types';
import {Divider, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const StepHeader = ({title, subtitle}) => {
  const {t} = useTranslation();
  return (
    <>
      <Typography variant="h2">
        {t(title)}
        {subtitle && (` (${subtitle})`)}
      </Typography>
      <Divider />
    </>
  );
};

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default StepHeader;
