import React from 'react';
import {Typography} from '@material-ui/core';
import logo from 'assets/images/start_logo.png';
import styled, {keyframes} from 'styled-components';
import {bounce} from 'react-animations';
import useStyles from './styles';

const bounceInAnimation = keyframes`${bounce}`;

const BounceInDiv = styled.div`
  animation: 5s ${bounceInAnimation} forwards;
`;

const StartScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <div className={classes.logoWrapper}>
        <BounceInDiv>
          <img src={logo} alt="Логотип" className={classes.logo} />
        </BounceInDiv>
      </div>
      <Typography className={classes.copyright}>powered by remsmed ©2020</Typography>
    </div>
  );
};

export default StartScreen;
