import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Divider, Grid, Typography} from '@material-ui/core';
import {useFormState} from 'react-final-form';
import RiskFactor from 'components/RiskFactor';
import StepHeader from 'layouts/StepHeader';
import {useTranslation} from 'react-i18next';
import {getAge, validatePressure, SYSTOLIC, DYASTOLIC} from 'helpers/utils';
import useStyles from './styles';

const RiskFactors = ({data}) => {
  const classes = useStyles();
  const {values} = useFormState();
  const age = getAge(get(values, 'birthYear', 1));
  const {t} = useTranslation();
  return (
    <>
      <StepHeader title="pageHeader.riskFactors" />
      <Grid container spacing={2} className={classes.stepWrapper}>
        <Grid container className={classes.headerContainer}>
          <Grid item xs={3} sm={4} />
          <Grid item xs={9} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography className={classes.normalLegend}>{t('riskFactors.normalRange')}</Typography>
                <Typography className={classes.expectedLegend}>{t('riskFactors.expectedRange')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item container xs={3}>
                    <Typography variant="h6">{t('riskFactors.normalRange')}</Typography>
                  </Grid>
                  <Grid item container xs={3}>
                    <Typography variant="h6">{t('riskFactors.normalValue')}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6">{t('riskFactors.expectedRange')}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6">{t('riskFactors.actual')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RiskFactor
          label="metrics.bloodPressureSystolic"
          measuredName="bloodPressureSystolic"
          measuredValue={values.bloodPressureSystolic}
          engineMin={80}
          engineMax={190}
          data={get(data, 'bloodPressureSystolic')}
          decimalScale={0}
          preasureGuidelines={validatePressure(get(values, 'gender'), age, SYSTOLIC)}
        />
        <RiskFactor
          label="metrics.bloodPressureDiastolic"
          measuredName="bloodPressureDiastolic"
          measuredValue={values.bloodPressureDiastolic}
          engineMin={40}
          engineMax={120}
          data={get(data, 'bloodPressureDiastolic')}
          decimalScale={0}
          preasureGuidelines={validatePressure(get(values, 'gender'), age, DYASTOLIC)}
        />
        <RiskFactor
          label="metrics.lipidTotalCholesterol"
          measuredName="lipidTotalCholesterol"
          measuredValue={values.lipidTotalCholesterol}
          engineMin={2}
          engineMax={10}
          data={get(data, 'lipidTotalCholesterol')}
        />
        <RiskFactor
          label="metrics.highDensityLiprotein"
          measuredName="highDensityLiprotein"
          measuredValue={values.highDensityLiprotein}
          engineMin={0.5}
          engineMax={3}
          data={get(data, 'highDensityLiprotein')}
        />
        <RiskFactor
          label="metrics.lowDensityLiprotein"
          measuredName="lowDensityLiprotein"
          measuredValue={values.lowDensityLiprotein}
          engineMin={1}
          engineMax={7}
          data={get(data, 'lowDensityLiprotein')}
        />
        <RiskFactor
          label="metrics.ratioOfTotalCholesterolToHdl"
          measuredName="ratioOfTotalCholesterolToHdl"
          measuredValue={values.ratioOfTotalCholesterolToHdl}
          engineMin={0}
          engineMax={11}
          data={get(data, 'ratioOfTotalCholesterolToHdl')}
          isActualDisbled
        />
        <RiskFactor
          label="metrics.triglycerides"
          measuredName="triglycerides"
          measuredValue={values.triglycerides}
          engineMin={0.2}
          engineMax={6.5}
          data={get(data, 'triglycerides')}
        />
        <RiskFactor
          label="metrics.fastingBloodGlucose"
          measuredName="fastingBloodGlucose"
          measuredValue={values.fastingBloodGlucose}
          engineMin={3}
          engineMax={14}
          data={get(data, 'fastingBloodGlucose')}
        />
        <RiskFactor
          label="metrics.glycatedHemoglobin"
          measuredName="glycatedHemoglobin"
          measuredValue={values.glycatedHemoglobin}
          engineMin={3.5}
          engineMax={10.5}
          data={get(data, 'glycatedHemoglobin')}
        />
        <RiskFactor
          label="metrics.cReactiveProtein"
          measuredName="cReactiveProtein"
          measuredValue={values.cReactiveProtein}
          engineMin={0.1}
          engineMax={55}
          data={get(data, 'creactiveProtein')}
        />
        <RiskFactor
          label="metrics.alanineAminotransferase"
          measuredName="alanineAminotransferase"
          measuredValue={values.alanineAminotransferase}
          engineMin={4}
          engineMax={83}
          data={get(data, 'alanineAminotransferase')}
        />
        <RiskFactor
          label="metrics.aspartateAminotransferase"
          measuredName="aspartateAminotransferase"
          measuredValue={values.aspartateAminotransferase}
          engineMin={10}
          engineMax={60}
          data={get(data, 'aspartateAminotransferase')}
        />
        <RiskFactor
          label="metrics.gammaGlutamylTransferase"
          measuredName="gammaGlutamylTransferase"
          measuredValue={values.gammaGlutamylTransferase}
          engineMin={3}
          engineMax={140}
          data={get(data, 'gammaGlutamylTransferase')}
        />
        <RiskFactor
          label="metrics.glomerularFiltrationRate"
          measuredName="glomerularFiltrationRate"
          measuredValue={values.glomerularFiltrationRate}
          engineMin={18}
          engineMax={180}
          data={get(data, 'glomerularFiltrationRate')}
        />
        <RiskFactor
          label="metrics.albuminCreatinineUrineRatio"
          measuredName="albuminCreatinineUrineRatio"
          measuredValue={values.albuminCreatinineUrineRatio}
          engineMin={0}
          engineMax={100}
          data={get(data, 'albuminCreatinineUrineRatio')}
        />
      </Grid>
      <Divider />
    </>
  );
};

RiskFactors.propTypes = {
  data: PropTypes.object
};

export default RiskFactors;
