const NO = 'radio.yesNo.no';
const YES = 'radio.yesNo.yes';
const NEVER = 'marks.never';
const OFTEN = 'marks.often';

export const smokeProducts = [
  {value: 1, label: 'select.smokeProducts.cigarettes'},
  {value: 2, label: 'select.smokeProducts.cigar'},
  {value: 3, label: 'select.smokeProducts.pipe'},
  {value: 4, label: 'select.smokeProducts.chewingTobacco'},
  {value: 5, label: 'select.smokeProducts.snuff'},
  {value: 6, label: 'select.smokeProducts.eCigs'},
  {value: 7, label: 'select.smokeProducts.other'},
];

export const heightUnits = [
  {value: 1, label: 'select.heightUnits.cm'},
  {value: 2, label: 'select.heightUnits.inch'}
];

export const weightUnits = [
  {value: 1, label: 'select.weightUnits.kg'},
  {value: 2, label: 'select.weightUnits.pound'}
];

export const parents = [
  {value: 1, label: 'select.parents.one'},
  {value: 2, label: 'select.parents.both'}
];

export const months = [
  {value: 1, label: 'month.january'},
  {value: 2, label: 'month.february'},
  {value: 3, label: 'month.march'},
  {value: 4, label: 'month.april'},
  {value: 5, label: 'month.may'},
  {value: 6, label: 'month.june'},
  {value: 7, label: 'month.july'},
  {value: 8, label: 'month.august'},
  {value: 9, label: 'month.september'},
  {value: 10, label: 'month.october'},
  {value: 11, label: 'month.november'},
  {value: 12, label: 'month.december'}
];

export const yesNoRadio = [
  {value: '0', label: NO},
  {value: '1', label: YES}
];

export const yesNoRadio2 = [
  {value: '0', label: NO},
  {value: '1', label: YES},
  {value: '-1', label: 'radio.yesNo.dontKnow'}
];

export const yesNoNARadio = [
  {value: '0', label: NO},
  {value: '1', label: YES},
  {value: '-1', label: 'radio.yesNo.na'}
];

export const genderFieldConfig = [
  {
    value: 'M',
    label: 'radio.gender.male'
  },
  {
    value: 'F',
    label: 'radio.gender.female'
  }
];

const exerciseMarks = [
  {
    value: 0,
    label: ['marks.hour', 0]
  },
  {
    value: 6,
    label: ['marks.moreHours', 6]
  }
];

const psychometricMarks = [
  {
    value: 0,
    label: 'marks.terrible'
  },
  {
    value: 1,
    label: 'marks.perfect'
  }
];

const nutritionMarks = [
  {
    value: 0,
    label: 'marks.seldom'
  },
  {
    value: 1,
    label: 'marks.perWeek'
  }
];

const coffeeTeaMarks = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 6,
    label: ['marks.more', 6],
  }
];
const alcoMarks = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 20,
    label: ['marks.more', 20]
  }
];
const fruitMarks = [
  {
    value: 0,
    label: NEVER
  },
  {
    value: 28,
    label: OFTEN
  }
];

const vegetableMarks = [
  {
    value: 0,
    label: NEVER
  },
  {
    value: 28,
    label: OFTEN
  }
];

const redMeatMarks = [
  {
    value: 0,
    label: NEVER
  },
  {
    value: 14,
    label: OFTEN
  }
];

const fishMarks = [
  {
    value: 0,
    label: NEVER
  },
  {
    value: 14,
    label: OFTEN
  }
];

const sleepMarks = [
  {
    value: 5,
    label: ['marks.less', 5]
  },
  {
    value: 9,
    label: ['marks.more', 9]
  }
];

export const marks = {
  genderFieldConfig,
  exerciseMarks,
  psychometricMarks,
  nutritionMarks,
  fruitMarks,
  vegetableMarks,
  redMeatMarks,
  fishMarks,
  coffeeTeaMarks,
  alcoMarks,
  sleepMarks
};

export const CHOLESTEROL_CONVERSION = 0.02586;
