/* eslint-disable @typescript-eslint/no-explicit-any */
import {buildUrl, getNewAuthHeader, handleResponse} from 'helpers';

const CONTENT_TYPE = 'content-type';
const CONTENT_TYPE_VALUE = 'application/json';

export function getHeader(): Headers {
  const headers = getNewAuthHeader();
  headers.append(CONTENT_TYPE, CONTENT_TYPE_VALUE);
  return headers;
}

export function baseGet(url: string, params: any): Promise<any> {
  const headers = getHeader();
  const requestOptions = {
    method: 'GET',
    headers,
    credentials: 'include',
    redirect: 'manual',
  };
  return fetch(buildUrl(url, params), requestOptions as RequestInit).then(
    handleResponse
  );
}

export function getBaseById(url: string, id: number): Promise<any> {
  const headers = getHeader();
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'manual',
    headers,
  };

  return fetch(`${url}/${id}`, requestOptions as RequestInit).then(
    handleResponse
  );
}

export function basePost(url: string, item: any): Promise<any> {
  const headers = getHeader();
  const requestOptions = {
    method: 'POST',
    contentType: CONTENT_TYPE_VALUE,
    headers,
    credentials: 'include',
    redirect: 'manual',
    body: JSON.stringify(item)
  };

  return fetch(url, requestOptions as RequestInit).then(handleResponse);
}

export function basePut(url: string, item: any): Promise<any> {
  const headers = getHeader();
  const requestOptions = {
    method: 'PUT',
    contentType: CONTENT_TYPE_VALUE,
    headers,
    credentials: 'include',
    redirect: 'manual',
    body: JSON.stringify(item)
  };

  return fetch(url, requestOptions as RequestInit).then(handleResponse);
}

export function baseDelete(url: string, id?: number): Promise<any> {
  const headers = getHeader();
  const requestOptions = {
    method: 'DELETE',
    redirect: 'manual',
    headers,
  };

  const fullURL = id ? `${url}/${id}` : url;

  return fetch(fullURL, requestOptions as RequestInit).then(
    handleResponse
  );
}
