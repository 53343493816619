import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import {getBmiCursorPosition} from 'helpers/utils';

const BmiCursor = styled('div')`
  position: absolute;
  width: 20px;
  height: 20px;
  border: 4px solid black;
  border-radius: 50%;
  margin-left: -10px;
  left: ${({inputvalue}) => (getBmiCursorPosition(inputvalue))}%;
`;

const useStyles = makeStyles({
  gradientWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '32px'
  },
  gradient: {
    width: '300px',
    height: '20px',
    backgroundImage: 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 6.66%, rgba(0,255,0,1) 16.66%, rgba(255,255,0,1) 43.33%, rgba(255,0,0,1) 100%)',
    backgroundRepeat: 'no-repeat',
    marginLeft: '8px',
    marginRight: '8px',
    position: 'relative',
    overflow: 'hidden'
  },
});

const BmiGradient = ({inputvalue}) => {
  const classes = useStyles();
  return (
    <div className={classes.gradientWrapper}>
      <Typography>15</Typography>
      <div className={classes.gradient}>
        <BmiCursor inputvalue={inputvalue} />
      </div>
      <Typography>45</Typography>
    </div>
  );
};

BmiGradient.propTypes = {
  inputvalue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default BmiGradient;
