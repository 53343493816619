import get from 'lodash/get';
import createDecorator from 'final-form-calculate';
import {calculateBMI, calculateCholesterolToHdl} from '../utils';

const calculator = createDecorator(
  {
    field: 'height',
    updates: {
      bmi: (heightValue, allValues) => {
        const weight = get(allValues, 'weight');
        return calculateBMI({
          weight,
          height: heightValue,
        });
      }
    }
  },
  {
    field: 'weight',
    updates: {
      bmi: (weightValue, allValues) => {
        const height = get(allValues, 'height');
        return calculateBMI({
          weight: weightValue,
          height,
        });
      }
    }
  },
  {
    field: 'lipidTotalCholesterol',
    updates: {
      ratioOfTotalCholesterolToHdl: (lipidTotalCholesterolValue, allValues) => {
        const highDensityLiprotein = get(allValues, 'highDensityLiprotein');
        return calculateCholesterolToHdl({
          lipidTotalCholesterol: lipidTotalCholesterolValue,
          highDensityLiprotein,
        });
      }
    }
  },
  {
    field: 'highDensityLiprotein',
    updates: {
      ratioOfTotalCholesterolToHdl: (highDensityLiproteinValue, allValues) => {
        const lipidTotalCholesterol = get(allValues, 'lipidTotalCholesterol');
        return calculateCholesterolToHdl({
          highDensityLiprotein: highDensityLiproteinValue,
          lipidTotalCholesterol,
        });
      }
    }
  },
);

export default calculator;
