import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import StepHeader from 'layouts/StepHeader';
import {FormField as Slider} from 'components/form/Slider';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import {useFormState} from 'react-final-form';
import {yesNoRadio, marks} from 'services/constants';

const ExistingConditions = () => {
  const {values} = useFormState();
  return (
    <>
      <StepHeader title="pageHeader.sleep" />
      <PhaseOneLayout title="pageOneQuestions.sleepWeekdays" withoutDivider>
        <Grid xs={12} sm={6} item>
          <Slider
            name="sleepHoursOnWeekdays"
            min={5}
            step={0.5}
            max={9}
            marks={marks.sleepMarks}
            withInput
          />
        </Grid>
      </PhaseOneLayout>
      {values.sleepHoursOnWeekdays && (
      <Fade in={Boolean(values.sleepHoursOnWeekdays)} timeout={1500}>
        <div>
          <PhaseOneLayout title="pageOneQuestions.isRefreshed" withoutDivider>
            <Grid xs={12} sm={6} item>
              <RadioButtonGroupField
                name="sleepWakeUpRefreshed"
                items={yesNoRadio}
                type="radio"
              />
            </Grid>
          </PhaseOneLayout>
        </div>
      </Fade>
      )}
      {values.sleepWakeUpRefreshed && (
      <Fade in={Boolean(values.sleepWakeUpRefreshed)} timeout={1500}>
        <div>
          <PhaseOneLayout title="pageOneQuestions.sleepWeekends" withoutDivider>
            <Grid xs={12} sm={6} item>
              <Slider
                name="sleepHoursOnHolydays"
                min={5}
                step={0.5}
                max={9}
                marks={marks.sleepMarks}
                withInput
              />
            </Grid>
          </PhaseOneLayout>
        </div>
      </Fade>
      )}
      <Box mt={4}>
        <Divider />
      </Box>
    </>
  );
};

export default ExistingConditions;
