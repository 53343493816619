import React, {useCallback, useEffect, useState} from 'react';
import get from 'lodash/get';
import round from 'lodash/round';
import Header from 'layouts/Header';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from 'components/IconButton';
import Divider from '@material-ui/core/Divider';
import TranslatedText from 'components/TranslatedText';
import DeseaseRisksGraph from 'components/DeseaseRisksGraph';
import StepHeader from 'layouts/StepHeader';
import {useHistory} from 'react-router';
import {phase2Service} from 'services/phase2Licence.service';
import {warning} from 'helpers/logger';
import {normalizedValue} from 'helpers/utils';
import useGetPatientData, {useGetCurrentUserConfigs} from 'helpers/hooks/useGetPatientData';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

function normalizedData(data) {
  const {diabetesMellitus, heartFailure, hypertension,
    kidneyDisease, liverDisease, metabolicDysfunction, totalCancer} = data;
  return [
    {name: 'risks.diabetesMellitus2', risk: diabetesMellitus.value, ciMin: diabetesMellitus.ciMin, ciMax: diabetesMellitus.ciMax, refVal: diabetesMellitus.refVal},
    {name: 'morbidity.diagnosedHypertension', risk: hypertension.value, ciMin: hypertension.ciMin, ciMax: hypertension.ciMax, refVal: hypertension.refVal},
    {name: 'morbidity.kidneyDesease', risk: kidneyDisease.value, ciMin: kidneyDisease.ciMin, ciMax: kidneyDisease.ciMax, refVal: kidneyDisease.refVal},
    {name: 'risks.liver', risk: liverDisease.value, ciMin: liverDisease.ciMin, ciMax: liverDisease.ciMax, refVal: liverDisease.refVal},
    {name: 'risks.metabolicDysfunction', risk: metabolicDysfunction.value, ciMin: metabolicDysfunction.ciMin, ciMax: metabolicDysfunction.ciMax, refVal: metabolicDysfunction.refVal},
    {name: 'risks.heartFailure', risk: heartFailure.value, ciMin: heartFailure.ciMin, ciMax: heartFailure.ciMax, refVal: heartFailure.refVal},
    {name: 'risks.totalCancer', risk: totalCancer.value, ciMin: totalCancer.ciMin, ciMax: totalCancer.ciMax, refVal: totalCancer.refVal},
  ];
}

const DiseaseRisks = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const history = useHistory();
  const userData = useGetPatientData();
  const userConfigs = useGetCurrentUserConfigs();
  const {t} = useTranslation();

  useEffect(() => {
    phase2Service.diseaseRisks({...userData, years: 5})
      .then((res) => {
        setData(normalizedData(res).sort((a, b) => b.risk - a.risk));
        sessionStorage.setItem('diseaseRisks', JSON.stringify(normalizedData(res)));
      })
      .catch(warning);
  }, [userData]);

  const handlePrev = useCallback(() => {
    history.push('/riskfactorsummary');
  }, [history]);

  const handleNext = useCallback(() => {
    const reportLink = get(userConfigs, 'config.client') !== 'base' ? '/patientResult' : '/phase2Report';
    const link = get(userConfigs, 'config.pages.plusRisks') ? '/plus' : reportLink;
    history.push(link);
  }, [history, userConfigs]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <StepHeader title="pageHeader.precursorRisks" subtitle={t('risks.timeHorizon', {time: 5})} />
        <Box mt={2} mb={1}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={4} />
            <Grid item xs={7}>
              <div className={classes.gradient} />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Box>
        <Box mt={2} mb={1}>
          {data.map(row => (
            <Grid key={row.name} container spacing={2} justify="center" alignItems="center">
              <Grid item xs={4}>
                <TranslatedText text={row.name} />
              </Grid>
              <Grid item xs={7}>
                <DeseaseRisksGraph
                  engineMin={0}
                  engineMax={1}
                  ciMin={normalizedValue(row.ciMin)}
                  ciMax={normalizedValue(row.ciMax)}
                  measured={normalizedValue(row.risk)}
                  refVal={normalizedValue(row.risk / row.refVal)}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography>{row.value ? round(row.value, 2) : ''}</Typography>
              </Grid>
            </Grid>
          ))}

        </Box>
        <Box mt={2} mb={2}>
          <Grid container mt={2} spacing={4}>
            <Grid item>
              <Grid container alignItems="baseline">
                <span className={classes.ageGender} />
                <Typography>- {t('plusRisks.chart.averageValue')}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <span className={classes.confidenceInterval} />
                <Typography>- {t('plusRisks.chart.confidenceInterval')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box marginTop="30px" marginBottom="30px">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <IconButton color="primary" onClick={handlePrev}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            {userData.patientPhase && (
              <Grid item>
                <IconButton color="primary" onClick={handleNext}>
                  {get(userConfigs, 'config.pages.plusRisks') ? (
                    <ArrowForwardIcon />
                  ) : (
                    <DoneIcon />
                  )}
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>

  );
};

export default DiseaseRisks;
