import React, {useCallback, useMemo} from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import Header from 'layouts/Header';
import Container from '@material-ui/core/Container';
import {useHistory} from 'react-router';
import {phase1Service} from 'services/phase1.service';
import {phase2Service} from 'services/phase2Licence.service';
import {heightUnits, weightUnits, months, smokeProducts, parents} from 'services/constants';
import useGetPatientData from 'helpers/hooks/useGetPatientData';
import {warning} from 'helpers/logger';
import Form from './Form';
import {getPackYears, calculateBMI, normalizeRadio, normalizeSlider, INIT, SUBMIT} from './utils';
import useStyles from './styles';

const PhasePatientDoctor = () => {
  const classes = useStyles();
  const history = useHistory();
  const data = useGetPatientData();

  const initialValues = useMemo(() => {
    const packYears = getPackYears(data);
    const heightUnit = find(heightUnits, ['value', get(data, 'heightUnitId')]);
    const weightUnit = find(weightUnits, ['value', get(data, 'weightUnitId')]);
    const birthMonth = find(months, ['value', get(data, 'birthMonth')]);
    return ({
      ...data,
      smkPrd: find(smokeProducts, ['value', get(data, 'smkPrd')]),
      heightUnit,
      weightUnit,
      packYears,
      bmi: calculateBMI({height: data.height, weight: data.weight, heightUnit, weightUnit}),
      diagnosedDiabet: normalizeRadio(data.diagnosedDiabet),
      highBloodSugar: normalizeRadio(data.highBloodSugar),
      diagnosedHypertension: normalizeRadio(data.diagnosedHypertension),
      highBloodPressure: normalizeRadio(data.highBloodPressure),
      kidneyDesease: normalizeRadio(data.kidneyDesease),
      metabolicSyndrome: normalizeRadio(data.metabolicSyndrome),
      cardiovascular: normalizeRadio(data.cardiovascular),
      stroke: normalizeRadio(data.stroke),
      cancer: normalizeRadio(data.cancer),
      familyHipertension: normalizeRadio(data.familyHipertension),
      familyCancer: normalizeRadio(data.familyCancer),
      familyCardiovascular: normalizeRadio(data.familyCardiovascular),
      familyDiabetes: normalizeRadio(data.familyDiabetes),
      nutFruit: normalizeSlider(data.nutFruit, INIT),
      nutVegetables: normalizeSlider(data.nutVegetables, INIT),
      nutRedMeat: normalizeSlider(data.nutRedMeat, INIT),
      nutChicken: normalizeSlider(data.nutChicken, INIT),
      nutFish: normalizeSlider(data.nutFish, INIT),
      familyHipertensionCount: find(parents, ['value', get(data, 'familyHipertensionCount')]),
      birthMonth
    });
  }, [data]);

  const handleSubmit = useCallback(({smkPrd, weightUnit, heightUnit, familyHipertensionCount,
    nutFruit, nutVegetables, nutRedMeat, nutChicken, nutFish, birthMonth, ...rest}) => {
    const formattedData = {
      ...rest,
      smkPrd: get(smkPrd, 'value'),
      weightUnitId: get(weightUnit, 'value'),
      heightUnitId: get(heightUnit, 'value'),
      familyHipertensionCount: get(familyHipertensionCount, 'value'),
      nutFruit: normalizeSlider(nutFruit, SUBMIT),
      nutVegetables: normalizeSlider(nutVegetables, SUBMIT),
      nutRedMeat: normalizeSlider(nutRedMeat, SUBMIT),
      nutChicken: normalizeSlider(nutChicken, SUBMIT),
      nutFish: normalizeSlider(nutFish, SUBMIT),
      birthMonth: get(birthMonth, 'value')
    };
    if (data.patientPhase) {
      phase1Service.createPhase1({...formattedData, years: 1})
        .then(res => {
          sessionStorage.setItem('acmData', JSON.stringify(res));
          sessionStorage.setItem('patient', JSON.stringify(formattedData));
          history.push('/riskfactorsummary');
        })
        .catch(warning);
    } else {
      phase2Service.imputed({...formattedData, years: 1})
        .then(res => {
          sessionStorage.setItem('riskFactors', JSON.stringify(res));
          sessionStorage.setItem('patient', JSON.stringify(formattedData));
          history.push('/riskfactors');
        })
        .catch(warning);
    }
  }, [history, data.patientPhase]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          patientPhase={data.patientPhase}
        />
      </Container>
    </>
  );
};

export default PhasePatientDoctor;
