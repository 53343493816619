import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
  '@keyframes fadeOut': {
    from: {
      opacity: 1
    },
    to: {
      opacity: 0,
      visibility: 'hidden'
    }
  },
  background: {
    position: 'fixed',
    margin: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#669ed9',
    borderRadius: 0,
    zIndex: 100,
    animation: `$fadeOut 1s 3s ${theme.transitions.easing.easeInOut} forwards`
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  logo: {
    maxWidth: '300px'
  },
  copyright: {
    marginTop: '-35px',
    textAlign: 'center',
    color: 'white'
  }

}));
