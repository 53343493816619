import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {FormField as Slider} from 'components/form/Slider';
import StepHeader from 'layouts/StepHeader';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import {marks} from 'services/constants';
import beer from 'assets/images/beer.png';
import wine from 'assets/images/wine.png';
import whiskey from 'assets/images/whiskey.png';
import useStyles from '../styles';

const Alcohol = () => {
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.alcohol" />
      <PhaseOneLayout title="pageOneQuestions.alcohol">
        <Grid container spacing={4} item xs={12} sm={6}>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={beer} alt="Beer" title="Beer" className={classes.sliderImage} />
              <Slider
                name="alcoBeerPerWeek"
                min={0}
                step={1}
                max={20}
                marks={marks.alcoMarks}
                withInput
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={wine} alt="Wine" title="Wine" className={classes.sliderImage} />
              <Slider
                name="alcoWinePerWeek"
                min={0}
                step={1}
                max={20}
                marks={marks.alcoMarks}
                withInput
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={whiskey} alt="Liquor" title="Liquor" className={classes.sliderImage} />
              <Slider
                name="alcoWhiskeyPerWeek"
                min={0}
                step={1}
                max={20}
                marks={marks.alcoMarks}
                withInput
              />
            </Box>
          </Grid>
        </Grid>
      </PhaseOneLayout>
    </>
  );
};

export default Alcohol;
