import React, {
  createContext,
  useCallback, useContext as useContextReact,
  useMemo,
  useState,
} from 'react';

const initialState = {
  filters: {}
};

const Context = createContext({state: initialState, actions: {}});

const Provider = (props) => {
  const [filters, setFilters] = useState({});

  const handleSetFilters = useCallback(({key, data}) => {
    const currentFilter = filters[key];
    if (JSON.stringify(currentFilter) === JSON.stringify(data)) {
      return;
    }
    setFilters({
      ...filters,
      [key]: data,
    });
  }, [filters]);

  const actions = useMemo(() => ({
    setFilters: handleSetFilters
  }), [handleSetFilters]);

  const value = useMemo(() => ({
    state: {
      filters,
    },
    actions,
  }), [filters, actions]);

  return (
    <Context.Provider value={value} {...props} />
  );
};

const useContext = () => useContextReact(Context);
const useActions = () => useContext().actions;
const useFilters = () => useContext().state.filters;

export {
  // context
  Provider,
  Context,
  useContext,
  useActions,

  // selectors
  useFilters,
};
