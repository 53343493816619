import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {
  getBmiCursorPosition,
  getBmiColor,
  getRiskFactorColor,
  getRiskCursorPosition,
  switchGradient
} from 'helpers/utils';

const BmiCursor = styled('div')`
  position: absolute;
  width: 20px;
  height: 20px;
  border: 4px solid black;
  border-radius: 50%;
  margin-left: -10px;
  left: ${({inputvalue, type}) => (type === 'bmi' ? getBmiCursorPosition(inputvalue) : getRiskCursorPosition(inputvalue))}%;
`;

const RefCursor = styled('div')`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 20px 10px;
  border-color: transparent transparent #36BADB transparent;
  margin-left: -10px;
  left: ${({inputvalue}) => (getRiskCursorPosition(inputvalue))}%;
`;

const StyledSpan = styled(Box)`
  width: 20px;
  height: 20px;
  display: block;
  background-color: ${({inputvalue, type}) => (type === 'bmi' ? getBmiColor(inputvalue) : getRiskFactorColor(inputvalue, type))};
`;

const StyledGradient = styled('div')`
  width: 100%;
  height: 20px;
  background-image: ${({type}) => (switchGradient(type))};
  background-repeat: no-repeat;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  overflow: visible;
`;

const useStyles = makeStyles({
  gradientWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '32px'
  },
  withoutValue: {
    width: '100%',
    height: '3px',
    marginLeft: '8px',
    marginRight: '8px',
    backgroundColor: '#ADC6CD'
  }
});

const RiskFactorGradient = ({title, value, refVal, type, isLink, visibleValue}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          {isLink ? (
            <Link to="/drilldown"><Typography>{t(title)}</Typography></Link>
          ) : (
            <Typography>{t(title)}</Typography>)}

        </Grid>
        <Grid item xs={1}>
          {value !== null && (<StyledSpan inputvalue={value} type={type} />)}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <div className={classes.gradientWrapper}>
            {value !== null ? (
              <StyledGradient type={type}>
                <BmiCursor inputvalue={value} type={type} />
                {refVal && (<RefCursor inputvalue={refVal} />)}
              </StyledGradient>
            ) : (
              <div className={classes.withoutValue} />
            )}
          </div>
        </Grid>
        {visibleValue !== undefined && (
        <Grid item xs={2}>
          <Typography align="right">{visibleValue}</Typography>
        </Grid>
        )}
      </Grid>
    </Box>
  );
};

RiskFactorGradient.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  refVal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
  isLink: PropTypes.bool,
  visibleValue: PropTypes.number
};

export default RiskFactorGradient;
