import {makeStyles} from '@material-ui/core/styles';

import {
  colorBorder,
  controlHeightLarge,
  colorBgLightActive,
  pageHeaderHeight,
  pageFooterHeight
} from 'theme/variables';

export default makeStyles(theme => ({

  pageContent: {
    minHeight: `calc(100vh - ${pageFooterHeight}px)`,
    paddingTop: pageHeaderHeight,
  },

  pageHome: {},

  pageHomeRegistryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  pageHomeTitle: {
    marginBottom: theme.spacing(3),
    minHeight: controlHeightLarge,
    display: 'flex',
    alignItems: 'center',
  },

  pageHomeTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-end',
    },

    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent !important',
    },

    '& .MuiTab-root': {
      borderLeft: `1px solid ${colorBorder}`,
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:hover': {
        backgroundColor: colorBgLightActive,
      },
    },

    '& .MuiIcon-root': {
      fontSize: 20,
    },
  },
}));
