import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Typography} from '@material-ui/core';
import {FormField as MetabolicTextField} from 'components/form/MetabolicTextField';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

const Horizontal = ({label, name, validation, decimalScale, validationPressure, isDisabled}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Grid container item xs={12} className={classes.controlItem}>
      <Typography className={classes.label}>{t(label)}</Typography>
      <Grid container item alignItems="center" className={classes.fieldWrapper}>
        <Grid item>
          <Typography className={classes.validation}>
            {validationPressure ? `${validationPressure.bpL}-${validationPressure.bpH}` : validation}
          </Typography>
        </Grid>
        <Grid item>
          <MetabolicTextField
            name={name}
            className={classes.numField}
            decimalScale={decimalScale}
            validation={validationPressure}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

Horizontal.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validation: PropTypes.string.isRequired,
  decimalScale: PropTypes.number,
  validationPressure: PropTypes.object,
  isDisabled: PropTypes.bool
};

export default Horizontal;
