/* eslint-disable max-lines */
import ru from 'locale/ru';
import en from 'locale/en';
import de from 'locale/de';
import tr from 'locale/tr';
import fr from 'locale/fr';
import Validators from 'redux-form-validators';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPropertyByKeyPath(targetObj: any, keyPath: string): string | undefined {
  let keys = keyPath.split('.');
  if (keys.length === 0) {
    return undefined;
  }
  keys = keys.reverse();
  let subObject = targetObj;
  while (keys.length) {
    const k = keys.pop();
    if (k && subObject !== null) {
      if (!subObject.hasOwnProperty(k)) {
        return undefined;
      }
      subObject = subObject[k];
    }
  }

  return subObject;
}

export function getCurrentDay(): string | undefined {
  return new Date().toISOString().split('T').shift();
}

export function getAge(birthYear: number): number {
  return new Date().getFullYear() - birthYear;
}

export const SYSTOLIC = 'sbp';
export const DYASTOLIC = 'dbp';

export function validatePressure(gender: string, age: number, type: string): object | undefined {
  switch (gender) {
    case 'F':
      if (type === SYSTOLIC) {
        return {
          bpL: Math.round(((-1.0666E-04 * age + 2.1364E-02) * age - 7.8834E-01) * age + 1.0630E+02),
          bpH: Math.round(((-2.5013E-04 * age + 3.8604E-02) * age - 8.7373E-01) * age + 1.2143E+02)
        };
      }
      if (type === DYASTOLIC) {
        return {
          bpL: Math.round((-1.0869E-02 * age + 1.0628E+00) * age + 3.7414E+01),
          bpH: Math.round((-8.6945E-03 * age + 9.3748E-01) * age + 5.9379E+01)
        };
      }
      break;
    case 'M':
      if (type === SYSTOLIC) {
        return {
          bpL: Math.round(((+7.4757E-06 * age + 1.0617E-03) * age + 3.2936E-02) * age + 1.0571E+02),
          bpH: Math.round(((-8.6830E-05 * age + 1.5294E-02) * age - 1.9425E-01) * age + 1.2567E+02)
        };
      }
      if (type === DYASTOLIC) {
        return {
          bpL: Math.round((-1.2594E-02 * age + 1.2087E+00) * age + 3.7953E+01),
          bpH: Math.round((-1.0627E-02 * age + 1.0687E+00) * age + 6.2001E+01)
        };
      }
      break;
    default:
      break;
  }
}

const sleepR = [
  255, 255, 255, 255, 253, 202, 157, 117, 83, 54, 32, 15, 4, 0, 1, 8, 22, 42,
  68, 100, 139, 184, 236, 255,
];
const sleepG = [
  0, 70, 137, 199, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  255, 255, 255, 255, 255, 255, 255, 215,
];

const alcoholR = [
  205, 140, 88, 49, 22, 5, 0, 3, 15, 34, 61, 93, 131, 172, 217, 255, 255, 255,
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  255, 255, 255,
];
const alcoholG = [
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  251, 235, 218, 201, 185, 170, 156, 142, 129, 116, 105, 94, 84, 74, 65, 57, 49,
  42, 36, 30, 25, 20, 16, 12, 9, 6, 4, 2, 1, 0, 0, 0, 0, 1, 2, 3,
];
const glucoseR = [
  166, 136, 109, 85, 65, 47, 33, 21, 12, 5, 1, 0, 0, 3, 7, 14, 23, 33, 45, 58,
  73, 89, 106, 125, 144, 165, 186, 208, 230, 253, 255, 255, 255, 255, 255, 255,
  255, 255, 255, 255, 255,
];
const glucoseG = [
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  232, 209, 185, 161, 137, 113, 89, 66, 43, 21, 0
];

const lipidsR = [
  255, 255, 255, 255, 204, 153, 102, 76, 51, 51, 51, 102, 132, 202, 249, 255,
  255, 255, 255, 255, 255,
];
const lipidsG = [
  102, 153, 204, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  219, 188, 153, 102, 61, 25,
];

const bloodPressureR = [
  255, 255, 255, 193, 102, 72, 20, 51, 76, 110, 134, 159, 185, 211, 235, 255,
  255, 255, 255, 255, 255, 255,
];
const bloodPressureG = [
  15, 183, 214, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 252,
  233, 216, 178, 127, 76, 15,
];

const ironMetabolismR = [
  255, 255, 255, 255, 255, 255, 255, 255, 244, 224, 205, 189, 175, 166, 160,
  159, 163, 172, 187, 206, 230, 255, 255, 255, 255, 255,
];
const ironMetabolismG = [
  165, 160, 162, 171, 185, 203, 223, 244, 255, 255, 255, 255, 255, 255, 255,
  255, 255, 255, 255, 255, 255, 249, 215, 177, 134, 89,
];

export const getAllColorArray = (rColor: number[], gColor: number[]): string[] => {
  const colorArr = [];
  for (let index = 0; index < rColor.length; index += 1) {
    colorArr.push(`rgb(${rColor[index]}, ${gColor[index]}, 0)`);
  }
  return colorArr;
};

export const getColorArray = (): string[] => {
  const colorArr = ['rgb(255, 0, 0)', 'rgb(255, 153, 0)'];
  for (let i = 3; i > 0; i -= 1) {
    colorArr.push(`rgb(${i * 85}, 255, 0)`);
  }
  for (let i = 0; i < 8; i += 1) {
    colorArr.push(`rgb(${i * 31.875}, 255, 0)`);
  }
  for (let i = 17; i >= 0; i -= 1) {
    colorArr.push(`rgb(255, ${i * 15}, 0)`);
  }
  return colorArr;
};

const MIN_BMI = 15;
const MAX_BMI = 45;

// eslint-disable-next-line max-len
export const bmiFormula = (bmi: number): number => (((0.000016179 * bmi - 0.0022167) * bmi + 0.11249) * bmi - 2.4505) * bmi + 19.274;

export const getBmiColor = (value: number): string | undefined => {
  const colorArray = getColorArray();
  if (value < MIN_BMI || value >= MAX_BMI) {
    return colorArray[colorArray.length - 1];
  }
  for (let i = 0; i < colorArray.length; i += 1) {
    if (value >= i + MIN_BMI && value < i + 1 + MIN_BMI) {
      return colorArray[i];
    }
  }
};

export const getBmiCursorPosition = (value: number): number => {
  const colorRatio = 100 / (getColorArray().length - 1);
  if (value <= MIN_BMI) {
    return 0;
  }
  if (value >= MAX_BMI) {
    return 100;
  }
  return (value - MIN_BMI) * colorRatio;
};

export function normalizedValue(r: number): number {
  // eslint-disable-next-line no-restricted-properties
  return (Math.pow(Math.log(r + 1), 0.54)) / (Math.pow(Math.log(2), 0.54));
}

export const getColorArrayRiskFactors = (type: string): string[] => {
  const colorArr = [];

  switch (type) {
    case 'nonlinear':
      for (let i = 0; i < 8; i += 1) {
        colorArr.push(`rgb(255, ${i * 31}, 0)`);
      }
      for (let i = 0; i < 7; i += 1) {
        colorArr.push(`rgb(${255 - (i * 37)}, 255, 0)`);
      }
      for (let i = 0; i < 7; i += 1) {
        colorArr.push(`rgb(${i * 36}, 255, 0)`);
      }
      for (let i = 0; i < 28; i += 1) {
        colorArr.push(`rgb(255, ${255 - (i * 9)}, 0)`);
      }
      break;
    case 'inverted':
      for (let i = 0; i < 26; i += 1) {
        colorArr.push(`rgb(255, ${i * 10}, 0)`);
      }
      for (let i = 24; i > 0; i -= 1) {
        colorArr.push(`rgb(${i * 9}, 255, 0)`);
      }
      break;
    case 'alcohol':
      return getAllColorArray(alcoholR, alcoholG);
    case 'sleep':
      return getAllColorArray(sleepR, sleepG);
    case 'glucose':
      return getAllColorArray(glucoseR, glucoseG);
    case 'lipids':
      return getAllColorArray(lipidsR, lipidsG);
    case 'bloodPressure':
      return getAllColorArray(bloodPressureR, bloodPressureG);
    case 'ironMetabolism':
      return getAllColorArray(ironMetabolismR, ironMetabolismG);
    case 'phase3':
      for (let i = 0; i < 12; i += 1) {
        colorArr.push(`rgb(${i * 21.25}, 255, 0)`);
      }
      for (let i = 38; i > 0; i -= 1) {
        colorArr.push(`rgb(255, ${i * 6.7}, 0)`);
      }
      break;
    default:
      for (let i = 0; i < 25; i += 1) {
        colorArr.push(`rgb(${i * 9}, 255, 0)`);
      }
      for (let i = 25; i > 0; i -= 1) {
        colorArr.push(`rgb(255, ${i * 10}, 0)`);
      }
      break;
  }
  return colorArr;
};

export const getRiskFactorColor = (value: number, type: string): string | undefined => {
  const colorArray = getColorArrayRiskFactors(type);
  const colorRatio = 1 / colorArray.length;
  if (value < 0) {
    return colorArray[0];
  }
  if (value >= 1) {
    return colorArray[colorArray.length - 1];
  }
  for (let i = 0; i < colorArray.length; i += 1) {
    if (value >= i * colorRatio && value < (i + 1) * colorRatio) {
      return colorArray[i];
    }
  }
};

export const getRiskCursorPosition = (value: number): number => {
  if (value <= 0) {
    return 0;
  }
  if (value >= 1) {
    return 100;
  }
  return value * 100;
};

const BMI_GRADIENT = 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 6.66%, rgba(0,255,0,1) 16.66%, rgba(255,255,0,1) 43.33%, rgba(255,0,0,1) 100%)';
const LINEAR_GRADIENT = 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,255,0,1) 50%, rgba(255,0,0,1) 100%);';
const NONLINEAR_GRADIENT = 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 16%, rgba(0,255,0,1) 30%, rgba(255,255,0,1) 44%, rgba(255,0,0,1) 100%);';
const INVERTED_GRADIENT = 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 50%, rgba(0,255,0,1) 100%);';
const ALCO_GRADIENT = 'linear-gradient(90deg, rgba(205,255,0,1) 0%, rgba(140,255,0,1) 2%, rgba(0,255,0,1) 12%, rgba(255,251,0,1) 30%, rgba(255,105,0,1) 50%, rgba(255,42,0,1) 64%, rgba(255,0,0,1) 100%);';
const SLEEP_GRADIENT = 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(253,255,0,1) 17.2%, rgba(117,255,0,1) 30.1%, rgba(0,255,0,1) 55.9%, rgba(139,255,0,1) 86%, rgba(236,255,0,1) 94.6%, rgba(255,215,0,1) 100%);';
const GLUCOSE_GRADIENT = 'linear-gradient(90deg, rgba(166,255,0,1) 0%, rgba(0,255,0,1) 27.5%, rgba(106,255,0,1) 55%, rgba(253,255,0,1) 72.5%, rgba(255,0,0,1) 100%);';
const LIPIDS_GRADIENT = 'linear-gradient(90deg, rgba(255,102,0,1) 0%, rgba(255,255,0,1) 15%, rgba(51,255,0,1) 45%, rgba(249,255,0,1) 70%, rgba(255,25,0,1) 100%);';
const BLOOD_PRESURE_GRADIENT = 'linear-gradient(90deg, rgba(255,15,0,1) 0%, rgba(255,183,0,1) 5%, rgba(255,214,0,1) 10%, rgba(193,255,0,1) 15%, rgba(51,255,0,1) 45%, rgba(255,252,0,1) 75%, rgba(255,15,0,1) 100%);';
const IRON_METABOLISM_GRADIENT = 'linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(244,255,0,1) 36%, rgba(160,255,0,1) 56%, rgba(255,249,0,1) 84%, rgba(255,89,0,1) 100%);';
const PHASE3 = 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,255,0,1) 23.5294117%, rgba(255,0,0,1) 100%)';
export const switchGradient = (type: string): string => {
  switch (type) {
    case 'bmi':
      return BMI_GRADIENT;
    case 'linear':
      return LINEAR_GRADIENT;
    case 'nonlinear':
      return NONLINEAR_GRADIENT;
    case 'inverted':
      return INVERTED_GRADIENT;
    case 'alcohol':
      return ALCO_GRADIENT;
    case 'sleep':
      return SLEEP_GRADIENT;
    case 'glucose':
      return GLUCOSE_GRADIENT;
    case 'lipids':
      return LIPIDS_GRADIENT;
    case 'bloodPressure':
      return BLOOD_PRESURE_GRADIENT;
    case 'ironMetabolism':
      return IRON_METABOLISM_GRADIENT;
    case 'phase3':
      return PHASE3;
    default:
      return LINEAR_GRADIENT;
  }
};

export const switchValidatorLang = (lang: string): void => {
  if (lang.startsWith('en')) {
    Object.assign(Validators.messages, en.translations.validator);
  } else if (lang.startsWith('de')) {
    Object.assign(Validators.messages, de.translations.validator);
  } else if (lang.startsWith('tr')) {
    Object.assign(Validators.messages, tr.translations.validator);
  } else if (lang.startsWith('fr')) {
    Object.assign(Validators.messages, fr.translations.validator);
  } else {
    Object.assign(Validators.messages, ru.translations.validator);
  }
};
