import React, {useCallback, useEffect, useMemo, useState} from 'react';
import get from 'lodash/get';
import Header from 'layouts/Header';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from 'components/IconButton';
import Divider from '@material-ui/core/Divider';
import StepHeader from 'layouts/StepHeader';
import {useHistory} from 'react-router';
import {useActions, useFilters} from 'context';
import {phase2Service} from 'services/phase2Licence.service';
import {warning} from 'helpers/logger';
import useGetPatientData, {useGetRiskFactorsData} from 'helpers/hooks/useGetPatientData';
import {getAge} from 'helpers/utils';
import {CHOLESTEROL_CONVERSION} from 'services/constants';
import Filters from './Filters';
import Chart from './Chart';
import useStyles from './styles';

const DrillDownGraph = () => {
  const classes = useStyles();
  const {setFilters} = useActions();
  const filters = useFilters();
  const [data, setData] = useState([]);
  const history = useHistory();
  const patientData = useGetPatientData();
  const riskFactorsData = useGetRiskFactorsData();

  const handleClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const initialValues = useMemo(() => {
    const age = getAge(get(patientData, 'birthYear') || 52);
    return {
      age,
      weight: get(patientData, 'weight') || 74,
      gender: get(patientData, 'gender') || 'M',
      nyhaClass: 4,
      ejectionFraction: 30,
      ischemicEtiology: true,
      sbp: get(patientData, 'bloodPressureSystolic') || get(riskFactorsData, 'bloodPressureSystolic.value'), // imputed
      sodium: 140,
      totalCholesterol: 2.97,
      hemoglobin: 10,
      lymphocytes: 0.24,
      uricACid: 6.5,
      aceInhibitor: '0',
      bBlocker: '0',
      arb: '0',
      ksparingDiuretic: '0',
      allopurinolUse: '0',
      statinUse: '0',
      biventricularPacemaker: '0',
      icd: '0',
      biventricularIcd: '0',
      years: 10
    };
  }, [patientData, riskFactorsData]);

  useEffect(() => {
    if (!filters.graphValues) {
      const normalizedValues = {
        ...initialValues,
        totalCholesterol: initialValues.totalCholesterol / CHOLESTEROL_CONVERSION
      };
      setFilters({key: 'graphValues', data: normalizedValues});
    } else {
      const normalizedData = {
        ...filters.graphValues,
        age: Number(get(filters, 'graphValues.age', 0)),
        weight: Number(get(filters, 'graphValues.weight', 0)),
        nyhaClass: Number(get(filters, 'graphValues.nyhaClass', 0)),
        ejectionFraction: Number(get(filters, 'graphValues.ejectionFraction', 0)),
        sbp: Number(get(filters, 'graphValues.sbp', 0)),
        sodium: Number(get(filters, 'graphValues.sodium', 0)),
        totalCholesterol: Number(get(filters, 'graphValues.totalCholesterol', 0)),
        hemoglobin: Number(get(filters, 'graphValues.hemoglobin', 0)),
        lymphocytes: Number(get(filters, 'graphValues.lymphocytes', 0)),
        uricACid: Number(get(filters, 'graphValues.uricACid', 0)),
        diureticDose: Number(get(filters, 'graphValues.diureticDose')),
        aceInhibitor: !!Number(get(filters, 'graphValues.aceInhibitor', false)),
        bBlocker: !!Number(get(filters, 'graphValues.bBlocker', false)),
        arb: !!Number(get(filters, 'graphValues.arb', false)),
        ksparingDiuretic: !!Number(get(filters, 'graphValues.ksparingDiuretic', false)),
        allopurinolUse: !!Number(get(filters, 'graphValues.allopurinolUse', false)),
        statinUse: !!Number(get(filters, 'graphValues.statinUse', false)),
        biventricularPacemaker: !!Number(get(filters, 'graphValues.biventricularPacemaker', false)),
        icd: !!Number(get(filters, 'graphValues.icd', false)),
        biventricularIcd: !!Number(get(filters, 'graphValues.biventricularIcd', false)),
      };
      phase2Service.graph(normalizedData)
        .then((res) => {
          setData(res.items);
        })
        .catch(warning);
    }
  }, [filters, setFilters, initialValues]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <StepHeader title="pageHeader.СHD" />
        <Box mt={2}>
          <Grid container mt={2}>
            <Grid item xs={12} sm={4} className={classes.filterWrapper}>
              <Filters initialValues={initialValues} />
            </Grid>
            <Grid item xs={12} sm={8} className={classes.graphWrapper}>
              <Box mt={4} className={classes.chart}>
                <Chart chartData={data} />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Divider />
        <Box marginTop="30px" marginBottom="30px">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <IconButton color="primary" type="submit" onClick={handleClick}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>

  );
};

export default DrillDownGraph;
