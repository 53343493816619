import {makeUrl} from 'helpers/url';
import {basePut, basePost} from 'services/base.service';
import {IPatientData} from './dto/IPatientData';

const SERVICE_URL = 'api/phase1';

const getUrl = (): string => makeUrl(SERVICE_URL);

function createPhase1(data: IPatientData): Promise<void> {
  return basePut(getUrl(), data);
}

function updatePhase1(data: IPatientData): Promise<void> {
  return basePost(getUrl(), data);
}
export const phase1Service = {
  createPhase1,
  updatePhase1
};
