import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import * as d3 from 'd3';

const STROKE_WIDTH = 'stroke-width';

const SliderD3 = ({engineMin, engineMax, guidelineMin, guidelineMax, ciMin, ciMax, measured}) => {
  const d3Container = useRef(null);
  const checkMinMax = (value, min, max) => {
    if (value && value < min) {
      return min;
    }
    if (value && value > max) {
      return max;
    }
    return value;
  };
  const health = g => {
    const totalWidth = 145;
    const skipStartPixel = 22;
    const line = d3.line()([
      [skipStartPixel, -3],
      [totalWidth + skipStartPixel, -3]
    ]);
    const minPixel = skipStartPixel;
    const maxPixel = skipStartPixel + totalWidth;
    const scaleInPixel = totalWidth / (engineMax - engineMin);

    const guidelineMinPixel =
      checkMinMax(skipStartPixel + (guidelineMin - engineMin) * scaleInPixel, minPixel, maxPixel);
    const guidelineMaxPixel =
      checkMinMax(skipStartPixel + (guidelineMax - engineMin) * scaleInPixel, minPixel, maxPixel);
    const ciMinPixel = checkMinMax(skipStartPixel + (ciMin - engineMin) * scaleInPixel, minPixel, maxPixel);
    const ciMaxPixel = checkMinMax(skipStartPixel + (ciMax - engineMin) * scaleInPixel, minPixel, maxPixel);

    let measuredPixel = skipStartPixel + (measured - engineMin) * scaleInPixel;
    if (measuredPixel < minPixel || measuredPixel > maxPixel) {
      measuredPixel = -10;
    }
    const measuredLine = d3.line()([[measuredPixel, 5], [measuredPixel, -11]]);
    const inGuidelines = !!(measured >= guidelineMin && measured <= guidelineMax);
    const measuredLineColor = inGuidelines ? 'green' : 'red';

    g.append('path')
      .attr('d', line)
      .attr('stroke', 'gray')
      .attr(STROKE_WIDTH, 0.5);

    g.append('rect')
      .attr('height', 16)
      .attr('width', guidelineMaxPixel - guidelineMinPixel)
      .attr('x', guidelineMinPixel)
      .attr('y', -11)
      .attr('fill', 'green')
      .attr('fill-opacity', 0.2);

    g.append('rect')
      .attr('height', 10)
      .attr('width', ciMaxPixel > ciMinPixel ? ciMaxPixel - ciMinPixel : ciMinPixel - ciMaxPixel)
      .attr('x', ciMaxPixel > ciMinPixel ? ciMinPixel : ciMaxPixel)
      .attr('y', -8)
      .attr('fill', 'blue')
      .attr('fill-opacity', 0.3);

    if (measured) {
      g.append('path')
        .attr('d', measuredLine)
        .attr('stroke', measuredLineColor)
        .attr('fill', 'none')
        .attr(STROKE_WIDTH, 2);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const legend = l => {
    l.call(g => g
      .append('text')
      .attr('x', '0')
      .attr('y', '0')
      .attr('font-size', '10px')
      .text(engineMin)
      .style('fill', 'gray'));
    l.call(g => g
      .append('text')
      .attr('x', '170')
      .attr('y', '0')
      .attr('font-size', '10px')
      .text(engineMax)
      .style('fill', 'gray'));
  };

  useEffect(
    () => {
      if (d3Container.current) {
        const svg = d3.select(d3Container.current)
          .attr('viewBox', '0 -20 200 30')
          .style('width', '100%')
          .style('height', 'auto')
          .style('font', '10px sans-serif');

        svg.selectAll('*').remove();

        svg.append('g')
          .call(health);
        svg.append('g')
          .call(legend);
      }
    }
  );
  return (
    <svg
      className="d3-component"
      ref={d3Container}
    />
  );
};
SliderD3.propTypes = {
  engineMin: PropTypes.number.isRequired,
  engineMax: PropTypes.number.isRequired,
  guidelineMin: PropTypes.number,
  guidelineMax: PropTypes.number,
  ciMin: PropTypes.number,
  ciMax: PropTypes.number,
  measured: PropTypes.number,
};

SliderD3.defaultProps = {
  guidelineMin: 0,
  guidelineMax: 0,
  ciMin: 0,
  ciMax: 0,
  measured: 0,
};

export default SliderD3;
