import React, {useCallback, useEffect, useState} from 'react';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import Header from 'layouts/Header';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';
import IconButton from 'components/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import RiskFactorGradient from 'components/RiskFactorGradient';
import StepHeader from 'layouts/StepHeader';
import {useHistory} from 'react-router';
import {normalizedValue} from 'helpers/utils';
import {phase2Service} from 'services/phase2Licence.service';
import useGetPatientData from 'helpers/hooks/useGetPatientData';
import {warning} from 'helpers/logger';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

function normalizedData(data) {
  const {diabetesMellitus, heartFailure, hypertension,
    kidneyDisease, liverDisease, metabolicDysfunction, totalCancer} = data;
  const riskArray = [
    {name: 'morbidity.diagnosedDiabet', refVal: diabetesMellitus.refVal, value: diabetesMellitus.value},
    {name: 'morbidity.diagnosedHypertension', refVal: hypertension.refVal, value: hypertension.value},
    {name: 'morbidity.kidneyDesease', refVal: kidneyDisease.refVal, value: kidneyDisease.value},
    {name: 'risks.liver', refVal: liverDisease.refVal, value: liverDisease.value},
    {name: 'risks.metabolicDysfunction', refVal: metabolicDysfunction.refVal, value: metabolicDysfunction.value},
    {name: 'risks.heartFailure', refVal: heartFailure.refVal, value: heartFailure.value},
    {name: 'risks.totalCancer', refVal: totalCancer.refVal, value: totalCancer.value},
  ];
  return orderBy(riskArray, ['value'], ['desc']);
}


const MorbidityRisks = () => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const history = useHistory();
  const userData = useGetPatientData();
  const {t} = useTranslation();

  useEffect(() => {
    phase2Service.diseaseRisks({...userData, years: 5})
      .then((res) => {
        setData(normalizedData(res));
      })
      .catch(warning);
  }, [userData]);

  const handlePrev = useCallback(() => {
    if (userData.patientPhase) {
      const params = {
        pathname: '/phase2',
        state: {toLastPage: true}
      };
      history.push(params);
    } else {
      history.push('/riskfactorsummary');
    }
  }, [history, userData.patientPhase]);

  const handleNext = useCallback(() => {
    if (userData.patientPhase) {
      history.push('/export');
    } else {
      history.push('/mortrisks');
    }
  }, [history, userData.patientPhase]);

  const handlePlus = useCallback(() => {
    history.push('/plus');
  }, [history]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <StepHeader title="pageHeader.precursorRisks" subtitle={t('risks.timeHorizon', {time: 5})} />
        <Box mt={2}>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={10} />
            <Grid item xs={2}>
              <Typography variant="h5" align="right">{t('risks.riskRatio')}</Typography>
            </Grid>
          </Grid>
        </Box>
        {data && (
        <Box mt={2}>
          {data.map((el => (
            <RiskFactorGradient
              key={el.name}
              title={el.name}
              value={normalizedValue(el.value)}
              refVal={normalizedValue(el.value / el.refVal)}
              type="phase3"
              visibleValue={round(el.value, 2)}
            />
          )))}
        </Box>
        )}
        <Divider />
        <Box marginTop="30px" marginBottom="30px">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <IconButton color="primary" onClick={handlePrev}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleNext}>
                {userData.patientPhase ? (<DoneIcon />) : (<ArrowForwardIcon />)}
              </IconButton>
            </Grid>
            {userData.patientPhase && (
              <Grid item>
                <IconButton color="primary" onClick={handlePlus}>
                  <AddIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>

  );
};

export default MorbidityRisks;
