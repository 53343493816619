import round from 'lodash/round';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import isNil from 'lodash/isNil';

export function getPackYears(data) {
  if (!data.smkNum) {
    return '';
  }
  const endDate = data.smkQuitYear || new Date().getFullYear();
  return ((endDate - data.smkStartYear) * data.smkNum) / 20;
}

const isNumber = (val1, val2) => toNumber(val1) && toNumber(val2);

const inch = 2.54;
const pound = 0.45359237;

export const calculateBMI = ({height, weight, heightUnit, weightUnit}) => {
  if (isNumber(weight, height)) {
    const normalizedHeight = get(heightUnit, 'value') === 2 ? height * inch : height;
    const normalizedWeight = get(weightUnit, 'value') === 2 ? weight * pound : weight;
    return round((((normalizedWeight / normalizedHeight) / normalizedHeight) * 10000), 2);
  }
  return 0;
};

export const calculateCholesterolToHdl = ({lipidTotalCholesterol, highDensityLiprotein}) => {
  if (isNumber(lipidTotalCholesterol, highDensityLiprotein)) {
    return round((lipidTotalCholesterol / highDensityLiprotein), 2);
  }
  return null;
};

export const SUBMIT = 'submit';
export const INIT = 'initial values';

export function normalizeSlider(value, type) {
  if (isNil(value)) {
    return 0;
  }
  return type === SUBMIT ? value / 7 : value * 7;
}

export function normalizeRadio(value) {
  if (isNil(value)) {
    return null;
  }
  return String(value);
}
