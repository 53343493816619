import {makeStyles} from '@material-ui/core/styles';

import {
  colorHeader
} from 'theme/variables';

export default makeStyles(theme => ({
  // контейнер для формы создания (без табов)
  header: {
    borderBottom: 'none',
    backgroundColor: 'transparent'
  },
  headerWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerInstuctions: {
    textDecoration: 'underline',
    color: colorHeader
  },
  headerHelp: {
    color: colorHeader,
    '& span': {
      fontWeight: 600,
      textTransform: 'uppercase'
    }
  },
  headerIcon: {
    marginRight: '8px'
  },
  pageLogo: {
    maxWidth: '220px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0px'
    },
  },
  linksWrapper: {
    marginRight: '-15px'
  },
  langIconWrapper: {
    padding: 0
  },
  langIcon: {
    width: '24px',
    height: '24px',
  }
}));
