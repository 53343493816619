import React from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import styled from 'styled-components';
import {numericality} from 'redux-form-validators';
import {Field} from 'react-final-form';
import {StyledBox as Box, NumberFormatCustom} from 'components/form/NumberField';
import {FormControl} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {getBmiColor} from 'helpers/utils';
import BmiGradient from 'components/BmiGradient';

const GREEN = 'green';
const RED = 'red';

function switchColor(value, name, validation) {
  switch (name) {
    case 'bloodPressureSystolic':
      return value >= validation.bpL && value <= validation.bpH ? GREEN : RED;
    case 'bloodPressureDiastolic':
      return value >= validation.bpL && value <= validation.bpH ? GREEN : RED;
    case 'lipidTotalCholesterol':
      return value < 5 ? GREEN : RED;
    case 'highDensityLiprotein':
      return value > 1 ? GREEN : RED;
    case 'lowDensityLiprotein':
      return value < 3 ? GREEN : RED;
    case 'ratioOfTotalCholesterolToHdl':
      return value < 5 ? GREEN : RED;
    case 'triglycerides':
      return value < 2 ? GREEN : RED;
    case 'fastingBloodGlucose':
      return value >= 3.5 && value <= 5.6 ? GREEN : RED;
    case 'glycatedHemoglobin':
      return value >= 4.4 && value <= 5.7 ? GREEN : RED;
    case 'cReactiveProtein':
      return value < 5 ? GREEN : RED;
    case 'alanineAminotransferase':
      return value < 50 ? GREEN : RED;
    case 'aspartateAminotransferase':
      return value < 50 ? GREEN : RED;
    case 'gammaGlutamylTransferase':
      return value < 60 ? GREEN : RED;
    case 'glomerularFiltrationRate':
      return value > 60 ? GREEN : RED;
    case 'albuminCreatinineUrineRatio':
      return value < 1 ? GREEN : RED;
    case 'bmi':
      return getBmiColor(value);
    default:
      return '#849CA3';
  }
}

const StyledBox = styled(Box)`
  .MuiInputBase-root {
    &:after {
      content: '';
      width: 8px;
      height: 40px;
      display: block;
      background-color: ${({inputvalue, inputname, validation}) => (inputvalue !== '' ? switchColor(inputvalue, inputname, validation) : 'transparent')};
      position: absolute;
      right: -15px;
    }
  }
`;

const useStyles = makeStyles({
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center'
  }

});

const MetabolicTextField = ({input, meta: {error, touched}, decimalScale, validation, ...rest}) => {
  const classes = useStyles();
  return (
    <FormControl
      error={touched && !!error}
      className={input.name === 'bmi' ? classes.flexRow : ''}
    >
      <StyledBox
        error={touched && !!error}
        inputvalue={input.value}
        inputname={input.name}
        validation={validation}
      >
        <OutlinedInput
          inputComponent={NumberFormatCustom}
          inputProps={{
            decimalScale: isNull(decimalScale) ? undefined : decimalScale,
            inputMode: decimalScale === 0 ? 'numeric' : 'decimal',
          }}
          {...input}
          {...rest}
        />
      </StyledBox>
      {input.name === 'bmi' && (
        <BmiGradient inputvalue={input.value} />
      )}
    </FormControl>
  );
};

MetabolicTextField.defaultProps = {
  decimalScale: 2,
};

MetabolicTextField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  decimalScale: PropTypes.number,
  validation: PropTypes.object
};

const FormField = props => <Field
  component={MetabolicTextField}
  size="medium"
  validate={numericality({allowBlank: true})}
  {...props}
/>;

FormField.propTypes = {
  name: PropTypes.string.isRequired
};

export default MetabolicTextField;

export {FormField};
