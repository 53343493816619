import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

const App = ({children}) => (
  <Suspense fallback="Загрузка">
    {children}
  </Suspense>
);

App.propTypes = {
  children: PropTypes.node.isRequired
};

export default App;
