import {makeStyles} from '@material-ui/core/styles';

import {
  pageHeaderHeight,
  pageFooterHeight
} from 'theme/variables';

export default makeStyles(() => ({

  pageContent: {
    minHeight: `calc(100vh - ${pageFooterHeight}px)`,
    paddingTop: pageHeaderHeight,
  },
  gradient: {
    width: '100%',
    height: '30px',
    backgroundImage: 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,255,0,1) 23.5294117%, rgba(255,0,0,1) 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px'
  },
}));
