import React, {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import Header from 'layouts/Header';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from 'components/IconButton';
import Divider from '@material-ui/core/Divider';
import TranslatedText from 'components/TranslatedText';
import DeseaseRisksGraph from 'components/DeseaseRisksGraph';
import StepHeader from 'layouts/StepHeader';
import {Form} from 'react-final-form';
import {useActions, useFilters} from 'context';
import {useHistory} from 'react-router';
import {phase2Service} from 'services/phase2Licence.service';
import useGetPatientData, {useGetCurrentUserConfigs} from 'helpers/hooks/useGetPatientData';
import {warning} from 'helpers/logger';
import {normalizedValue} from 'helpers/utils';
import {useTranslation} from 'react-i18next';
import Filters from './Filters';
import useStyles from './styles';

function normalizedData(data, sortingOrder) {
  const {diabetesMellitus, heartFailure, hypertension,
    kidneyDisease, liverDisease, metabolicDysfunction, totalCancer} = data;
  const arr = [
    {name: 'risks.diabetesMellitus2', risk: diabetesMellitus.value, ciMin: diabetesMellitus.ciMin, ciMax: diabetesMellitus.ciMax, refVal: diabetesMellitus.refVal},
    {name: 'morbidity.diagnosedHypertension', risk: hypertension.value, ciMin: hypertension.ciMin, ciMax: hypertension.ciMax, refVal: hypertension.refVal},
    {name: 'morbidity.kidneyDesease', risk: kidneyDisease.value, ciMin: kidneyDisease.ciMin, ciMax: kidneyDisease.ciMax, refVal: kidneyDisease.refVal},
    {name: 'risks.liver', risk: liverDisease.value, ciMin: liverDisease.ciMin, ciMax: liverDisease.ciMax, refVal: liverDisease.refVal},
    {name: 'risks.metabolicDysfunction', risk: metabolicDysfunction.value, ciMin: metabolicDysfunction.ciMin, ciMax: metabolicDysfunction.ciMax, refVal: metabolicDysfunction.refVal},
    {name: 'risks.heartFailure', risk: heartFailure.value, ciMin: heartFailure.ciMin, ciMax: heartFailure.ciMax, refVal: heartFailure.refVal},
    {name: 'risks.totalCancer', risk: totalCancer.value, ciMin: totalCancer.ciMin, ciMax: totalCancer.ciMax, refVal: totalCancer.refVal},
  ];
  return sortingOrder ? arr.sort((a, b) => sortingOrder.indexOf(a.name) - sortingOrder.indexOf(b.name)) : arr;
}

const getInitialRisk = (initialData, name) => {
  if (initialData) {
    const intialRisk = initialData.find(item => item.name === name);
    return normalizedValue(intialRisk.risk);
  }
  return null;
};

const SUBMIT = 'submit';
const INIT = 'initial values';

function normalizeSlider(value, type) {
  if (isNil(value)) {
    return 0;
  }
  return type === SUBMIT ? value / 7 : value * 7;
}

const onSubmit = () => undefined;

const PlusRisks = () => {
  const classes = useStyles();
  const filters = useFilters();
  const {setFilters} = useActions();
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const history = useHistory();
  const intervalRef = useRef();
  const didMountRef = useRef(false);
  const userData = useGetPatientData();
  const userConfigs = useGetCurrentUserConfigs();
  const {t} = useTranslation();

  useEffect(() => {
    if (!filters.plusFilters) {
      setFilters({key: 'plusFilters', data: JSON.parse(sessionStorage.getItem('patient'))});
    }
    const newFormData = !didMountRef.current ? {...filters.plusFilters, years: 5} : {
      ...filters.plusFilters,
      nutFruit: normalizeSlider(get(filters.plusFilters, 'nutFruit'), SUBMIT),
      nutVegetables: normalizeSlider(get(filters.plusFilters, 'nutVegetables'), SUBMIT),
      nutRedMeat: normalizeSlider(get(filters.plusFilters, 'nutRedMeat'), SUBMIT),
      nutChicken: normalizeSlider(get(filters.plusFilters, 'nutChicken'), SUBMIT),
      nutFish: normalizeSlider(get(filters.plusFilters, 'nutFish'), SUBMIT),
      years: 5
    };
    clearTimeout(intervalRef.current);
    const timer = setTimeout(() => {
      phase2Service.diseaseRisks(newFormData)
        .then((res) => {
          if (!didMountRef.current) {
            const firstData = normalizedData(res).sort((a, b) => b.risk - a.risk);
            setData(firstData);
            setInitialData(firstData);
            setSortingOrder(firstData.map(el => el.name));
            didMountRef.current = true;
          } else {
            setData(normalizedData(res, sortingOrder));
          }
          if (isReset) {
            setIsReset(false);
          }
        })
        .catch(warning);
    }, 500);
    intervalRef.current = timer;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, setFilters, isReset]);

  const handlePrev = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleFinish = useCallback(() => {
    const reportLink = get(userConfigs, 'config.client') !== 'base' ? '/patientResult' : '/phase2Report';
    history.push(reportLink);
  }, [history, userConfigs]);

  const initialValues = useMemo(() => ({
    ...userData,
    nutFruit: normalizeSlider(userData.nutFruit, INIT),
    nutVegetables: normalizeSlider(userData.nutVegetables, INIT),
    nutRedMeat: normalizeSlider(userData.nutRedMeat, INIT),
    nutChicken: normalizeSlider(userData.nutChicken, INIT),
    nutFish: normalizeSlider(userData.nutFish, INIT),
    years: 5,
    smoking: userData.smkNow ? '0' : null
  }), [userData]);

  const handleReset = useCallback((form) => {
    form.reset();
    setIsReset(true);
  }, [setIsReset]);

  return (
    <>
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <StepHeader title="pageHeader.plusRisks" subtitle={t('risks.timeHorizon', {time: 5})} />
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({handleSubmit, form}) => (
            <Box mt={2} mb={2} position="relative">
              <Grid container mt={2} spacing={4}>
                <Grid item xs={12} className={classes.tableWrapper}>
                  <Box className={classes.table}>
                    <Box mb={1}>
                      <Grid container spacing={2} justify="center" alignItems="center">
                        <Grid item xs={4}>
                          <Button
                            size="medium"
                            variant="outlined"
                            className={classes.resetBtn}
                            onClick={() => handleReset(form)}
                          >
                            {t('plusRisks.chart.reset')}
                          </Button>
                        </Grid>
                        <Grid item xs={8}>
                          <div className={classes.gradient} />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2} mb={1}>
                      {data.map(row => (
                        <Grid key={row.name} container spacing={2} justify="center" alignItems="center">
                          <Grid item xs={4}>
                            <TranslatedText text={row.name} />
                          </Grid>
                          <Grid item xs={8}>
                            <DeseaseRisksGraph
                              engineMin={0}
                              engineMax={1}
                              ciMin={normalizedValue(row.ciMin)}
                              ciMax={normalizedValue(row.ciMax)}
                              measured={normalizedValue(row.risk)}
                              initialMeasured={getInitialRisk(initialData, row.name)}
                              refVal={normalizedValue(row.risk / row.refVal)}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                    <Box mt={2}>
                      <Grid container mt={2} spacing={4}>
                        <Grid item>
                          <Grid container alignItems="baseline">
                            <span className={classes.ageGender} />
                            <Typography>- {t('plusRisks.chart.averageValue')}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="center">
                            <span className={classes.baselineValue} />
                            <Typography>- {t('plusRisks.chart.baseLineValue')}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.filterWrapper}>
                  <Filters
                    initialValues={initialValues}
                    weight={userData.weight}
                    isReset={isReset}
                    handleSubmit={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        />

        <Divider />
        <Box marginTop="30px" marginBottom="30px">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <IconButton color="primary" onClick={handlePrev}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleFinish}>
                <DoneIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>

  );
};

export default PlusRisks;
