import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepWrapper: {
    padding: theme.spacing(4, 0),
  },
  stepWrapperWithoutDivider: {
    paddingBottom: 0,
  },
}));
