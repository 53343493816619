import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {FormField as Slider} from 'components/form/Slider';
import StepHeader from 'layouts/StepHeader';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import {marks} from 'services/constants';
import tea from 'assets/images/tea.png';
import coffee from 'assets/images/coffee.png';
import useStyles from '../styles';

const СoffeAndTea = () => {
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.coffeAndTea" />
      <PhaseOneLayout title="pageOneQuestions.coffeAndTea">
        <Grid container spacing={4} item xs={12} sm={6}>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={coffee} alt="Coffe" title="Coffe" className={classes.sliderImage} />
              <Slider
                name="coffePerDay"
                min={0}
                step={1}
                max={6}
                marks={marks.coffeeTeaMarks}
                withInput
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={tea} alt="Tea" title="Tea" className={classes.sliderImage} />
              <Slider
                name="teaPerDay"
                min={0}
                step={1}
                max={6}
                marks={marks.coffeeTeaMarks}
                withInput
              />
            </Box>
          </Grid>
        </Grid>
      </PhaseOneLayout>
    </>
  );
};

export default СoffeAndTea;
