import React from 'react';
import {Box, Container, Grid, Typography} from '@material-ui/core';
import pdfIcon from 'assets/images/pdf.svg';
import {useTranslation} from 'react-i18next';
import useGetPatientData from 'helpers/hooks/useGetPatientData';
import useStyles from './styles';

const Export = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const userData = useGetPatientData();
  return (
    <div className={classes.exportBack}>
      <Container maxWidth="sm">
        <Box className={classes.exportWrapper}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box className={classes.alertLink}>
                <img src={pdfIcon} alt="" className={classes.pdfIcon} />
                <Typography variant="body1" className={classes.alertP}>
                  {t('export.patient')}
                </Typography>
              </Box>
            </Grid>
            {!userData.patientPhase && (
            <Grid item xs={12}>
              <Box className={classes.alertLink}>
                <img src={pdfIcon} alt="" className={classes.pdfIcon} />
                <Typography variant="body1" className={classes.alertP}>
                  {t('export.physician')}
                </Typography>
              </Box>
            </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Export;
