import React, {useCallback} from 'react';
import clsx from 'clsx';
import {Box, Button, Container, Typography} from '@material-ui/core';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import useGetPatientData from 'helpers/hooks/useGetPatientData';
import useStyles from './styles';

const CombinedPhaseResult = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const data = useGetPatientData();

  const handleNext = useCallback(() => {
    history.push('/phase2Report');
  }, [history]);

  return (
    <div className={clsx(classes.resultSuccess, classes.resultBack)}>

      <Container maxWidth="sm">
        <Box mb={4}>
          <Typography variant="h2" align="center">
            {`${t('combinedPhaseResult.id')}: ${data.code}`}
          </Typography>
        </Box>
        <Box className={classes.resultWrapper} mb={6}>
          <Typography variant="body1" className={classes.successP}>
            {t('combinedPhaseResult.info')}
          </Typography>
        </Box>
        <Box mb={2} mt={2} textAlign="center">
          <Button
            onClick={handleNext}
            color="primary"
            variant="outlined"
          >
            {t('combinedPhaseResult.report')}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default CombinedPhaseResult;
