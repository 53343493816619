import React from 'react';
import {Box, Grid} from '@material-ui/core';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import {FormField as Slider} from 'components/form/PhaseOneSlider';
import StepHeader from 'layouts/StepHeader';
import {marks} from 'services/constants';
import fish from 'assets/images/fishNew.png';
import poultry from 'assets/images/poultry.png';
import redMeat from 'assets/images/red_meat.png';
import useStyles from '../styles';

const Nutrition2 = () => {
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.nutrition" />
      <PhaseOneLayout title="pageOneQuestions.fishMeat">
        <Grid container spacing={4} item xs={12} sm={6}>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={redMeat} alt="Red Meat" title="Red Meat" className={classes.sliderImage} />
              <Slider
                name="nutRedMeat"
                min={0}
                step={1}
                max={14}
                marks={marks.redMeatMarks}
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={poultry} alt="Poultry" title="Poultry" className={classes.sliderImage} />
              <Slider
                name="nutChicken"
                min={0}
                step={1}
                max={14}
                marks={marks.redMeatMarks}
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={fish} alt="Fish" title="Fish" className={classes.sliderImage} />
              <Slider
                name="nutFish"
                min={0}
                step={1}
                max={14}
                marks={marks.fishMarks}
              />
            </Box>
          </Grid>
        </Grid>
      </PhaseOneLayout>
    </>
  );
};

export default Nutrition2;
