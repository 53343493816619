import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import Wizard from 'components/Wizard';
import BasicData from './BasicData';
import LifeStyle from './LifeStyle';
import MorbidityFamily from './MorbidityFamily';
import MetabolicMetrics from './MetabolicMetrics';
import calculator from './formDecorator';

const Form = ({initialValues, onSubmit, patientPhase}) => {
  const {state = {}} = useLocation();
  return (
    <Wizard
      onSubmit={onSubmit}
      initialValues={initialValues}
      calculator={calculator}
      toLastPage={get(state, 'toLastPage', false)}
    >
      <Wizard.Page>
        <BasicData />
      </Wizard.Page>
      <Wizard.Page>
        <LifeStyle patientPhase={patientPhase} />
      </Wizard.Page>
      <Wizard.Page>
        <MorbidityFamily />
      </Wizard.Page>
      <Wizard.Page>
        <MetabolicMetrics />
      </Wizard.Page>
    </Wizard>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  patientPhase: PropTypes.bool,
};

export default Form;
