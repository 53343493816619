import createDecorator from 'final-form-calculate';

const calculator = createDecorator(
  {
    field: 'smkNow',
    updates: {
      smkEver: (smkNowValue, allValues) => {
        if (`${smkNowValue}` === '1') {
          return '0';
        }
        return allValues.smkEver;
      }
    }
  },
);

export default calculator;
