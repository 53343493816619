import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Form, FormSpy} from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {FormField as SwitchField} from 'components/form/SwitchField';
import {FormField as TextField} from 'components/form/TextField';
import {FormField as NumberField} from 'components/form/NumberField';
import {numericality, required} from 'redux-form-validators';
import composeValidators from 'helpers/composeValidators';
import RadioButtonGroupField from 'components/form/RadioButtonGroup';
import HorizontalFormItem from 'layouts/FormItem/Horizontal';
import {useActions} from 'context';
import {yesNoRadio, genderFieldConfig, CHOLESTEROL_CONVERSION} from 'services/constants';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

const onSubmit = () => undefined;

const Filters = ({initialValues}) => {
  const {setFilters} = useActions();
  const classes = useStyles();
  const {t} = useTranslation();
  const handleSpyOnChange = useCallback(
    ({values, valid}) => {
      if (valid) {
        const normalizedValues = {
          ...values,
          totalCholesterol: values.totalCholesterol / CHOLESTEROL_CONVERSION
        };
        setFilters({key: 'graphValues', data: normalizedValues});
      }
    }, [setFilters]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box mt={2} mb={2} mr={2}>
            <Box mb={1}>
              <Typography variant="h3">{t('drillDownGraph.filters.data')}</Typography>
            </Box>
            <Grid container spacing={2} alignItems="center">
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.age">
                <TextField
                  name="age"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                  disabled
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.gender">
                <RadioButtonGroupField
                  name="gender"
                  items={genderFieldConfig}
                  type="radio"
                  validate={required()}
                  disabled
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.weight">
                <TextField
                  name="weight"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.nyhaClass">
                <TextField
                  name="nyhaClass"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.ejectionFraction">
                <TextField
                  name="ejectionFraction"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <Grid item xs={12}>
                <SwitchField
                  name="ischemicEtiology"
                  label="drillDownGraph.filters.ischemicEtiology"
                  color="primary"
                  fullWidthSwitch
                  validate={required()}
                />
              </Grid>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.sbp">
                <NumberField
                  name="sbp"
                  margin="normal"
                  variant="outlined"
                  size="medium"
                  parseToNumber
                  decimalScale={0}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                  className={classes.numField}
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.sodium">
                <TextField
                  name="sodium"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.totalCholesterol">
                <TextField
                  name="totalCholesterol"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.hemoglobin">
                <TextField
                  name="hemoglobin"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.lymphocytes">
                <TextField
                  name="lymphocytes"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.uricACid">
                <TextField
                  name="uricACid"
                  variant="outlined"
                  className={classes.numField}
                  validate={composeValidators(required(), numericality())}
                  showErrorOnRender
                />
              </HorizontalFormItem>
            </Grid>
          </Box>
          <Divider />
          <Box mt={2} mr={2}>
            <Box mb={1}>
              <Typography variant="h3">{t('drillDownGraph.filters.intervention')}</Typography>
            </Box>
            <Grid container spacing={2} alignItems="center">
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.diureticDose">
                <TextField
                  name="diureticDose"
                  variant="outlined"
                  className={classes.numField}
                  validate={numericality({allowBlank: true})}
                  showErrorOnRender
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.aceInhibitor">
                <RadioButtonGroupField
                  name="aceInhibitor"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.bBlocker">
                <RadioButtonGroupField
                  name="bBlocker"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.arb">
                <RadioButtonGroupField
                  name="arb"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.ksparingDiuretic">
                <RadioButtonGroupField
                  name="ksparingDiuretic"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.allopurinolUse">
                <RadioButtonGroupField
                  name="allopurinolUse"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.statinUse">
                <RadioButtonGroupField
                  name="statinUse"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.biventricularPacemaker">
                <RadioButtonGroupField
                  name="biventricularPacemaker"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.icd">
                <RadioButtonGroupField
                  name="icd"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
              <HorizontalFormItem isSpaceBetween label="drillDownGraph.filters.biventricularIcd">
                <RadioButtonGroupField
                  name="biventricularIcd"
                  items={yesNoRadio}
                  type="radio"
                />
              </HorizontalFormItem>
            </Grid>
          </Box>
          <FormSpy
            subscription={{values: true, valid: true}}
            onChange={handleSpyOnChange}
          />
        </form>
      )}
    />
  );
};

Filters.propTypes = {
  initialValues: PropTypes.object,
};

export default Filters;
