/* eslint-disable max-len */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Typography} from '@material-ui/core';
import {exportToPdf} from './utils';
import useStyles from './styles';

const Report = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const phase1Data = JSON.parse(sessionStorage.getItem('phase1'));
  return (
    <Box>
      <Button
        onClick={() => exportToPdf(t, phase1Data.needMedicine)}
        color="primary"
        variant="outlined"
        size="small"
      >
        {t('phase1Report.pdfBtn')}
      </Button>
      <Box mb={2}>
        <Typography variant="h2">{t('phase1Report.header')}</Typography>
        {phase1Data.needMedicine ? (
          <>
            <Typography variant="body2">{t('phase1Report.needMedicineYes1')}</Typography>
            <Typography variant="body2">{t('phase1Report.needMedicineYes2')}</Typography>
          </>
        ) : (
          <>
            <Typography variant="body2">{t('phase1Report.needMedicineNo1')}</Typography>
            <Typography variant="body2">{t('phase1Report.needMedicineNo2')}</Typography>
          </>
        )}
      </Box>
      <Box>
        <Typography variant="h2">{t('phase1Report.generalRecommendationTitle')}</Typography>
        <Typography variant="body2">{t('phase1Report.generalRecommendationSubTitle')}</Typography>
        <ol className={classes.reportOl}>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase1Report.point1Title')}</Typography>
              <Typography variant="body2">{t('phase1Report.point1Body')}</Typography>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase1Report.point2Title')}</Typography>
              <Typography variant="body2">{t('phase1Report.point2Body')}</Typography>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase1Report.point3Title')}</Typography>
              <Typography variant="body2">{t('phase1Report.point3Body')}</Typography>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Typography variant="h4">{t('phase1Report.point4Title')}</Typography>
              <Typography variant="body2">{t('phase1Report.point4Body')}</Typography>
            </Box>
          </li>
        </ol>
      </Box>
    </Box>
  );
};

export default Report;
