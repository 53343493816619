import React, {useCallback, useState} from 'react';
import Header from 'layouts/Header';
import StartScreen from 'layouts/StartScreen';
import {Container, Box, Typography} from '@material-ui/core';
import {useHistory} from 'react-router';
import {warning} from 'helpers/logger';
import {phase2Service} from 'services/phase2Licence.service';
import {useTranslation} from 'react-i18next';
import Form from './Form';
import useStyles from './styles';

const inittialValues = {
  code: localStorage.getItem('patientId')
};

const Search = () => {
  const classes = useStyles();
  const history = useHistory();
  const [searchError, setSearchError] = useState(false);
  const {t} = useTranslation();
  const handleSubmit = useCallback((values) => phase2Service.findPhase1(values.code)
    .then((res) => {
      const result = {
        ...res,
        patientPhase: false
      };
      sessionStorage.setItem('patient', JSON.stringify(result));
      history.push('/phase3');
    })
    .catch(error => {
      if (error === 404) {
        setSearchError(true);
      } else {
        return warning(error);
      }
    }), [history]);

  return (
    <>
      <StartScreen />
      <Header />
      <Container className={classes.pageContent} maxWidth="md">
        <Form
          onSubmit={handleSubmit}
          initialValues={inittialValues}
        />
        {searchError && (
          <Box textAlign="center">
            <Typography variant="h2" color="error">{t('search.error')}</Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default Search;
