import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const TranslatedText = ({text}) => {
  const {t} = useTranslation();
  return (
    <>
      {t(text)}
    </>
  );
};

TranslatedText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TranslatedText;
