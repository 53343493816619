import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import {ThemeProvider} from '@material-ui/styles';
import './i18n';
import AppRouter from './routers';
import theme from './theme';

const render = () => {
  ReactDOM.render((
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  ), document.getElementById('app'));
};

render();
