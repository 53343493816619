import React, {useEffect, useMemo, useRef} from 'react';
import * as d3 from 'd3';
import {Box} from '@material-ui/core';
import useStyles from './styles';

export const getColorArray = () => {
  const colorArr = [];
  for (let i = 0; i < 5; i += 1) {
    colorArr.push(`rgb(${i * 63.75}, 255, 0)`);
  }
  for (let i = 12; i >= 0; i -= 1) {
    colorArr.push(`rgb(255, ${i * 19.6153846}, 0)`);
  }
  return colorArr;
};

function switchColor(value) {
  const colorArray = getColorArray();
  if (value < 0 || value >= 0.34) {
    return colorArray[colorArray.length - 1];
  }
  for (let i = 0; i < colorArray.length; i += 1) {
    if (value >= i * 0.02 && value < (i + 1) * 0.02) {
      return colorArray[i];
    }
  }
}

const AcmChart = () => {
  const classes = useStyles();
  const d3Container = useRef(null);
  const data = useMemo(() => {
    const phase1Data = JSON.parse(sessionStorage.getItem('acmData'));
    return {
      healthVal: phase1Data.healthVal,
      absACMmrt: 1 - phase1Data.healthSlice
    };
  }, []);

  const health = g => {
    const startAngleValue = data.healthVal * 5.5 + 3.5;
    const arc = d3
      .arc()
      .innerRadius(40)
      .outerRadius(60)
      .endAngle(3.6); // It's in radian, so Pi = 3.14 = bottom.

    g.append('path')
      .datum({startAngle: 9})
      .style('fill', '#bfbfbf')
      .attr('d', arc);

    const foreground = g
      .append('path')
      .datum({startAngle: 3.6})
      .style('fill', switchColor(data.absACMmrt))
      .style('stroke', 'white')
      .style('stroke-width', '1px')
      .style('opacity', 0.9)
      .attr('d', arc);

    foreground
      .transition()
      .duration(5000)
      .call((transition, newAngle) => {
        transition.attrTween('d', (d) => {
          const interpolate = d3.interpolate(d.startAngle, newAngle);
          // eslint-disable-next-line func-names
          return function (s) {
            // eslint-disable-next-line no-param-reassign
            d.startAngle = interpolate(s);
            return arc(d);
          };
        });
      }, startAngleValue);
  };

  useEffect(
    () => {
      if (d3Container.current) {
        const svg = d3.select(d3Container.current)
          .attr('viewBox', '-150 -60 300 120')
          .style('width', '100%')
          .style('height', '200px')
          .style('font', '10px sans-serif');

        svg.append('g')
          .call(health);
      }
    }
  );

  return (
    <Box className={classes.resultWrapper}>
      <svg
        className="d3-component"
        ref={d3Container}
      />
    </Box>
  );
};

export default AcmChart;
