import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import App from 'modules/app/App';
import {Provider} from 'context';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Box} from '@material-ui/core';
import {makeUrl} from 'helpers/url';
import {switchValidatorLang} from 'helpers/utils';
import i18n from 'i18n';
import {userService} from 'services/user.service';
import {SnackBar} from 'components/SnackBar';
import Phase1Page from 'pages/phase1';
import PhasePatientDoctorPage from 'pages/phasePatientDoctor';
import Phase1ResultPage from 'pages/phase1Result';
import Phase1ReportPage from 'pages/phase1Report';
import SearchPage from 'pages/search';
import RiskFactorsPage from 'pages/riskFactors';
import RiskFactorsSummaryPage from 'pages/riskFactorsSummary';
import MorbidityRisksPage from 'pages/morbidityRisks';
import MortalityRisksPage from 'pages/mortalityRisks';
import DrillDownGraphPage from 'pages/drillDownGraph';
import SearchPatientPage from 'pages/searchPatient';
import DiseaseRisksPage from 'pages/diseaseRisks';
import PlusRisks from 'pages/plusRisks';
import ExportPage from 'pages/export';
import Phase2ReportPage from 'pages/phase2Report';
import CombinedPhasePage from 'pages/combinedPhase';
import CombinedPhaseResultPage from 'pages/combinedPhaseResult';
import useStyles from './styles';

const AppRouters = () => {
  const classes = useStyles();
  const [client, setClient] = useState('base');
  useEffect(() => {
    const currentUser = sessionStorage.getItem('user');
    if (!currentUser) {
      userService.getCurrentInfo().then(user => {
        sessionStorage.setItem('user', JSON.stringify(user));
        if (user && user.config) {
          setClient(user.config.client);
        }
      });
    } else {
      const user = JSON.parse(currentUser);
      if (user && user.config) {
        setClient(user.config.client);
      }
    }
    switchValidatorLang(i18n.language);
  }, [client]);

  return (
    <Provider>
      <div>
        <CssBaseline />
        <SnackBar id="snackbar" className={classes.snackBarVisible} />
        <Router basename={makeUrl()}>
          <Box>
            <App>
              <Switch>
                <Route path="/" component={client !== 'base' ? CombinedPhasePage : Phase1Page} exact />
                <Route path="/patient" component={CombinedPhasePage} exact />
                <Route path="/phase1Result" component={Phase1ResultPage} exact />
                <Route path="/phase1Report" component={Phase1ReportPage} exact />
                <Route path="/search" component={SearchPage} exact />
                <Redirect path="/doctor" to="/search" exact />
                <Route path="/searchpatient" component={SearchPatientPage} exact />
                <Route path={['/phase2', '/phase3']} component={PhasePatientDoctorPage} exact />
                <Route path="/riskfactors" component={RiskFactorsPage} exact />
                <Route path="/riskfactorsummary" component={RiskFactorsSummaryPage} exact />
                <Route path="/morbidityrisks" component={MorbidityRisksPage} exact />
                <Route path="/mortrisks" component={MortalityRisksPage} exact />
                <Route path="/drilldown" component={DrillDownGraphPage} exact />
                <Route path="/export" component={ExportPage} exact />
                <Route path="/diseaseRisks" component={DiseaseRisksPage} exact />
                <Route path="/plus" component={PlusRisks} exact />
                <Route path="/phase2Report" component={Phase2ReportPage} exact />
                <Route path="/patientResult" component={CombinedPhaseResultPage} exact />
                <Redirect to="/" />
              </Switch>
            </App>
          </Box>
        </Router>
      </div>
    </Provider>
  );
};

export default AppRouters;
