import React from 'react';
import {Container, Grid, Link, Toolbar} from '@material-ui/core';
import logo from 'assets/images/check_logo.png';
import {useTranslation} from 'react-i18next';
import Report from './Report';
import useStyles from './styles';

const Phase1Report = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Container maxWidth="md">
      <Toolbar disableGutters>
        <Grid container alignItems="center">
          <Grid item id="pageLogo">
            <Link to="/">
              <img src={logo} alt={t('header.logo')} className={classes.pageLogo} />
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
      <Report />
    </Container>
  );
};

export default Phase1Report;
