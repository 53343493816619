/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {FormField as TextField} from 'components/form/TextField';
import {FormField as Select} from 'components/form/Select';
import {FormField as Slider} from 'components/form/Slider';
import {FormField as NumberField} from 'components/form/NumberField';
import {marks, smokeProducts} from 'services/constants';
import {useTranslation} from 'react-i18next';
import {numericality} from 'redux-form-validators';
import HorizontalFormItem from 'layouts/FormItem/Horizontal';
import {useFormState} from 'react-final-form';
import StepHeader from 'layouts/StepHeader';
import useStyles from './styles';

const WEEK = 'marks.week';

const LifeStyle = ({patientPhase}) => {
  const classes = useStyles();
  const {values} = useFormState();
  const {t} = useTranslation();
  return (
    <>
      <StepHeader title="pageHeader.lifeStyle" />
      <Grid container spacing={2} className={classes.stepWrapper}>
        <Grid item xs={12}>
          {patientPhase ? (
            <Grid container spacing={2}>
              <HorizontalFormItem label="lifeStyle.smokeTime">
                {(values.smkNow || values.smkQuitYear) ? (
                  <Grid container alignItems="center">
                    <Typography>{t('lifeStyle.since')}</Typography>
                    <Box mr={1} ml={1}>
                      <NumberField
                        name="smkStartYear"
                        variant="outlined"
                        size="medium"
                        parseToNumber
                        decimalScale={0}
                        className={classes.numField}
                        validate={numericality({
                          allowBlank: true,
                          '>': '1900',
                          '<=': new Date().getFullYear()
                        })}
                      />
                    </Box>
                    {values.smkQuitYear && (
                      <>
                        <Typography>{t('lifeStyle.to')}</Typography>
                        <Box mr={1} ml={1}>
                          <NumberField
                            name="smkQuitYear"
                            variant="outlined"
                            size="medium"
                            parseToNumber
                            decimalScale={0}
                            className={classes.numField}
                            validate={numericality({
                              allowBlank: true,
                              '<=': new Date().getFullYear(),
                              '>=': values.smkStartYear
                            })}
                          />
                        </Box>
                      </>
                    )}
                    <Box mr={1} ml={1}>
                      <Select
                        name="smkPrd"
                        options={smokeProducts}
                        isSearchable
                        isClearable
                      />
                    </Box>
                    <Box mr={1}>
                      <TextField
                        name="smkNum"
                        variant="outlined"
                        className={classes.numField}
                        validate={numericality({allowBlank: true, '>': 0})}
                      />
                    </Box>
                    <Typography>{t('lifeStyle.aDay')}</Typography>
                  </Grid>

                ) : (
                  <Typography>{t('marks.never')}</Typography>
                )}
              </HorizontalFormItem>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <HorizontalFormItem label="lifeStyle.smokeTime" halfSize>
                {(values.smkNow || values.smkQuitYear) ? (
                  <Select
                    name="smkPrd"
                    options={smokeProducts}
                    isSearchable
                    isClearable
                  />
                ) : (
                  <Typography>{t('marks.never')}</Typography>
                )}
              </HorizontalFormItem>
              {(values.smkNow || values.smkQuitYear) && (
              <HorizontalFormItem label="lifeStyle.packYears" halfSize>
                <TextField
                  name="packYears"
                  variant="outlined"
                  className={classes.numField}
                  disabled
                />
              </HorizontalFormItem>
              )}
            </Grid>
          )}

        </Grid>
        <Grid item xs={12} sm={6}>
          <Slider
            label="lifeStyle.alcoBeerPerWeek.label"
            name="alcoBeerPerWeek"
            min={0}
            step={1}
            max={20}
            marks={marks.alcoMarks}
            popOverText="lifeStyle.alcoBeerPerWeek.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.alcoWinePerWeek.label"
            name="alcoWinePerWeek"
            min={0}
            step={1}
            max={20}
            marks={marks.alcoMarks}
            popOverText="lifeStyle.alcoWinePerWeek.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.alcoWhiskeyPerWeek.label"
            name="alcoWhiskeyPerWeek"
            min={0}
            step={1}
            max={20}
            marks={marks.alcoMarks}
            popOverText="lifeStyle.alcoWhiskeyPerWeek.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.exerciseWalkPerWeek.label"
            name="exerciseWalkPerWeek"
            min={0}
            step={0.5}
            max={6}
            marks={marks.exerciseMarks}
            popOverText="lifeStyle.exerciseWalkPerWeek.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.exerciseRunPerWeek.label"
            name="exerciseRunPerWeek"
            min={0}
            step={0.5}
            max={6}
            marks={marks.exerciseMarks}
            popOverText="lifeStyle.exerciseRunPerWeek.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.exerciseBicyclePerWeek.label"
            name="exerciseBicyclePerWeek"
            min={0}
            step={0.5}
            max={6}
            marks={marks.exerciseMarks}
            popOverText="lifeStyle.exerciseBicyclePerWeek.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.exerciseSimulatorsPerWeek.label"
            name="exerciseSimulatorsPerWeek"
            min={0}
            step={0.5}
            max={6}
            marks={marks.exerciseMarks}
            popOverText="lifeStyle.exerciseSimulatorsPerWeek.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.exerciseSwimmingPerWeek.label"
            name="exerciseSwimmingPerWeek"
            min={0}
            step={0.5}
            max={6}
            marks={marks.exerciseMarks}
            popOverText="lifeStyle.exerciseSwimmingPerWeek.popOverText"
            withInput
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Slider
            label="lifeStyle.coffePerDay.label"
            name="coffePerDay"
            min={0}
            step={1}
            max={6}
            marks={marks.coffeeTeaMarks}
            popOverText="lifeStyle.coffePerDay.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.teaPerDay.label"
            name="teaPerDay"
            min={0}
            step={1}
            max={6}
            marks={marks.coffeeTeaMarks}
            popOverText="lifeStyle.teaPerDay.popOverText"
            withInput
          />
          <Slider
            label="lifeStyle.nutFruit.label"
            name="nutFruit"
            min={0}
            step={1}
            max={28}
            marks={marks.fruitMarks}
            popOverText="lifeStyle.nutFruit.popOverText"
            popOverImage="fruit"
            withInput
            subLabel={`≥ 2/${t('marks.day')}`}
          />
          <Slider
            label="lifeStyle.nutVegetables.label"
            name="nutVegetables"
            min={0}
            step={1}
            max={28}
            marks={marks.vegetableMarks}
            popOverText="lifeStyle.nutVegetables.popOverText"
            popOverImage="vegetables"
            withInput
            subLabel={`≥ 1/${t('marks.day')}`}
          />
          <Slider
            label="lifeStyle.nutChicken.label"
            name="nutChicken"
            min={0}
            step={1}
            max={14}
            marks={marks.redMeatMarks}
            popOverText="lifeStyle.nutChicken.popOverText"
            popOverImage="poultry"
            withInput
            subLabel={`≥ 1/${t(WEEK)}`}
          />
          <Slider
            label="lifeStyle.nutRedMeat.label"
            name="nutRedMeat"
            min={0}
            step={1}
            max={14}
            marks={marks.redMeatMarks}
            popOverText="lifeStyle.nutRedMeat.popOverText"
            popOverImage="redMeat"
            withInput
            subLabel={`≥ 1/${t(WEEK)}`}
          />
          <Slider
            label="lifeStyle.nutFish.label"
            name="nutFish"
            min={0}
            step={1}
            max={14}
            marks={marks.fishMarks}
            popOverText="lifeStyle.nutFish.popOverText"
            popOverImage="fish"
            withInput
            subLabel={`≥ 1/${t(WEEK)}`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Slider
            label="pageOneQuestions.psychometric"
            name="healthyFeel"
            min={0}
            step={0.1}
            max={1}
            marks={marks.psychometricMarks}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

LifeStyle.propTypes = {
  patientPhase: PropTypes.bool,
};

export default LifeStyle;
