import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import {Field} from 'react-final-form';
import {FormControl, FormHelperText} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import CoreInput from '@material-ui/core/Input';
import CoreBox from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import {
  borderRadius,
  colorBorder,
  colorBorderHover,
  colorControl,
  colorControlDisabled,
  colorDanger,
  colorPrimary,
  colorTextBase,
  colorTextMedium
} from 'theme/variables';
import {makeStyles} from '@material-ui/core/styles';

const StyledBox = styled((props) => <CoreBox {..._.omit(props, 'error')} />)`
  input {
    height: 40px;
    padding: 12px 14px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    color: ${colorTextBase};
    border-radius: ${borderRadius}px;
    border: 1px solid;
    border-color: ${({error}) => (error ? colorDanger : colorBorder)};
    background-color: ${colorControl};

    &.Mui-disabled {
      color: ${colorTextMedium};
      background-color: ${colorControlDisabled};
    }

    &:hover:not(.Mui-disabled):not(:focus) {
      border-color: ${({error}) => (error ? colorDanger : colorBorderHover)};
    }

    &:focus {
      outline: unset;
      border-color: ${({error}) => (error ? colorDanger : colorPrimary)};
    }
  }
  label + .MuiInput-formControl {
    margin-top:0px;
  }
  .MuiInput-underline:before {
    display: none;
  }

  .MuiInput-underline:after {
    left: unset;
  }
`;

const NumberFormatCustom = (props) => {
  const {inputRef, onChange, ...other} = props;

  const handleChange = useCallback(({value}) => {
    onChange(value);
  }, [onChange]);

  return (
    <NumberFormat
      thousandSeparator={false}
      getInputRef={inputRef}
      onValueChange={handleChange}
      allowedDecimalSeparators={['.', ',']}
      {...other}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'none'
  },
}));

const NumberFieldWithTooltipError = ({
  error, tooltipClasses, touched, margin,
  className, fullWidth, label, inputProps,
  input, ...rest
}) => (
  <Tooltip
    title={error || ''}
    placement="top"
    classes={tooltipClasses}
  >
    <FormControl
      error={touched && !!error}
      margin={margin}
      className={className}
      fullWidth={fullWidth}
    >
      <StyledBox
        error={touched && !!error}
        label={label}
      >
        {label && (<InputLabel variant="outlined">{label}</InputLabel>)}
        <CoreInput
          fullWidth={fullWidth}
          inputComponent={NumberFormatCustom}
          inputProps={inputProps}
          {...input}
          {...rest}
        />
      </StyledBox>
    </FormControl>
  </Tooltip>
);

NumberFieldWithTooltipError.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
  margin: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  inputProps: PropTypes.object,
  tooltipClasses: PropTypes.object,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
};

const NumberField = ({
  input, meta: {touched, error}, fullWidth, tooltipError,
  margin, label, showError, decimalScale, allowNegative,
  formatString, mask, isAllowed, className, thousandSeparator, showErrorOnRender, ...rest
}) => {
  const classes = useStyles();
  const inputProps = useMemo(() => ({
    decimalScale: _.isNull(decimalScale) ? undefined : decimalScale,
    inputMode: decimalScale === 0 ? 'numeric' : 'decimal',
    mask,
    format: formatString,
    isAllowed,
    allowNegative,
    thousandSeparator,
  }), [decimalScale, mask, formatString, isAllowed, allowNegative, thousandSeparator]);

  const tooltipClasses = useMemo(() => ((tooltipError && error)
    ? {}
    : {popper: classes.root}),
  [error, tooltipError, classes]);
  const isErrorHelperText = showErrorOnRender ? showError && error : showError && touched && error;
  return (
    tooltipError ? (
      <NumberFieldWithTooltipError
        input={input}
        error={error}
        tooltipClasses={tooltipClasses}
        touched={touched}
        margin={margin}
        className={className}
        fullWidth={fullWidth}
        label={label}
        inputProps={inputProps}
        {...rest}
      />
    ) : (
      <FormControl
        error={!showErrorOnRender ? touched && !!error : !!error}
        margin={margin}
        className={className}
        fullWidth={fullWidth}
      >
        <StyledBox
          error={!showErrorOnRender ? touched && !!error : !!error}
          label={label}
        >
          {label && (<InputLabel variant="outlined">{label}</InputLabel>)}
          <CoreInput
            fullWidth={fullWidth}
            inputComponent={NumberFormatCustom}
            inputProps={inputProps}
            {...input}
            {...rest}
          />
        </StyledBox>
        {isErrorHelperText ? <FormHelperText>{error}</FormHelperText> : null}
      </FormControl>
    )
  );
};

NumberField.defaultProps = {
  showError: true,
  allowNegative: true,
  decimalScale: 2,
  tooltipError: false,
  thousandSeparator: false,
  showErrorOnRender: false
};

NumberField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  meta: PropTypes.object,
  margin: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.bool,
  tooltipError: PropTypes.bool,
  allowNegative: PropTypes.bool,
  thousandSeparator: PropTypes.bool,
  decimalScale: PropTypes.number,
  formatString: PropTypes.string,
  className: PropTypes.string,
  isAllowed: PropTypes.func,
  showErrorOnRender: PropTypes.bool,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

const parseValueToNumber = (val) => {
  if (_.isNil(val) || val === '') {
    return null;
  }
  return _.toNumber(val);
};

const withOutParse = (value) => value;

const FormField = ({parseToNumber, ...rest}) => (
  <Field
    component={NumberField}
    parse={parseToNumber ? parseValueToNumber : withOutParse}
    {...rest}
  />
);

FormField.defaultProps = {
  parseToNumber: false,
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  parseToNumber: PropTypes.bool,
};

export default NumberField;

export {
  FormField,
  StyledBox,
  NumberFormatCustom
};
