import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {FormField as Slider} from 'components/form/Slider';
import StepHeader from 'layouts/StepHeader';
import PhaseOneLayout from 'layouts/PhaseOneLayout';
import {marks} from 'services/constants';
import cycling from 'assets/images/cycling.png';
import running from 'assets/images/running.png';
import training from 'assets/images/training.png';
import walking from 'assets/images/walking.png';
import swimming from 'assets/images/swimming2.png';
import useStyles from '../styles';

const Exercises = () => {
  const classes = useStyles();
  return (
    <>
      <StepHeader title="pageHeader.exercises" />
      <PhaseOneLayout title="pageOneQuestions.exercises">
        <Grid container spacing={4} item xs={12} sm={6}>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={walking} alt="Walk" title="Walk" className={classes.sliderImage} />
              <Slider
                name="exerciseWalkPerWeek"
                min={0}
                step={0.5}
                max={6}
                marks={marks.exerciseMarks}
                withInput
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={running} alt="Run" title="Run" className={classes.sliderImage} />
              <Slider
                name="exerciseRunPerWeek"
                min={0}
                step={0.5}
                max={6}
                marks={marks.exerciseMarks}
                withInput
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={cycling} alt="Bicycle" title="Bicycle" className={classes.sliderImage} />
              <Slider
                name="exerciseBicyclePerWeek"
                min={0}
                step={0.5}
                max={6}
                marks={marks.exerciseMarks}
                withInput
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={training} alt="Simulators" title="Simulators" className={classes.sliderImage} />
              <Slider
                name="exerciseSimulatorsPerWeek"
                min={0}
                step={0.5}
                max={6}
                marks={marks.exerciseMarks}
                withInput
              />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className={classes.sliderWrapper}>
              <img src={swimming} alt="Swimming" title="Swimming" className={classes.sliderImage} />
              <Slider
                name="exerciseSwimmingPerWeek"
                min={0}
                step={0.5}
                max={6}
                marks={marks.exerciseMarks}
                withInput
              />
            </Box>
          </Grid>
        </Grid>
      </PhaseOneLayout>
    </>
  );
};

export default Exercises;
