import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
  controlItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',

    [theme.breakpoints.down('810')]: {
      flexWrap: 'wrap',
    },
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    marginRight: '16px',
    fontWeight: 600,
    flexBasis: 220,

    [theme.breakpoints.down('810')]: {
      minHeight: 0,
      marginBottom: '16px',
    },
  },
  field: {
    flexBasis: 368,
    flexShrink: 0,

    [theme.breakpoints.down('810')]: {
      flexBasis: '100%',
    },
  },
  required: {
    color: 'red',
    marginLeft: '5px'
  },
  longLabel: {
    flexBasis: 300,

    [theme.breakpoints.down('810')]: {
      minHeight: 0,
      marginBottom: '16px',
    },
  },
  marginLeft: {
    marginLeft: '30px'
  },
  marginRight: {
    marginRight: '16px'
  }
}));
