export function handleResponse(response) {
  return response.text().then(data => {
    if (!response.ok) {
      if (response.status === 401 || response.type === 'opaqueredirect') {
        // auto logout if 401 response returned from api
        localStorage.removeItem('user');
        window.location.reload(true);
        return {};
      }
      if (response.status === 404) {
        throw response.status;
      }

      const error = (data && data.message) || response.statusText;
      throw Error(error);
    }

    const jsData = JSON.parse(data);
    if (jsData) {
      if (jsData.errorDescription) {
        throw Error(jsData.errorDescription);
      } else if (jsData.res) {
        return jsData.res;
      } else {
        return jsData;
      }
    }
    return {};
  });
}

export function buildUrl(url, params) {
  if (!params || Object.keys(params).length === 0) {
    return url;
  }
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key] || params[key] === 0 || params[key] === false) {
      query.append(key, params[key]);
    }
  });
  const buildedUrl = `${url}?${query}`;

  return buildedUrl.startsWith('/') ? buildedUrl : `/${buildedUrl}`;
}
