import React from 'react';
import PropTypes from 'prop-types';

import {Box, Button, Grid, Divider} from '@material-ui/core';
import {FormField as TextField} from 'components/form/TextField';
import {Form as CoreForm} from 'react-final-form';
import StepHeader from 'layouts/StepHeader';
import {required} from 'redux-form-validators';
import {useTranslation} from 'react-i18next';
import useStyles from './styles';

const Form = ({initialValues, onSubmit}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <CoreForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
          <StepHeader title="pageHeader.search" />
          <Grid container spacing={2} className={classes.stepWrapper}>
            <Grid item xs={12} md={4}>
              <TextField
                name="code"
                label="Id"
                variant="outlined"
                size="medium"
                validate={required()}
                required
              />
            </Grid>
          </Grid>
          <Divider />
          <Box marginTop="30px">
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                >
                  {t('search.find')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    />
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default Form;
