import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import Wizard from 'components/Wizard';
import BasicData from 'modules/phase1/Form/Steps/BasicData';
import Smoking from 'modules/phase1/Form/Steps/Smoking';
import Alcohol from 'modules/phase1/Form/Steps/Alcohol';
import CoffeAndTea from 'modules/phase1/Form/Steps/СoffeAndTea';
import Exercises from 'modules/phase1/Form/Steps/Exercises';
import Sleep from 'modules/phase1/Form/Steps/Sleep';
import Psychometric from 'modules/phase1/Form/Steps/Psychometric';
import Nutrition from 'modules/phase1/Form/Steps/Nutrition';
import Nutrition2 from 'modules/phase1/Form/Steps/Nutrition2';
import MorbidityFamily from 'modules/phasePatientDoctor/Form/MorbidityFamily';
import MetabolicMetrics from 'modules/phasePatientDoctor/Form/MetabolicMetrics';
import calculator from './formDecorator';

const Form = ({initialValues, onSubmit}) => {
  const {state = {}} = useLocation();
  return (
    <Wizard
      onSubmit={onSubmit}
      initialValues={initialValues}
      calculator={calculator}
      toLastPage={get(state, 'toLastPage', false)}
    >
      <Wizard.Page>
        <BasicData />
      </Wizard.Page>
      <Wizard.Page>
        <Smoking />
      </Wizard.Page>
      <Wizard.Page>
        <Alcohol />
      </Wizard.Page>
      <Wizard.Page>
        <CoffeAndTea />
      </Wizard.Page>
      <Wizard.Page>
        <Exercises />
      </Wizard.Page>
      <Wizard.Page>
        <Sleep />
      </Wizard.Page>
      <Wizard.Page>
        <Psychometric />
      </Wizard.Page>
      <Wizard.Page>
        <Nutrition />
      </Wizard.Page>
      <Wizard.Page>
        <Nutrition2 />
      </Wizard.Page>
      <Wizard.Page>
        <MorbidityFamily />
      </Wizard.Page>
      <Wizard.Page>
        <MetabolicMetrics />
      </Wizard.Page>
    </Wizard>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default Form;
